import { Button, Input, Paragraph } from '@axmit/clp-library';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'antd/es/form';
import { Col, Row } from 'antd/es/grid';
import { useHistory } from 'react-router';
import { EPrivateRoutes } from 'common/const';
import { SearchHooks } from 'common/hooks';
import { InvoiceGenerator } from 'entities/CompanyPayment/components';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';
import { CompanyAccountPaymentTotal } from 'entities/Companies/components/CompanyAccountPaymentTotal';

interface IComponentProps {
  initAmount?: number | null;
}

type AllProps = ICompaniesConnectedProps & IComponentProps;

const CompanyAccountPaymentComponent = (props: AllProps) => {
  const { getCompaniesFullAmount, clearCompaniesFullAmount, companiesFullAmount, initAmount } = props;
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    initAmount && getCompaniesFullAmount({ amount: initAmount });
  }, [initAmount, getCompaniesFullAmount]);

  const debounceGetFullAmount = SearchHooks.useDebounce(getCompaniesFullAmount);
  const { data: fullAmountData, loading: fullAmountLoading } = companiesFullAmount;
  const { fullAmount } = fullAmountData || {};
  const { amount: amountWithoutFee } = companiesFullAmount?.params || {};

  useEffect(() => {
    return () => {
      clearCompaniesFullAmount();
    };
  }, [clearCompaniesFullAmount]);

  return (
    <>
      <Paragraph className="color_primary t_bold" size="sm">
        {t('accountPage.paymentForm.header')}
      </Paragraph>

      <Row>
        <Col xl={18} md={12}>
          <Form.Item className="mob-form-wrapper mb-30" label={t('accountPage.paymentForm.amountLabel')}>
            <Input
              type="number"
              min={1}
              step={1}
              addonAfter="€"
              defaultValue={initAmount || 0}
              onChange={e => {
                const { value } = e.target;
                const formattedValue = value ? Number(value) : 0;

                if (formattedValue > 0) {
                  debounceGetFullAmount({ amount: formattedValue });
                }
              }}
            />

            <CompanyAccountPaymentTotal total={fullAmount} loading={fullAmountLoading} />
          </Form.Item>
        </Col>
      </Row>

      <InvoiceGenerator amount={fullAmount || 0} />

      <Button
        className="ant-btn_mob-block ml-10"
        size="small"
        onClick={() => {
          history.push(EPrivateRoutes.DepositCompany, { amount: amountWithoutFee || 1 });
        }}
      >
        {t('accountPage.paymentForm.btnCard')}
      </Button>
    </>
  );
};

export const CompanyAccountPayment = communicationCompanies.injector(CompanyAccountPaymentComponent);
