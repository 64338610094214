import * as React from 'react';
import { Button } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { EPublicRoutes } from 'common/const';

export const SignUpCommon = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-25">
        {t(`signUp.header`)}
      </Typography.Title>

      <Typography.Text className="clp-typography-text clp-typography-text_size_md mb-50">
        <Trans i18nKey="signUp.content">
          You may enjoy Les Embellisseurs for your own personal use by setting up a <b>Personal Account</b>; companies may also
          offer and pay for their employees to access Les Embellisseurs by creating a <b>Corporate Account</b>.
        </Trans>
      </Typography.Text>

      <Button
        type="primary"
        className="ant-btn_mob-block mb-15 mr-8"
        size="small"
        onClick={() => history.push(EPublicRoutes.SignUpCorporate)}
      >
        {t(`signUp.corporateAccount`)}
      </Button>

      <Button className="ant-btn_mob-block" size="small" onClick={() => history.push(EPublicRoutes.SignUpPersonal)}>
        {t(`signUp.personalAccount`)}
      </Button>
    </>
  );
};
