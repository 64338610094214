import { StoreBranch } from '@axmit/redux-communications';
import { IBaseFilterModel } from 'common/models/Request.models';

export function buildReverseInfinityCollection<TResponse extends { data: any; meta: any }, TPayload extends IBaseFilterModel>(
  response: TResponse,
  payload: TPayload,
  branchState: StoreBranch<TResponse, TPayload>
) {
  if (!payload.offset || payload.offset === 0) {
    return { data: response.data.reverse(), meta: response.meta };
  }

  const oldData = (branchState.data && branchState.data.data) || [];

  return { data: [...response.data.reverse(), ...oldData], meta: response.meta };
}

export const ResponseHelper = {
  buildReverseInfinityCollection
};
