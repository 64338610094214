import { Container } from '@axmit/clp-library';
import * as React from 'react';
import { EPrivateRoutes } from 'common/const';
import { MobilePage } from 'common/components';
import { EmployeesPage } from 'entities/User/components/EmployeesPage';

export const EmployeesMobile = () => {
  return (
    <Container>
      <MobilePage headerKey="employeesAccounts" desktopUrl={EPrivateRoutes.Employees}>
        <EmployeesPage />
      </MobilePage>
    </Container>
  );
};
