import React, { Dispatch, SetStateAction } from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Form, { FormComponentProps } from 'antd/es/form';
import message from 'antd/es/message';
import { Button, FormItem, Input } from '@axmit/clp-library';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent
} from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import Radio from 'antd/es/radio';
import { ValidateHelper } from 'common/helpers';
import { cardFormTypes, ECardFormType, ICheckoutForm, stripeStyles } from 'entities/Payment';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { CardsSelector } from 'entities/Payment/components/CardsSelector';

interface IComponentProps {
  setStripeLoading: Dispatch<SetStateAction<boolean>>;
  currencyIsValid: boolean;
  successCallback?: () => void;
  isOwner?: boolean;
  submit: (params: ICheckoutForm) => void;
  invoiceRecipientEmail?: string;
  withSelector: boolean;
}

type AllProps = IUsersConnectedProps & IComponentProps & FormComponentProps;

const CheckoutCardFormComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const {
    submit,
    successCallback,
    currencyIsValid,
    setStripeLoading,
    usersCurrent,
    form,
    invoiceRecipientEmail,
    withSelector
  } = props;

  const { getFieldDecorator } = form;

  const cardNumberElement = elements?.getElement('cardNumber');
  const cardExpiryElement = elements?.getElement('cardExpiry');
  const cardCvcElement = elements?.getElement('cardCvc');

  const cardIsValid = HooksHelper.useStateBuilder(false);
  const dateIsValid = HooksHelper.useStateBuilder(false);
  const cvcIsValid = HooksHelper.useStateBuilder(false);
  const paymentMethod = HooksHelper.useStateBuilder('');
  const formIsValid = currencyIsValid && cardIsValid.value && dateIsValid.value && cvcIsValid.value;

  const cardFormType = HooksHelper.useStateBuilder<ECardFormType>(withSelector ? ECardFormType.Saved : ECardFormType.New);
  const cardError = HooksHelper.useStateBuilder<string>('');
  const dateError = HooksHelper.useStateBuilder<string>('');
  const cvcError = HooksHelper.useStateBuilder<string>('');

  const submitForm = async () => {
    const additiveProps =
      cardFormType.value === ECardFormType.New
        ? { stripeConfirm }
        : {
            paymentMethod: paymentMethod.value,
            customer: usersCurrent.data?.stripeCustomerId,
            stripeConfirm: onSuccessTransaction
          };

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      const { email } = values;
      const params = email ? { email, ...additiveProps } : additiveProps;
      submit(params);
    });
  };

  const stripeConfirm = async (clientSecret: string) => {
    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      message.error(t('errorPaymentElement'));

      return;
    }

    setStripeLoading(true);

    const confirmation = await stripe?.confirmCardPayment(clientSecret, {
      // eslint-disable-next-line @typescript-eslint/camelcase
      payment_method: {
        card: cardNumberElement
      }
    });

    setStripeLoading(false);

    if (!confirmation || confirmation.error) {
      message.error(confirmation?.error?.message || t('errorPaymentService'));
    } else {
      onSuccessTransaction();
    }
  };

  const onSuccessTransaction = () => {
    successCallback && successCallback();
    clearForm();
  };

  const clearForm = () => {
    form?.resetFields();
    cardNumberElement?.clear();
    cardCvcElement?.clear();
    cardExpiryElement?.clear();
  };

  const handleCardChange = (event: StripeCardNumberElementChangeEvent) => {
    const isValid = !event.error && event.complete && !event.empty;

    cardError.set(event.error?.message || '');
    cardIsValid.set(isValid);
  };

  const handleDateChange = (event: StripeCardExpiryElementChangeEvent) => {
    const isValid = !event.error && event.complete && !event.empty;

    dateError.set(event.error?.message || '');
    dateIsValid.set(isValid);
  };

  const handleCvcChange = (event: StripeCardCvcElementChangeEvent) => {
    const isValid = !event.error && event.complete && !event.empty;

    cvcError.set(event.error?.message || '');
    cvcIsValid.set(isValid);
  };

  return (
    <>
      {withSelector && (
        <Radio.Group
          onChange={e => {
            cardIsValid.set(false);
            dateIsValid.set(false);
            cvcIsValid.set(false);
            cardFormType.set(e.target.value);
          }}
          value={cardFormType.value}
          className="radio-group radio-group_inline mob-form-wrapper"
        >
          {cardFormTypes.map(({ value, label }) => (
            <Radio key={value} value={value}>
              {t(label)}
            </Radio>
          ))}
        </Radio.Group>
      )}

      {cardFormType.value === ECardFormType.New && (
        <>
          <FormItem
            label={t('cardCreate.number.label')}
            colon={false}
            help={cardError.value}
            validateStatus={ValidateHelper.getValidateStatus(cardError.value)}
          >
            <div className="bordered_bottom">
              <CardNumberElement options={{ style: stripeStyles }} onChange={handleCardChange} />
            </div>
          </FormItem>

          <FormItem
            label={t('cardCreate.expiry.label')}
            colon={false}
            help={dateError.value}
            validateStatus={ValidateHelper.getValidateStatus(dateError.value)}
          >
            <div className="bordered_bottom">
              <CardExpiryElement options={{ style: stripeStyles }} onChange={handleDateChange} />
            </div>
          </FormItem>

          <FormItem
            label={t('cardCreate.cvc.label')}
            colon={false}
            help={cvcError.value}
            validateStatus={ValidateHelper.getValidateStatus(cvcError.value)}
          >
            <div className="bordered_bottom">
              <CardCvcElement options={{ style: stripeStyles, placeholder: '***' }} onChange={handleCvcChange} />
            </div>
          </FormItem>
        </>
      )}

      {cardFormType.value === ECardFormType.Saved && (
        <CardsSelector
          onSelect={id => {
            cardIsValid.set(true);
            dateIsValid.set(true);
            cvcIsValid.set(true);
            paymentMethod.set(id);
          }}
        />
      )}

      <FormItem label={t('contributorForm.emailForInvoicing')}>
        {getFieldDecorator('email', {
          initialValue: invoiceRecipientEmail,
          rules: [
            {
              type: 'email',
              message: t('errorEmailNotEmail')
            }
          ]
        })(<Input />)}
      </FormItem>

      <Button type="primary" onClick={submitForm} disabled={!formIsValid} className="ant-btn_mob-block">
        {t('defaultBtnDeposite')}
      </Button>
    </>
  );
};

export const CheckoutCardForm = communicationUsers.injector(Form.create<AllProps>()(CheckoutCardFormComponent));
