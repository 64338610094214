import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  EActionsTypes,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import { put } from 'redux-saga/effects';
import { COOKIES_IS_COMPANY } from 'common/const';
import { CookiesHelper, MessageHelper, RequestLoadingHelper } from 'common/helpers';
import { IApplicationState } from 'app/store/reducers';
import {
  ICompanyCollection,
  ICompanyCollectionParams,
  ICurrentCompany,
  ICompanyUpdateParams,
  ICompanyModel,
  ICompanySummary,
  ICompanyTransferHistory,
  ICompanyTransferHistoryParams,
  ICompanyTransactionFullAmount,
  ICompanyTransactionFullAmountParams
} from 'entities/Companies/Companies.models';
import { CompaniesTransport } from 'entities/Companies/Companies.transport';

const namespace = 'companies';

export interface ICompaniesConnectedProps {
  companiesCurrent: StoreBranch<ICurrentCompany, ICompanyCollectionParams>;
  getCompaniesCurrent(params: ICompanyCollectionParams): ICurrentCompany;
  updateCompaniesCurrent(params: ICompanyUpdateParams): ICurrentCompany;
  clearCompaniesCurrent(): void;

  companiesFullAmount: StoreBranch<ICompanyTransactionFullAmount, ICompanyTransactionFullAmountParams>;
  getCompaniesFullAmount(params: ICompanyTransactionFullAmountParams): ICompanyTransactionFullAmount;
  clearCompaniesFullAmount(): void;

  companiesModel: StoreBranch<ICompanyModel>;
  getCompaniesModel(id: string): ICompanyModel;
  updateCompaniesModel(params: ICompanyUpdateParams): ICompanyModel;
  clearCompaniesModel(): void;

  companiesTransferHistory: StoreBranch<ICompanyTransferHistory>;
  getCompaniesTransferHistory(params: ICompanyTransferHistoryParams): ICompanyTransferHistory;
  clearCompaniesTransferHistory(): void;

  companiesSummary: StoreBranch<ICompanySummary>;
  getCompaniesSummary(id: string): ICompanySummary;
  clearCompaniesSummary(): void;
}

const transport = new CompaniesTransport('companies');

const currentApiProvider = [
  new APIProvider<ICurrentCompany, ICompanyCollectionParams>(EActionsTypes.get, transport.getCollection, {
    mapSuccess: (response: ICompanyCollection) => {
      const hasCompany = !!response?.meta?.count;

      if (hasCompany) {
        CookiesHelper.setCookies(COOKIES_IS_COMPANY, 'true');

        return {
          isCompanyAccount: true,
          data: response.data[0]
        };
      }

      return {
        isCompanyAccount: false
      };
    }
  })
];

const modelApiProvider = [
  new APIProvider(EActionsTypes.get, transport.get, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  }),
  new APIProvider(EActionsTypes.update, transport.update, {
    preRequestDataMapper: (response, payload, branchState) => {
      return RequestLoadingHelper.setOldDataForUpdating(payload, branchState);
    },
    onSuccess: function*(response, originalParams, branchState, fullState: IApplicationState) {
      yield put({
        type: getStartType('companies', 'current', EActionsTypes.get),
        payload: fullState?.companies?.current?.params
      });
      MessageHelper.updateSuccess();
    }
  })
];

const fullAmountApiProvider = [
  new APIProvider<ICompanyTransactionFullAmount, ICompanyTransactionFullAmountParams>(EActionsTypes.get, transport.getFullAmount)
];

const summaryApiProvider = [
  new APIProvider<ICompanySummary, string>(EActionsTypes.get, transport.getSummary, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

export function* reloadCompanySummary(id: string) {
  yield put({ type: getStartType(namespace, 'summary', EActionsTypes.get), payload: id });
}

const transferHistoryApiProvider = [
  new APIProvider<ICompanyTransferHistory, ICompanyTransferHistoryParams>(EActionsTypes.get, transport.getTransferHistory, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

export function* reloadCompanyTransferHistory(params: ICompanyTransferHistoryParams) {
  yield put({ type: getStartType(namespace, 'transferHistory', EActionsTypes.get), payload: params });
}

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('fullAmount', fullAmountApiProvider),
  new Branch('current', currentApiProvider),
  new Branch('summary', summaryApiProvider),
  new Branch('transferHistory', transferHistoryApiProvider)
];
const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationCompanies = buildCommunication<ICompaniesConnectedProps>(strategy);
