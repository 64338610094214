import { Button, Container, Tabs } from '@axmit/clp-library';
import { Col } from 'antd/es/grid';
import AntTabs from 'antd/es/tabs';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import Spin from 'antd/es/spin';
import message from 'antd/es/message';
import { BtnBack } from 'common/components/BtnBack';
import { ECommonRoutes, EPrivateRoutes } from 'common/const';
import { SearchHooks } from 'common/hooks';
import { CheckoutForm, CurrencyForm } from 'entities/Payment/components';
import { communicationCompanyPayment, ICompanyPaymentConnectedProps } from 'entities/CompanyPayment/CompanyPayment.communication';
import { ICheckoutForm } from 'entities/Payment';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';
import { CompanyAccountPaymentTotal } from 'entities/Companies/components/CompanyAccountPaymentTotal';

type AllProps = ICompanyPaymentConnectedProps & ICompaniesConnectedProps;

export const CompanyDepositComponent = (props: AllProps) => {
  const {
    getCompaniesFullAmount,
    clearCompaniesFullAmount,
    companiesFullAmount,
    companyPaymentIntent,
    addCompanyPaymentIntent,
    clearCompanyPaymentIntent,
    companiesCurrent
  } = props;
  const companyId = companiesCurrent?.data?.data?.id;
  const { loading } = companyPaymentIntent;

  const { i18n } = useTranslation();
  const { state } = useLocation<{ amount?: number }>();
  const { amount: initAmount } = state || {};
  const history = useHistory();

  const { data: fullAmountData, loading: fullAmountLoading } = companiesFullAmount;
  const { fullAmount } = fullAmountData || {};
  const amount = HooksHelper.useStateBuilder<number | undefined>(initAmount);
  const amountIsValid = HooksHelper.useStateBuilder(true);
  const stripeLoading = HooksHelper.useStateBuilder(false);

  useEffect(() => {
    return () => {
      clearCompanyPaymentIntent();
    };
  }, [clearCompanyPaymentIntent]);

  const debounceGetFullAmount = SearchHooks.useDebounce(getCompaniesFullAmount);

  useEffect(() => {
    initAmount && getCompaniesFullAmount({ amount: initAmount });
  }, [initAmount, getCompaniesFullAmount]);

  useEffect(() => {
    return () => {
      clearCompaniesFullAmount();
    };
  }, [clearCompaniesFullAmount]);

  const submit = (checkoutParams: ICheckoutForm) => {
    const params = { amount: fullAmount || 0 };

    companyId && addCompanyPaymentIntent({ id: companyId, ...params, ...checkoutParams });
  };

  return (
    <Container>
      <BtnBack backRoute={EPrivateRoutes.Account} />

      <Tabs
        tabBarExtraContent={
          <Button type="link" className="pr-0 " onClick={() => history.push(ECommonRoutes.Logout)}>
            <span className="t_decoration_none">{i18n.t('signOut')}</span>
          </Button>
        }
        activeKey="form"
      >
        <AntTabs.TabPane tab={i18n.t('accountPage.paymentForm.header')} key="form">
          <Col xl={8} lg={12} className="pt-30">
            <Spin spinning={loading || stripeLoading.value}>
              <CurrencyForm
                amount={amount.value}
                setAmount={value => {
                  const formattedValue = value ? Number(value) : 0;

                  amount.set(value);

                  if (formattedValue > 0) {
                    debounceGetFullAmount({ amount: formattedValue });
                  }
                }}
                setValid={amountIsValid.set}
                initValue={state?.amount}
              />

              <CompanyAccountPaymentTotal total={fullAmount} loading={fullAmountLoading} />

              <CheckoutForm
                submit={submit}
                currencyIsValid={amountIsValid.value}
                successCallback={() => {
                  message.success(i18n.t('successUpdateDefault'));
                }}
                setStripeLoading={stripeLoading.set}
                onlyNewCard
              />
            </Spin>
          </Col>
        </AntTabs.TabPane>
      </Tabs>
    </Container>
  );
};

export const CompanyDeposit = communicationCompanyPayment.injector(communicationCompanies.injector(CompanyDepositComponent));
