import { getCreds } from '@axmit/axios-patch-jwt';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { EmptyData } from 'common/components';
import { EPublicRoutes } from 'common/const';
import { RouterHelper } from 'common/helpers';
import {
  communicationCompanyInvitation,
  ICompanyInvitationConnectedProps
} from 'entities/CompanyInvitations/Invitations.communication';

type AllProps = ICompanyInvitationConnectedProps;

const InvitePageComponent = ({
  activateCompanyInvitationsEmployee,
  initCompanyInvitationsCode,
  clearCompanyInvitationsCode
}: AllProps) => {
  const { code } = RouterHelper.useQueryParam(['code']);
  const history = useHistory();

  useEffect(() => {
    async function checkCreds() {
      const creds = await getCreds();

      if (!creds.access) {
        initCompanyInvitationsCode(code);
        history.push(EPublicRoutes.SignUpPersonal);
      } else {
        clearCompanyInvitationsCode();
        code && activateCompanyInvitationsEmployee(code);
      }
    }

    checkCreds();
  }, [code, activateCompanyInvitationsEmployee, initCompanyInvitationsCode, clearCompanyInvitationsCode, history]);

  return <EmptyData />;
};

export const InvitePage = communicationCompanyInvitation.injector(InvitePageComponent);
