import Badge from 'antd/es/badge';
import Typography from 'antd/es/typography';
import { Row, Col } from 'antd/es/grid';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { Desktop, Mobile, Spinner } from 'common/components';
import { Breadcrumb } from 'common/components/Breadcrumb';
import { BtnBack } from 'common/components/BtnBack';
import { EPrivateRoutes } from 'common/const';
import { RequestViewMain } from 'entities/Requests/components/RequestViewMain';
import { RequestHelper } from 'entities/Requests/helpers/Request.helper';
import { communicationRequests, IRequestsConnectedProps } from 'entities/Requests/Requests.communication';
import { RequestHistory } from 'entities/Requests/components/RequestHistory';

type AllProps = IRequestsConnectedProps;

const RequestViewComponent = ({ getRequestsModel, clearRequestsModel, requestsModel }: AllProps) => {
  const { i18n } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    return () => clearRequestsModel();
  }, [clearRequestsModel]);

  useEffect(() => {
    getRequestsModel(id);
  }, [id, getRequestsModel]);

  const title = requestsModel.data?.meta.title;
  const status = requestsModel.data?.status;
  const statusIcon = status ? RequestHelper.getStatusIcon(status) : undefined;
  const statusText = status ? RequestHelper.getStatusDescription(status) : undefined;

  return (
    <Spinner spinning={requestsModel.loading}>
      <Desktop>
        <Breadcrumb
          previous={i18n.t('requests')}
          previousHref={EPrivateRoutes.Main}
          current={
            <>
              {title} <Badge className="t_normal ml-5" status={statusIcon} text={statusText} />
            </>
          }
        />
      </Desktop>

      <Mobile>
        <>
          <BtnBack />
          <Typography.Title className="clp-typography-title clp-typography-title_size_xs mb-10" level={2}>
            {title}
          </Typography.Title>

          <Badge className="t_normal mb-30" status={statusIcon} text={statusText} />
        </>
      </Mobile>

      <Row gutter={48}>
        <Col xs={24} sm={12}>
          <RequestViewMain request={requestsModel.data} />
        </Col>

        <Col xs={24} sm={12}>
          <RequestHistory id={id} />
        </Col>
      </Row>
    </Spinner>
  );
};

const containers = compose(communicationRequests.injector);

export const RequestView = containers(RequestViewComponent);
