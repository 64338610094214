import * as React from 'react';
import { FormItem, Input } from '@axmit/clp-library';
import { FormItemProps } from 'antd/es/form';
import { FieldDecorator } from 'common/models/Form.models';
import i18n from 'app/locales/i18n';

interface IComponentProps<T> {
  getFieldDecorator: FieldDecorator;
  fieldName: string;
  label?: string;
  validatorFunction?: (rule: string, value: string, callback: Function) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  formItemProps?: FormItemProps;
}

export function PasswordInput<T>({
  getFieldDecorator,
  fieldName,
  label,
  validatorFunction,
  onBlur,
  formItemProps
}: IComponentProps<T>) {
  return (
    <FormItem label={label} {...formItemProps}>
      {getFieldDecorator(fieldName, {
        rules: [
          { min: 8, message: () => i18n.t('errorPasswordMin') },
          {
            required: true,
            message: () => i18n.t('errorRequiredDefault')
          },
          {
            pattern: new RegExp(/[A-Z]/g),
            message: () => i18n.t('errorPasswordUppercase')
          },
          {
            pattern: new RegExp(/[a-z]/g),
            message: () => i18n.t('errorPasswordLowercase')
          },
          {
            pattern: new RegExp(/[0-9]/g),
            message: () => i18n.t('errorPasswordNumbers')
          },
          { validator: validatorFunction }
        ]
      })(<Input type="password" onBlur={onBlur} />)}
    </FormItem>
  );
}
