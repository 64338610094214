import axios from 'axios';
import { BaseTransport } from 'common/dto/base.transport';
import {
  IRequestModel,
  IRequestCollection,
  IRequestCreateParams,
  IRequestCollectionParams,
  IRequestsNonAuthUserMsgParams,
  IRequestTransactionParams,
  IRequestTransaction
} from 'entities/Requests/Requests.models';

const baseNonAuthUserMsgPath = '/contact-us/';

export class RequestTransport extends BaseTransport<
  IRequestModel,
  IRequestCollection,
  IRequestCreateParams,
  null,
  IRequestCollectionParams
> {
  public getTransactions = ({ id, ...rest }: IRequestTransactionParams): Promise<IRequestTransaction> => {
    return axios.get(`${this.path}/${id}/transactions`, { params: rest });
  };

  public sendNonAuthUserMsg = (params: IRequestsNonAuthUserMsgParams): Promise<void> => {
    return axios.post(baseNonAuthUserMsgPath, params);
  };
}
