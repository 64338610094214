import { EnvironmentHooks } from 'common/hooks';
import { MEDIA_WIDTH_DESKTOP } from 'common/config';

interface IComponentProps {
  children?: JSX.Element;
}

export const Mobile = ({ children }: IComponentProps) => {
  const { inRange } = EnvironmentHooks.useWidth({ max: MEDIA_WIDTH_DESKTOP - 1 });

  return inRange && children ? children : null;
};
