import { clearCreds, getCreds, saveCreds } from '@axmit/axios-patch-jwt';
import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  EActionsTypes,
  getFailType,
  getStartType,
  getSuccessType,
  StoreBranch
} from '@axmit/redux-communications';
import message from 'antd/es/message';
import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';
import { COOKIES_IS_COMPANY, COOKIES_NAME, EPrivateRoutes } from 'common/const';
import { EPublicRoutes } from 'common/const/Routes.const';
import { BaseTransport } from 'common/dto/base.transport';
import { CookiesHelper, MessageHelper } from 'common/helpers';
import { IErrorResponse } from 'common/models/Request.models';
import i18n from 'app/locales/i18n';
import {
  IAuthModel,
  IAuthViewModel,
  IChangePasswordParams,
  IPasswordRecoveryModel,
  IConfirmationParams,
  IRepeatConfirmationParams,
  ITokenModel
} from 'entities/Auth/Auth.models';
import { RestorePasswordTransport } from 'entities/Auth/Auth.transport';
import { IEmployeesModel } from 'entities/Employees/Employees.models';
import { communicationUsers } from 'entities/User/Users.communication';

const namespace = 'auth';

export interface IAuthConnectedProps {
  authModel: StoreBranch<ITokenModel>;
  initAuthModel(): void;
  addAuthModel(params: IAuthViewModel): void;
  deleteAuthModel(): void;

  authRestoreModel: StoreBranch<ITokenModel>;
  addAuthRestoreModel(params: IAuthModel): void;
  updateAuthRestoreModel(params: IPasswordRecoveryModel): void;

  authPassword: StoreBranch<{}>;
  changeAuthPassword(params: IChangePasswordParams): void;

  authAccount: StoreBranch<IEmployeesModel>;
  updateAuthAccount(account: IEmployeesModel): void;
  clearAuthAccount(): void;

  authConfirmation: StoreBranch<{}>;
  addAuthConfirmation(params: IConfirmationParams): void;
  repeatAuthConfirmation(params: IRepeatConfirmationParams): void;
}

const transport = new BaseTransport<ITokenModel, null, IAuthModel>(namespace);

const modelApiProvider = [
  new APIProvider<ITokenModel, IAuthViewModel, IErrorResponse>(
    EActionsTypes.add,
    ({ callbackAfterSuccess, ...allProps }) => transport.add(allProps),
    {
      onFail: response => {
        response?.message && message.error(response.message);
      },
      onSuccess: function*(response, payload) {
        const userId = response?.id?.userId;
        yield userId && getCurrentUser(userId);
        yield userId && getCompany(userId);
        yield userId && getEmployeeAccounts(userId);
        // @ts-ignore wrong typing in axios-jwt
        yield saveCreds(response);
        payload?.callbackAfterSuccess();
      }
    }
  ),
  new APIProvider(EActionsTypes.init, (): Promise<ITokenModel> => getCreds(), {
    onSuccess: function*() {
      const AuthModelData = yield getCreds();
      const userId = AuthModelData?.id?.userId;

      if (userId) {
        yield getCurrentUser(userId);
        yield getCompany(userId);
        yield getEmployeeAccounts(userId);
      } else {
        yield put({ type: getFailType(communicationUsers.namespace, 'current', EActionsTypes.get) });
      }
    }
  }),
  new APIProvider(EActionsTypes.delete, transport.deleteAll, {
    onSuccess: function*() {
      yield clearAuth();
    },
    onFail: function*() {
      yield clearAuth();
    }
  })
];

export function* clearAuth() {
  yield clearCreds();
  yield put({ type: getStartType(communicationUsers.namespace, 'current', EActionsTypes.clear) });
  yield put(push(EPublicRoutes.LogIn));
  yield CookiesHelper.clearCookies(COOKIES_NAME);
  yield CookiesHelper.clearCookies(COOKIES_IS_COMPANY);
  yield clearAuthAccount();
}

function* getCurrentUser(userId: string) {
  yield put({ type: getStartType('users', 'current', EActionsTypes.get), payload: userId });
}

function* getCompany(userId: string) {
  yield put({ type: getStartType('companies', 'current', EActionsTypes.get), payload: { user: userId } });
}

export function* getEmployeeAccounts(userId: string) {
  yield put({ type: getStartType('employees', 'current', EActionsTypes.get), payload: { user: userId } });
}

function* clearAuthAccount() {
  yield put({ type: getSuccessType(namespace, 'account', EActionsTypes.clear) });
}

const restorePassTransport = new RestorePasswordTransport('/restore-password');

const restoreModelApiProvider = [
  new APIProvider(EActionsTypes.add, restorePassTransport.add, {
    onSuccess: function() {
      message.success(i18n.t('restorePassSuccessMessage'));
    }
  }),
  new APIProvider(EActionsTypes.update, restorePassTransport.sendNewPassword, {
    onSuccess: function*() {
      message.success(i18n.t('successUpdateDefault'));
      yield put(push(EPublicRoutes.LogIn));
    }
  })
];

const passwordApiProvider = [
  new APIProvider('change', restorePassTransport.changePassword, {
    onFail: function(response) {
      response?.message && message.error(response.message);
    },
    onSuccess: function() {
      message.success(i18n.t('successUpdateDefault'));
    }
  })
];

const accountApiProvider = [
  new APIProvider(EActionsTypes.update, params => params, {
    *onSuccess() {
      yield put(push(EPrivateRoutes.Main));
    }
  })
];

const confirmationTransport = new BaseTransport('/registration/confirm-email');
const resendConfirmationTransport = new BaseTransport('/registration/resend-confirmation');

const confirmationApiProvider = [
  new APIProvider(EActionsTypes.add, confirmationTransport.add, {
    onFail: function(response) {
      response?.message && message.error(response.message);
    },
    onSuccess: function*(response, payload, branchState, fullState) {
      MessageHelper.updateSuccess();

      // @ts-ignore reason: bad typing for fullState
      if (!fullState?.users?.current?.data) {
        yield put(push(EPublicRoutes.LogIn));
      }
    }
  }),
  new APIProvider('repeat', resendConfirmationTransport.add, {
    onFail: function(response) {
      response?.message && message.error(response.message);
    },
    onSuccess: MessageHelper.updateSuccess
  })
];

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('account', accountApiProvider),
  new Branch('restoreModel', restoreModelApiProvider),
  new Branch('confirmation', confirmationApiProvider),
  new Branch('password', passwordApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationAuth = buildCommunication<IAuthConnectedProps>(strategy);
