import message from 'antd/es/message';
import { IErrorResponse } from 'common/models/Request.models';
import i18n from 'app/locales/i18n';

function updateSuccess() {
  message.success(i18n.t('successUpdateDefault'));
}

const requestError = (response: IErrorResponse) => {
  response?.message && message.error(response.message);
};

export const MessageHelper = {
  requestError,
  updateSuccess
};
