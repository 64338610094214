import { Container } from '@axmit/clp-library';
import * as React from 'react';
import { MobilePage } from 'common/components';
import { BtnBack } from 'common/components/BtnBack';
import { EPrivateRoutes } from 'common/const';
import { EmployeePage } from 'entities/Employees/components';

export const EmployeeMobile = () => {
  return (
    <Container>
      <MobilePage desktopUrl={EPrivateRoutes.Employees}>
        <BtnBack />

        <EmployeePage />
      </MobilePage>
    </Container>
  );
};
