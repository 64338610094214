import React, { useEffect } from 'react';
import AutoComplete from 'antd/es/auto-complete';
import { Input, SvgArrowLang, FormItem } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { SearchHooks } from 'common/hooks';
import { SEARCH_INIT_LIMIT } from 'common/config';
import { communicationCountries, ICountriesConnectedProps } from 'entities/Countries/Countries.communication';
import { CountriesHelper } from 'entities/Countries/helpers';

interface IComponentProps {
  fieldName: string;
  updateCountry: (value: string) => void;
  form: WrappedFormUtils; // Reason: if error FC can't be changed only by getFieldDecorator
  initialValue?: string;
}

type AllProps = ICountriesConnectedProps & IComponentProps;

const CountriesSelectorComponent = (props: AllProps) => {
  const {
    getCountriesCollection,
    clearCountriesCollection,
    countriesCollection,
    fieldName,
    form,
    updateCountry,
    initialValue
  } = props;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    return () => {
      clearCountriesCollection();
    };
  }, [clearCountriesCollection]);

  useEffect(() => {
    getCountriesCollection({ limit: SEARCH_INIT_LIMIT });
  }, [getCountriesCollection, i18n]);

  const debounceSearch = SearchHooks.useDebounce(getCountriesCollection);
  const handleSearch = (searchText: string) => {
    debounceSearch({ name: searchText });
  };

  return (
    <FormItem label={t('form.labels.country')}>
      {form.getFieldDecorator(fieldName, { valuePropName: 'select' })(
        <AutoComplete
          dataSource={CountriesHelper.formatData(countriesCollection.data)}
          onSearch={handleSearch}
          className="clp-select"
          defaultValue={initialValue}
          onSelect={value => {
            updateCountry(String(value));
          }}
          dropdownClassName="clp-select__dropdown"
        >
          <Input addonAfter={<SvgArrowLang />} />
        </AutoComplete>
      )}
    </FormItem>
  );
};

export const CountriesSelector = communicationCountries.injector(CountriesSelectorComponent);
