import { Container } from '@axmit/clp-library';
import React from 'react';
import { MobilePage } from 'common/components';
import { EPrivateRoutes } from 'common/const';
import { CompanyUpdate } from 'entities/Companies/components/CompanyUpdate';

export const CompanyUpdateMobile = () => {
  return (
    <Container>
      <MobilePage headerKey="companyInfo" desktopUrl={EPrivateRoutes.CompanyInfo}>
        <CompanyUpdate />
      </MobilePage>
    </Container>
  );
};
