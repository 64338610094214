import { Container } from '@axmit/clp-library';
import React from 'react';
import { MobilePage } from 'common/components';
import { EPrivateRoutes } from 'common/const';
import { CardCreateForm } from 'entities/Payment/components/CardCreateForm';
import { CardsList } from 'entities/Payment/components/CardsList';

export const PaymentMobile = () => {
  return (
    <Container>
      <MobilePage headerKey="paymentInformation" desktopUrl={EPrivateRoutes.PaymentInfo}>
        <CardCreateForm />

        <CardsList />
      </MobilePage>
    </Container>
  );
};
