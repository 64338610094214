import { Button } from '@axmit/clp-library';
import Icon from 'antd/es/icon';
import * as React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ECommonRoutes, EPrivateRoutes } from 'common/const';

interface IComponentProps {
  price?: string | number;
  fullWidth?: boolean;
  backRoute?: EPrivateRoutes | ECommonRoutes;
  className?: string;
}

export const BtnBack = ({ backRoute = EPrivateRoutes.Main, className = '' }: IComponentProps) => {
  const { i18n } = useTranslation();
  const history = useHistory();

  return (
    <Button onClick={() => history.push(backRoute)} size="small" className={`mb-20 ${className}`}>
      <span>
        <Icon type="left" className="mr-8" />

        {i18n.t('defaultBtnBack')}
      </span>
    </Button>
  );
};
