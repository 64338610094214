import { Link } from 'react-router-dom';
import React from 'react';
import { TableFormatHelper } from 'common/helpers';
import { EPrivateRoutes } from 'common/const';
import { IEmployeesModel } from 'entities/Employees/Employees.models';

function formatEmployeeTableLink(value: string, employee: IEmployeesModel) {
  const { id, firstName } = employee;

  return (
    <Link className="link link_bold" to={`${EPrivateRoutes.Employees}/${id}`}>
      {TableFormatHelper.formatFullName(firstName, employee)}
    </Link>
  );
}

export const EmployeesHelper = {
  formatEmployeeTableLink
};
