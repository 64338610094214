import { AntdFormHelper } from '@axmit/antd-helpers';
import { Button, Checkbox, FormItem, Input, PhoneInput } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import Spin from 'antd/es/spin';
import { FormComponentProps } from 'antd/es/form';
import React, { FormEvent } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EPublicRoutes } from 'common/const';
import { PhoneHooks, CheckboxHooks } from 'common/hooks';
import { TwoColumnsWithImage } from 'common/components/TwoColumnsWithImage';
import imgSnow700 from 'app/assets/images/snow_w_700.jpg';
import imgSnow565 from 'app/assets/images/snow_w_565.jpg';
import imgSnow1440 from 'app/assets/images/snow_w_1440.jpg';
import imgSnow2000 from 'app/assets/images/snow_w_2000.jpg';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { AgreeTocCheckbox } from 'entities/Auth/components/AgreeTOCCheckbox';
import { PasswordInput } from 'entities/Auth/components/PasswordInput';
import {
  communicationCompanyInvitation,
  ICompanyInvitationConnectedProps
} from 'entities/CompanyInvitations/Invitations.communication';

type AllProps = FormComponentProps & IUsersConnectedProps & ICompanyInvitationConnectedProps;

const SignUpFormComponent = (props: AllProps) => {
  const { form, usersModel, addUsersModel, addInvitedUsersModel, companyInvitationsCode } = props;
  const { getFieldDecorator, getFieldError } = form;

  const { i18n } = useTranslation();
  const history = useHistory();

  const { phone, phoneValidateMessage, validatePhone, changePhone, phoneValidateStatus } = PhoneHooks.usePhone(
    undefined,
    getFieldError('phoneNumber')
  );
  const agreeTOC = CheckboxHooks.useCheckbox(false);
  const agreePromo = CheckboxHooks.useCheckbox(false);

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err || !agreeTOC.value) {
        return;
      }

      const params = { ...values, phoneNumber: phone, isPromoAllowed: agreePromo.value };
      const code = companyInvitationsCode?.data;
      code ? addInvitedUsersModel({ ...params, companyInvitation: code }) : addUsersModel(params);
    });
  };

  return (
    <TwoColumnsWithImage
      imageSlot={
        <img
          sizes="calc(50vw - 20px)"
          srcSet={`
            ${imgSnow565} 565w,
            ${imgSnow700} 700w,
            ${imgSnow1440} 1440w,
            ${imgSnow2000} 2000w`}
          src={imgSnow700}
          alt="snow"
          className="img-sign"
        />
      }
      contentSlot={
        <Spin spinning={usersModel.loading}>
          <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-25" level={2}>
            {i18n.t('signUpFormHeader')}
          </Typography.Title>

          <Typography.Text className="clp-typography-text clp-typography-text_size_md mob-text_center mb-50">
            {i18n.t('signUpMessagePersonal')}
          </Typography.Text>

          <Form className="mt-30" onSubmit={submit}>
            <Row gutter={22}>
              <Col {...wrapperCol}>
                <FormItem label={i18n.t('form.labels.firstName')}>
                  {getFieldDecorator('firstName', {
                    rules: [
                      {
                        required: true,
                        message: () => i18n.t('errorRequiredDefault')
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>

              <Col {...wrapperCol}>
                <FormItem label={i18n.t('form.labels.lastName')}>
                  {getFieldDecorator('lastName', {
                    rules: [
                      {
                        required: true,
                        message: () => i18n.t('errorRequiredDefault')
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>

              <Col {...wrapperCol}>
                <FormItem label={i18n.t('form.labels.email')} className="email-input">
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        type: 'email',
                        message: () => i18n.t('errorEmailNotEmail')
                      },
                      {
                        required: true,
                        message: () => i18n.t('errorRequiredDefault')
                      }
                    ]
                  })(<Input type="email" />)}
                </FormItem>
              </Col>

              <Col {...wrapperCol}>
                <FormItem
                  label={i18n.t('form.labels.phoneNumber')}
                  validateStatus={phoneValidateStatus}
                  help={phoneValidateMessage}
                >
                  <PhoneInput onChange={changePhone} validateTrigger={validatePhone} block />
                </FormItem>
              </Col>

              <Col {...wrapperCol}>
                <PasswordInput
                  label={i18n.t('form.labels.password')}
                  getFieldDecorator={getFieldDecorator}
                  fieldName="password"
                />
              </Col>
            </Row>

            <AgreeTocCheckbox onChange={agreeTOC.set} />
            <FormItem>
              <Checkbox onChange={agreePromo.set}>{i18n.t('signupAgreeWithPromo')}</Checkbox>
            </FormItem>

            <Row align="middle" gutter={16}>
              <Col {...wrapperCol} className="mob-form-wrapper">
                <Button
                  className="ant-btn_mob-block mob-form-wrapper"
                  disabled={usersModel.loading || !agreeTOC.value}
                  type="primary"
                  htmlType="submit"
                  size="small"
                  block
                >
                  {i18n.t('signUpSubmit')}
                </Button>
              </Col>

              <Col {...wrapperCol} className="mob-text_center">
                <Button type="link" className="pl-0 pr-0" onClick={() => history.push(EPublicRoutes.LogIn)}>
                  {i18n.t('signUpLogInLink')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      }
    />
  );
};

const wrapperCol = { xs: 24, xl: 11 };

export const SignUpForm = communicationCompanyInvitation.injector(
  communicationUsers.injector(
    Form.create<AllProps>({
      mapPropsToFields(props: AllProps) {
        const { usersModel } = props;

        return AntdFormHelper.mapValidationToFields(usersModel?.params, usersModel?.errors);
      }
    })(SignUpFormComponent)
  )
);
