import { Col, Row } from 'antd/es/grid';
import React from 'react';
import { EmployeesList } from 'entities/Employees/components';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';
import { EmployeesInviteForm, PendingList } from 'entities/CompanyInvitations/components';
import { EmployeeCredit } from 'entities/CompanyPayment/components';

type AllProps = ICompaniesConnectedProps;

const EmployeesComponent = ({ companiesCurrent }: AllProps) => {
  const companyId = companiesCurrent.data?.data?.id;

  if (!companyId) {
    return null;
  }

  return (
    <>
      <Row gutter={{ sm: 24, md: 100 }}>
        <Col xs={24} sm={13}>
          <EmployeesInviteForm companyId={companyId} />
        </Col>

        <Col xs={24} sm={11}>
          <PendingList companyId={companyId} />
        </Col>
      </Row>

      <EmployeesList companyId={companyId} />

      <Row className="mt-50">
        <Col xs={24} xl={13}>
          <EmployeeCredit />
        </Col>
      </Row>
    </>
  );
};

export const Employees = communicationCompanies.injector(EmployeesComponent);
