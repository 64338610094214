import { Button, Tabs } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import AntTabs from 'antd/es/tabs';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { EPrivateRoutes } from 'common/const';
import { TabsHooks } from 'common/hooks';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';
import { PersonalInfo } from 'entities/User/components';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { CompanyUpdate } from 'entities/Companies/components/CompanyUpdate';
import { Plans } from 'entities/SubscriptionPlans/components/Plans';
import { EmployeesPage } from 'entities/User/components/EmployeesPage';
import { AutoPayment } from 'entities/Payment/components';
import { CompanyAccount } from 'entities/Companies/components/CompanyAccount';

type AllProps = IAuthConnectedProps & IUsersConnectedProps & ICompaniesConnectedProps;

const routes = [
  EPrivateRoutes.Main,
  EPrivateRoutes.Employees,
  EPrivateRoutes.AutoPayment,
  EPrivateRoutes.Account,
  EPrivateRoutes.PersonalInfo,
  EPrivateRoutes.CompanyInfo
];

const DashboardCompanyDesktopComponent = ({ companiesCurrent, deleteAuthModel }: AllProps) => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const name = companiesCurrent?.data?.data?.name || '';
  const activeTab = TabsHooks.useInitFromRoute(routes);

  useEffect(() => {
    const { pathname } = location;
    const newTab = getTab(pathname);
    const tabWasChanged = pathname !== activeTab.value && routes.includes(newTab as EPrivateRoutes);

    if (tabWasChanged) {
      activeTab.set(pathname);
    }
  }, [location, activeTab]);

  const onChangeTab = (key: string) => {
    history.push(key);
  };

  const activeTabKey = getTab(activeTab.value);

  return (
    <>
      <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-25" level={2}>
        {name}
      </Typography.Title>

      <Tabs
        className="mt-15"
        tabBarExtraContent={
          <Button type="link" className="pr-0 " onClick={() => deleteAuthModel()}>
            <span className="t_decoration_none">{i18n.t('signOut')}</span>
          </Button>
        }
        activeKey={activeTabKey}
        onTabClick={onChangeTab}
      >
        <AntTabs.TabPane tab={i18n.t('companyTabs.plans')} key={EPrivateRoutes.Main}>
          <Plans />
        </AntTabs.TabPane>

        <AntTabs.TabPane tab={i18n.t('companyTabs.employees')} key={EPrivateRoutes.Employees}>
          <EmployeesPage />
        </AntTabs.TabPane>

        <AntTabs.TabPane tab={i18n.t('companyTabs.autoPayment')} key={EPrivateRoutes.AutoPayment}>
          <AutoPayment />
        </AntTabs.TabPane>

        <AntTabs.TabPane tab={i18n.t('companyTabs.account')} key={EPrivateRoutes.Account}>
          <CompanyAccount />
        </AntTabs.TabPane>

        <AntTabs.TabPane tab={i18n.t('companyTabs.personalInfo')} key={EPrivateRoutes.PersonalInfo}>
          <PersonalInfo />
        </AntTabs.TabPane>

        <AntTabs.TabPane tab={i18n.t('companyTabs.companyInfo')} key={EPrivateRoutes.CompanyInfo}>
          <CompanyUpdate />
        </AntTabs.TabPane>
      </Tabs>
    </>
  );
};

const containers = compose(communicationAuth.injector, communicationUsers.injector, communicationCompanies.injector);

export const DashboardCompanyDesktop = containers(DashboardCompanyDesktopComponent);

const getTab = (value: string): string => {
  const tab = `/${value.split('/')[1]}`;

  return tab === EPrivateRoutes.Plans ? EPrivateRoutes.Main : tab;
};
