import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@axmit/clp-library';
import {
  communicationSubscriptionPlans,
  ISubscriptionPlansConnectedProps
} from 'entities/SubscriptionPlans/SubscriptionPlans.communication';
import { communicationEmployees, IEmployeesConnectedProps } from 'entities/Employees/Employees.communication';

type AllProps = ISubscriptionPlansConnectedProps & IEmployeesConnectedProps;

const PlansArchiveBtnComponent = (props: AllProps) => {
  const { subscriptionPlansModel, employeesPlanCollection } = props;
  const { data: employees, loading: employeesLoading } = employeesPlanCollection;
  const { loading: planLoading, data: plan } = subscriptionPlansModel;

  const { t } = useTranslation();

  const archive = () => {
    const { archiveSubscriptionPlansModel, unArchiveSubscriptionPlansModel } = props;

    if (!plan) {
      return;
    }

    const { id, isDeleted } = plan;
    const isArchive = !isDeleted;

    isArchive ? archiveSubscriptionPlansModel(id) : unArchiveSubscriptionPlansModel(id);
  };

  const hasSubscriptions = !!employees?.data?.length;
  const disableBtn = hasSubscriptions || employeesLoading || planLoading || !plan;
  const isVisibleSpin = !plan || planLoading;
  const isArchive = !plan?.isDeleted;

  return (
    <Button className="ant-btn_mob-block" disabled={disableBtn} onClick={archive} loading={isVisibleSpin}>
      {plan ? t(`plansPage.form.${isArchive ? 'btnArchive' : 'btnUnarchive'}`) : ''}
    </Button>
  );
};

export const PlansArchiveBtn = communicationEmployees.injector(communicationSubscriptionPlans.injector(PlansArchiveBtnComponent));
