import React from 'react';
import { Col, Row } from 'antd/es/grid';
import { Input, Paragraph, Select } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import AntSelect from 'antd/es/select';
import { QuickSearch } from 'common/components';
import { subscriptionPlansOptions } from 'entities/SubscriptionPlans/SubscriptionPlans.consts';

interface IComponentProps {
  onSearchTitle: (value: string) => void;
  onSearchPeriod: (value: string) => void;
  onChangeAmountFrom: (value: number | undefined) => void;
  onChangeAmountTo: (value: number | undefined) => void;
  changeStatus: (value: any) => void;
}

export const SubscriptionPlansListFilters = (props: IComponentProps) => {
  const { onSearchTitle, onSearchPeriod, changeStatus, onChangeAmountFrom, onChangeAmountTo } = props;
  const { t } = useTranslation();

  return (
    <Row gutter={[24, 24]}>
      <Col lg={12} xl={5}>
        <QuickSearch label={t('plansPage.table.titleLabel')} onSearch={onSearchTitle} />
      </Col>

      <Col lg={12} xl={6}>
        <Paragraph size="xs">{t('plansPage.table.amountLabel')}</Paragraph>

        <Input
          className="range-inputs-item"
          placeholder={t('plansPage.table.fromSelectorLabel')}
          type="number"
          min={1}
          step={1}
          onChange={e => {
            const { value } = e?.target || {};

            onChangeAmountFrom(value || value === '0' ? Number(value ?? 0) : undefined);
          }}
        />
        <Input placeholder="—" className="range-inputs-divider" disabled />
        <Input
          className="range-inputs-item"
          placeholder={t('plansPage.table.toSelectorLabel')}
          type="number"
          min={1}
          step={1}
          onChange={e => {
            const { value } = e?.target || {};

            onChangeAmountTo(value || value === '0' ? Number(value ?? 0) : undefined);
          }}
        />
      </Col>

      <Col lg={12} xl={5}>
        <QuickSearch
          label={t('plansPage.table.periodLabel')}
          onSearch={onSearchPeriod}
          inputProps={{ type: 'number', min: 1, step: 1 }}
        />
      </Col>

      <Col lg={12} xl={5}>
        <Paragraph size="xs">{t('plansPage.table.statusLabel')}</Paragraph>

        <Select bordered="bottom" className="w-100p" allowClear onChange={changeStatus}>
          {subscriptionPlansOptions.statuses.map(({ value, label }) => (
            <AntSelect.Option key={value} value={value}>
              {t(label)}
            </AntSelect.Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};
