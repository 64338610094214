import { EnvironmentHooks } from 'common/hooks';
import { MEDIA_WIDTH_DESKTOP } from 'common/config';

interface IComponentProps {
  children?: JSX.Element;
  customWidth?: number;
}

export const Desktop = ({ children, customWidth }: IComponentProps) => {
  const { inRange } = EnvironmentHooks.useWidth({ min: customWidth || MEDIA_WIDTH_DESKTOP });

  return inRange && children ? children : null;
};
