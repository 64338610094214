import { MainLayout } from '@axmit/clp-library';
import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ECommonRoutes, EPublicRoutes } from 'common/const/Routes.const';
import { ContactUs } from 'app/pages/ContactUs';
import { history } from 'app/store';
import { RequestCreateForm } from 'entities/Requests/components';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { InvitePage, Logout } from 'entities/Auth/components';
import { Footer } from 'entities/Layout/Footer';
import { Header } from 'entities/Layout/Header';
import { LayoutPrivate } from 'entities/Layout/LayoutPrivate';
import { LayoutPublic } from 'entities/Layout/LayoutPublic';
import { Confirmation } from 'entities/Auth/components/Confirmation';
import { FinalizeRequest } from 'entities/Requests/components/FinalizeRequest';

type AllProps = IAuthConnectedProps;

class LayoutBaseComponent extends React.Component<AllProps> {
  public componentDidMount() {
    const { initAuthModel } = this.props;

    initAuthModel();
  }

  public render() {
    return (
      <ConnectedRouter history={history}>
        <Header />

        <MainLayout>
          <Switch>
            <Route path={ECommonRoutes.CreateRequest} component={RequestCreateForm} />
            <Route path={ECommonRoutes.Logout} component={Logout} />
            <Route path={ECommonRoutes.ContactUs} component={ContactUs} />
            <Route path={ECommonRoutes.Invite} component={InvitePage} />
            <Route path={ECommonRoutes.Confirmation} component={Confirmation} />
            <Route path={ECommonRoutes.FinalizeRequest} component={FinalizeRequest} />
            <Route path={EPublicRoutes.NewPassword} component={LayoutPublic} />
            <Route path={EPublicRoutes.RestorePassword} component={LayoutPublic} />
            <Route path={EPublicRoutes.SignUpPersonal} component={LayoutPublic} />
            <Route path={EPublicRoutes.GetConfirm} component={LayoutPublic} />
            <Route path={EPublicRoutes.SignUpCorporate} component={LayoutPublic} />
            <Route path={EPublicRoutes.SignUpCommon} component={LayoutPublic} />
            <Route path={EPublicRoutes.LogIn} component={LayoutPublic} />
            <Route path="*" component={LayoutPrivate} />
          </Switch>
        </MainLayout>

        <Footer />
      </ConnectedRouter>
    );
  }
}

export const LayoutBase = communicationAuth.injector(LayoutBaseComponent);
