import { Paragraph } from '@axmit/clp-library';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from 'antd/es/table';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import moment from 'moment';
import { PaginationHelper, TableFormatHelper } from 'common/helpers';
import { EmptyData } from 'common/components';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';
import { ICompanyTransferHistoryParams } from 'entities/Companies/Companies.models';
import { COMPANY_HISTORY_ITEMS_PER_PAGE, transferHistoryColumns } from 'entities/Companies/Companies.consts';
import { CompanyAccountHistoryFilters } from 'entities/Companies/components/CompanyAccountHistoryFilters';

interface IComponentProps {
  companyId: string;
}

type AllProps = ICompaniesConnectedProps & IComponentProps;

const CompanyAccountHistoryComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const { getCompaniesTransferHistory, clearCompaniesTransferHistory, companiesTransferHistory, companyId } = props;
  const { data, loading } = companiesTransferHistory;
  const { data: tableData, meta } = data || {};
  const hasData = !!data?.data.length;

  const dateFrom = HooksHelper.useStateBuilder<moment.Moment | null | undefined>(undefined);
  const dateTo = HooksHelper.useStateBuilder<moment.Moment | null | undefined>(undefined);
  const type = HooksHelper.useStateBuilder<string | undefined>(undefined);
  const employee = HooksHelper.useStateBuilder<string | undefined>(undefined);

  const hasFilter: boolean = !!employee.value || !!type.value || !!dateTo.value || !!dateFrom.value;
  const isVisibleNoData = !hasData && !hasFilter && !loading;
  const isVisibleTable = hasData || hasFilter;

  const [currentPage, setCurrentPage] = useState<number>(1);

  const onChangePage = (limit: number, offset: number, page: number) => {
    setCurrentPage(page);
    reloadCollection({ limit, offset });
  };

  const listPagination = PaginationHelper.getListsPagination(
    onChangePage,
    COMPANY_HISTORY_ITEMS_PER_PAGE,
    meta?.count,
    currentPage
  );

  useEffect(() => {
    return () => {
      clearCompaniesTransferHistory();
    };
  }, [clearCompaniesTransferHistory]);

  const reloadCollection = useCallback(
    (newParams?: Partial<ICompanyTransferHistoryParams>) => {
      const params: ICompanyTransferHistoryParams = { id: companyId, limit: COMPANY_HISTORY_ITEMS_PER_PAGE };

      if (!newParams?.offset) {
        setCurrentPage(1);
      }

      if (dateFrom.value) {
        params.dateFrom = dateFrom.value.toISOString();
      }

      if (dateTo.value) {
        params.dateTo = dateTo.value.toISOString();
      }

      if (employee.value) {
        params.employee = employee.value;
      }

      if (type.value) {
        // @ts-ignore: string to string literal
        params.type = type.value;
      }

      getCompaniesTransferHistory({ ...params, ...newParams });
    },
    [getCompaniesTransferHistory, companyId, dateTo.value, dateFrom.value, type.value, employee.value]
  );

  useEffect(() => {
    reloadCollection();
  }, [reloadCollection]);

  return (
    <>
      <Paragraph className="color_primary t_bold mb-15" size="sm">
        {t('accountPage.historyTable.header')}
      </Paragraph>

      {isVisibleNoData && (
        <Paragraph className="mt-30" size="xs">
          {t('accountPage.historyTable.noDataPlaceholder')}
        </Paragraph>
      )}

      {isVisibleTable && (
        <>
          <CompanyAccountHistoryFilters
            onChangeDateTo={dateTo.set}
            onChangeDateFrom={dateFrom.set}
            dateTo={dateTo.value}
            dateFrom={dateFrom.value}
            changeType={type.set}
            setEmployee={employee.set}
            employee={employee.value}
          />

          <Table
            className="clw-table clw-table_align-left clw-table_without-no-data clw-table_first-col-bold mb-25 mt-25"
            size="middle"
            columns={TableFormatHelper.translateTitles(transferHistoryColumns, t)}
            footer={!!tableData?.length || loading ? undefined : () => <EmptyData />}
            dataSource={tableData}
            rowKey={record => record.createdAt}
            loading={loading}
            scroll={{ x: true }}
            pagination={listPagination}
            bordered
          />
        </>
      )}
    </>
  );
};

export const CompanyAccountHistory = communicationCompanies.injector(CompanyAccountHistoryComponent);
