import { Link } from 'react-router-dom';
import React from 'react';
import { EPrivateRoutes } from 'common/const';
import i18n from 'app/locales/i18n';
import { ESubscriptionPlansStatus } from 'entities/SubscriptionPlans/SubscriptionPlans.consts';
import { ISubscriptionPlansModel } from 'entities/SubscriptionPlans/SubscriptionPlans.models';

const getStatus = (isDeleted: boolean): string => {
  return i18n.t(`plansPage.table.status${isDeleted ? ESubscriptionPlansStatus.Archived : ESubscriptionPlansStatus.Active}`);
};

const formatTitleTableLink = (title: string, plan: ISubscriptionPlansModel) => {
  const { id } = plan;

  return (
    <Link className="link link_bold" to={`${EPrivateRoutes.Plans}/${id}`}>
      {title}
    </Link>
  );
};

export const SubscriptionPlansHelper = {
  formatTitleTableLink,
  getStatus
};
