import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  EActionsTypes,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import message from 'antd/es/message';
import { put } from 'redux-saga/effects';
import { MessageHelper, RequestLoadingHelper } from 'common/helpers';
import { EErrorStatus } from 'common/models/Request.models';
import i18n from 'app/locales/i18n';
import {
  ICompanyEmployeesTransferCreateParams,
  ICompanyEmployeesTransferModel,
  ICompanyInvoiceModel,
  ICompanyInvoiceParams,
  ICompanyPaymentIntent,
  ICompanyPaymentIntentParams,
  ICompanyPaymentMethodCollection,
  ICompanyPaymentMethodCreateParams,
  ICompanyPaymentMethodDeleteParams,
  ICompanyPaymentMethodModel
} from 'entities/CompanyPayment/CompanyPayment.models';
import { CompanyPaymentTransport } from 'entities/CompanyPayment/CompanyPayment.transport';
import { ICompanyInvitationCreateParams } from 'entities/CompanyInvitations/Invintations.models';
import { EAutoPaymentBEErrors } from 'entities/CompanyPayment/CompanyPayment.consts';
import { reloadCompanySummary, reloadCompanyTransferHistory } from 'entities/Companies/Companies.communication';
import { reloadEmployeesCollection } from 'entities/Employees/Employees.communication';

const namespace = 'companyPayment';

export interface ICompanyPaymentConnectedProps {
  companyPaymentModel: StoreBranch<ICompanyPaymentMethodModel>;
  addCompanyPaymentModel(params: ICompanyPaymentMethodCreateParams): void;
  deleteCompanyPaymentModel(params: ICompanyPaymentMethodDeleteParams): void;

  companyPaymentCollection: StoreBranch<ICompanyPaymentMethodCollection>;
  getCompanyPaymentCollection(id: string): void;

  companyPaymentInvoice: StoreBranch<ICompanyInvoiceModel>;
  addCompanyPaymentInvoice(params: ICompanyInvoiceParams): void;
  clearCompanyPaymentInvoice(): void;

  companyPaymentIntent: StoreBranch<ICompanyPaymentIntent>;
  addCompanyPaymentIntent(params: ICompanyPaymentIntentParams): void;
  clearCompanyPaymentIntent(): void;

  companyPaymentTransferModel: StoreBranch<ICompanyEmployeesTransferModel>;
  addCompanyPaymentTransferModel(params: ICompanyEmployeesTransferCreateParams): void;
  clearCompanyPaymentTransferModel(): void;
}

const transport = new CompanyPaymentTransport('companies');

const modelApiProvider = [
  new APIProvider(EActionsTypes.add, transport.addMethod, {
    clearParams: true,
    onSuccess: function*(response, originalParams, branchState, fullState) {
      message.success(i18n.t('successCreateDefault'));

      // @ts-ignore
      const { params = {} } = fullState?.companyPayment?.collection;

      yield params && reloadCollection(params);

      yield originalParams?.companyId && reloadCompanySummary(originalParams.companyId);
    },
    onFail: response => {
      if (response?.message === EAutoPaymentBEErrors.IncorrectCardNumber) {
        message.error(i18n.t('cardCreate.labelForErrorInNumber'));

        return;
      }

      if (response?.message === EAutoPaymentBEErrors.TooManyCards) {
        message.error(i18n.t('cardCreate.labelForErrorTooManyCards'));

        return;
      }

      if (response?.status !== EErrorStatus.Validation) {
        MessageHelper.requestError(response);
      }
    }
  }),
  new APIProvider(EActionsTypes.delete, transport.deleteMethod, {
    onSuccess: function*(response, originalParams, branchState, fullState) {
      // @ts-ignore
      const { params = {} } = fullState?.companyPayment?.collection;

      yield params && reloadCollection(params);

      yield originalParams?.companyId && reloadCompanySummary(originalParams.companyId);
    }
  })
];

function* reloadCollection(params: ICompanyInvitationCreateParams) {
  yield put({ type: getStartType(namespace, 'collection', EActionsTypes.get), payload: params });
}

const collectionApiProvider = [
  new APIProvider(EActionsTypes.get, transport.getMethods, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

const transferApiProvider = [
  new APIProvider(EActionsTypes.add, transport.transfer, {
    clearParams: true,
    onSuccess: function*(response, originalParams, branchState, fullState) {
      message.success(i18n.t('employeesPage.creditForm.successMessage'));

      yield originalParams?.id && reloadCompanySummary(originalParams.id);

      // @ts-ignore
      const { params: employeesParams } = fullState?.employees?.collection;
      yield employeesParams && reloadEmployeesCollection(employeesParams);

      // @ts-ignore
      const { params: historyParams } = fullState?.companies?.transferHistory;
      yield historyParams && reloadCompanyTransferHistory(historyParams);
    },
    onFail: response => {
      if (response?.message === EAutoPaymentBEErrors.InsufficientFunds) {
        message.error(i18n.t('employeesPage.creditForm.insufficientFunds'));

        return;
      }

      if (response?.status !== EErrorStatus.Validation) {
        MessageHelper.requestError(response);
      }
    }
  })
];

const invoiceApiProvider = [
  new APIProvider(EActionsTypes.add, transport.generateInvoice, {
    onSuccess: (response: ICompanyInvoiceModel) => {
      const file = new Blob([response], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    },
    onFail: response => {
      if (response?.message === EAutoPaymentBEErrors.AmountLess05) {
        message.error(
          i18n.t('errorLabeledValueMustGreaterOrEqual', { label: i18n.t('accountPage.paymentForm.amountLabel'), min: 0.5 })
        );

        return;
      }

      MessageHelper.requestError(response);
    }
  })
];

const mapIntentParams = (originalParams?: ICompanyPaymentIntentParams) => {
  if (!originalParams) {
    return {};
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { stripeConfirm, ...rest } = originalParams;

  return rest;
};

const onSuccessIntent = (response: ICompanyPaymentIntent, originalParams: ICompanyPaymentIntentParams | undefined) => {
  if (!originalParams?.stripeConfirm) {
    return;
  }

  if (!response.clientSecret) {
    message.error(i18n.t('errorPaymentInfo'));

    return;
  }

  originalParams.stripeConfirm(response.clientSecret);
};

const intentApiProvider = [
  new APIProvider(EActionsTypes.add, transport.payToCompany, {
    clearParams: true,
    mapParams: mapIntentParams,
    onSuccess: onSuccessIntent
  })
];

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('transferModel', transferApiProvider),
  new Branch('invoice', invoiceApiProvider),
  new Branch('intent', intentApiProvider),
  new Branch('collection', collectionApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationCompanyPayment = buildCommunication<ICompanyPaymentConnectedProps>(strategy);
