import { IUserCompanyCreateParams } from 'entities/User/Users.models';

export const CorporateSignUpFormFields: Array<keyof IUserCompanyCreateParams> = [
  // @ts-ignore: Reason - bad typing for nested fields IValidationError from 'antd-helpers'
  'company.name',
  'firstName',
  'lastName',
  'email',
  'password',
  'phoneNumber'
];
