import { useEffect } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Typography from 'antd/es/typography';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { FormatHelper } from 'common/helpers';
import { EPrivateRoutes } from 'common/const';
import { UsersHooks } from 'common/hooks/Users.hooks';
import { RetryHooks } from 'common/hooks/Retry.hooks';
import { RETRY_NUMBER_ATTEMPTS_GET_BALANCE } from 'common/const/Retry.const';
import { communicationEmployees, IEmployeesConnectedProps } from 'entities/Employees/Employees.communication';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationPayment, IPaymentConnectedProps } from 'entities/Payment/Payment.communication';

type AllProps = IPaymentConnectedProps & IEmployeesConnectedProps & IAuthConnectedProps;

const BalanceComponent = ({
  getPaymentBalance,
  paymentBalance,
  authAccount,
  getEmployeesBalance,
  employeesBalance
}: AllProps) => {
  const { i18n } = useTranslation();
  const { currentUserId } = UsersHooks.useCurrent();
  const employeeId = authAccount.data?.id;

  const getBalance = React.useCallback(() => {
    if (employeeId) {
      getEmployeesBalance(employeeId);
    } else {
      currentUserId && getPaymentBalance(currentUserId);
    }
  }, [employeeId, currentUserId, getEmployeesBalance, getPaymentBalance]);

  RetryHooks.useRetryCallback(RETRY_NUMBER_ATTEMPTS_GET_BALANCE, () => getBalance());

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  const data = employeeId ? employeesBalance.data : paymentBalance.data;

  const [displayedBalance, setDisplayedBalance] = React.useState('');

  useEffect(() => {
    const balance = data ? FormatHelper.getCurrency(data.balance) : '';
    balance && setDisplayedBalance(balance);
  }, [data, setDisplayedBalance]);

  return (
    <div>
      <div className="mb-5">{i18n.t('form.labels.accountBalance')}</div>

      <Typography.Title className="clp-typography-title clp-typography-title_size_xs" level={4}>
        {displayedBalance}
      </Typography.Title>

      {!authAccount.data && (
        <Link className="link" to={EPrivateRoutes.Deposit}>
          {i18n.t('deposit')}
        </Link>
      )}
    </div>
  );
};

const containers = compose(communicationPayment.injector, communicationEmployees.injector, communicationAuth.injector);

export const Balance = containers(BalanceComponent);
