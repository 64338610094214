import React from 'react';
import Spin from 'antd/es/spin';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { CardInfo, Container } from '@axmit/clp-library';
import { useHistory } from 'react-router';
import { MessageHelper, RouterHelper } from 'common/helpers';
import { EmptyData } from 'common/components';
import { EPrivateRoutes } from 'common/const';
import { communicationPayment, IPaymentConnectedProps } from 'entities/Payment';
import { CheckoutForm, CurrencyForm } from 'entities/Payment/components';

type AllProps = IPaymentConnectedProps;

const UserEmployeeCheckoutFormComponent = (props: AllProps) => {
  const query = RouterHelper.useQuery();
  const history = useHistory();

  const id = query.get('userId');
  const employeeId = query.get('employeeId');
  const description = query.get('description');
  const amountInit = query.get('amount') || 1;

  const amount = HooksHelper.useStateBuilder<number | undefined>(Number(amountInit));
  const currencyIsValid = HooksHelper.useStateBuilder(true);
  const stripeLoading = HooksHelper.useStateBuilder(false);

  const { addEmployeesPaymentIntent, addPaymentIntent, paymentIntent } = props;
  const { loading } = paymentIntent;
  const userId = employeeId || id;
  const submit = employeeId ? addEmployeesPaymentIntent : addPaymentIntent;

  if (!userId) {
    return <EmptyData />;
  }

  return (
    <Container>
      <div className="content-wrapper" style={{ width: '50%' }}>
        {description && <CardInfo header={description} headerSize="sm" />}

        <Spin spinning={loading || stripeLoading.value}>
          <CurrencyForm amount={amount.value} setAmount={amount.set} setValid={currencyIsValid.set} />

          <CheckoutForm
            submit={params => submit({ userId, amount: amount.value || 0, ...params })}
            currencyIsValid={currencyIsValid.value}
            successCallback={() => {
              MessageHelper.updateSuccess();
              history.push(EPrivateRoutes.Main);
            }}
            setStripeLoading={stripeLoading.set}
            onlyNewCard={!!employeeId}
          />
        </Spin>
      </div>
    </Container>
  );
};

export const UserEmployeeCheckoutForm = communicationPayment.injector(UserEmployeeCheckoutFormComponent);
