import React from 'react';
import axios from 'axios';

function useRetryCallback(count: number, fn: Function) {
  let currentAttempt = 1;
  const initialInterval = 2000;
  const auth = axios.defaults.headers.common['Authorization'];

  const retry = React.useCallback(() => {
    if (count < currentAttempt) {
      return;
    }

    const delayInterval = currentAttempt * initialInterval;
    setTimeout(() => {
      const currentAuth = axios.defaults.headers.common['Authorization'];
      if (!currentAuth || auth !== currentAuth) {
        return;
      }

      fn();
      currentAttempt++;
      retry();
    }, delayInterval);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    retry();
  }, [retry]);
}

export const RetryHooks = {
  useRetryCallback
};
