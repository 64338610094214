import { AntdFormHelper } from '@axmit/antd-helpers';
import { FormItem, Input, PhoneInput } from '@axmit/clp-library';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import Modal from 'antd/es/modal';
import { FormComponentProps } from 'antd/es/form';
import React, { FormEvent } from 'react';
import { useHistory } from 'react-router';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { Desktop, MobFormButton, Mobile, Spinner } from 'common/components';
import { EPrivateRoutes } from 'common/const';
import { PhoneHooks } from 'common/hooks';
import { ChangePasswordForm } from 'entities/Auth/components';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';

type AllProps = IUsersConnectedProps & FormComponentProps;

const PersonalInfoComponent = (props: AllProps) => {
  const { i18n } = useTranslation();
  const { usersCurrent, form } = props;
  const { getFieldDecorator, getFieldError } = form;

  const history = useHistory();
  const isVisibleModal = HooksHelper.useStateBuilder<boolean>(false);

  const { phone, phoneValidateMessage, validatePhone, changePhone, phoneValidateStatus } = PhoneHooks.usePhone(
    usersCurrent.data?.phoneNumber || undefined,
    getFieldError('phoneNumber')
  );

  if (!usersCurrent.data) {
    return null;
  }

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (phoneValidateMessage) {
      return;
    }

    form.validateFields((err, values) => {
      if (err || !usersCurrent.data) {
        return;
      }

      const { updateUsersCurrent } = props;
      const { data } = usersCurrent;
      const { id, role } = data;

      updateUsersCurrent({
        id,
        data: { ...values, phoneNumber: phone, role, id }
      });
    });
  };

  return (
    <Spinner spinning={usersCurrent.loading}>
      <Form className="mt-30" onSubmit={submit}>
        <Row gutter={22}>
          <Col {...wrapperCol}>
            <FormItem label={i18n.t('form.labels.firstName')}>
              {getFieldDecorator('firstName', {
                rules: [
                  {
                    required: true,
                    message: () => i18n.t('errorRequiredDefault')
                  }
                ]
              })(<Input />)}
            </FormItem>
          </Col>

          <Col {...wrapperCol}>
            <FormItem label={i18n.t('form.labels.lastName')}>
              {getFieldDecorator('lastName', {
                rules: [
                  {
                    required: true,
                    message: () => i18n.t('errorRequiredDefault')
                  }
                ]
              })(<Input />)}
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col {...wrapperCol}>
            <FormItem
              label={i18n.t('form.labels.phoneNumber')}
              className="mob-form-wrapper"
              validateStatus={phoneValidateStatus}
              help={phoneValidateMessage}
            >
              <PhoneInput validateTrigger={validatePhone} onChange={changePhone} defaultValue={phone} block />
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col {...wrapperCol}>
            <FormItem label={i18n.t('form.labels.email')}>
              <Input type="email" value={usersCurrent.data?.email} disabled />
            </FormItem>
          </Col>
        </Row>

        <Row type="flex" align="middle">
          <Col {...wrapperCol} className="hidden_mob">
            <FormItem label={i18n.t('form.labels.password')}>
              <Input value="********" disabled />
            </FormItem>
          </Col>

          <Col {...wrapperCol}>
            <Desktop>
              <MobFormButton
                type="link"
                size="small"
                onClick={() => {
                  isVisibleModal.set(true);
                }}
              >
                {i18n.t('btnChangePassword')}
              </MobFormButton>
            </Desktop>

            <Mobile>
              <MobFormButton
                type="link"
                onClick={() => {
                  history.push(EPrivateRoutes.ChangePasswordMobile);
                }}
              >
                {i18n.t('btnChangePassword')}
              </MobFormButton>
            </Mobile>
          </Col>
        </Row>

        <MobFormButton size="small" disabled={usersCurrent.loading} type="primary" htmlType="submit" className="mt-20">
          {i18n.t('defaultBtnSave')}
        </MobFormButton>
      </Form>

      <Desktop>
        <Modal
          destroyOnClose
          visible={isVisibleModal.value}
          footer={null}
          onCancel={() => {
            isVisibleModal.set(false);
          }}
        >
          <ChangePasswordForm
            cancel={() => {
              isVisibleModal.set(false);
            }}
          />
        </Modal>
      </Desktop>
    </Spinner>
  );
};

const containers = compose(communicationUsers.injector);

export const PersonalInfo = containers(
  Form.create<AllProps>({
    mapPropsToFields({ usersCurrent }: AllProps) {
      const { data, errors } = usersCurrent;

      return AntdFormHelper.mapValidationToFields(data, errors);
    }
  })(PersonalInfoComponent)
);

const wrapperCol = { xs: 24, sm: 12, lg: 8, xl: 6 };
