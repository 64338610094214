import '@axmit/clp-api';

export interface IRequestModel extends Components.Schemas.GiftRequestDto {
  status: ERequestStatus;
}
// @ts-ignore
export interface IRequestCreateParams extends Components.Schemas.GiftRequestDataDto {
  employee?: string;
}

export interface IRequestCollection extends Components.Schemas.GiftRequestCollection {
  data: IRequestModel[];
}
export interface IRequestCollectionParams extends Paths.GiftRequestControllerGetList.QueryParameters {}

export enum ERequestStatus {
  Created = 'created',
  Delivered = 'delivered',
  Activated = 'activated',
  InProgress = 'inProgress',
  Completed = 'completed'
}

export interface IRequestsNonAuthUserMsgParams extends Components.Schemas.ContactUsDto {}

export interface IRequestTransactionModel extends Components.Schemas.GiftRequestTransactionDto {}
export interface IRequestTransaction extends Components.Schemas.GiftRequestTransactionCollection {}
export interface IRequestTransactionParams extends Paths.EmployeeControllerTransactionList.QueryParameters {
  id: string;
}
