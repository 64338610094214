import * as React from 'react';
import AntdBreadcrumb from 'antd/es/breadcrumb';
import Icon from 'antd/es/icon';

interface IComponentProps {
  previous: string;
  previousHref: string;
  current?: string | null | JSX.Element;
}

export const Breadcrumb = ({ current = '', previous, previousHref }: IComponentProps) => {
  return (
    <AntdBreadcrumb separator={<Icon type="right" className="breadcrumb__label_inactive" />} className="breadcrumb mb-20">
      <AntdBreadcrumb.Item href={previousHref}>
        <span className="breadcrumb__label_inactive">{previous}</span>
      </AntdBreadcrumb.Item>

      <AntdBreadcrumb.Item>
        <b>{current}</b>
      </AntdBreadcrumb.Item>
    </AntdBreadcrumb>
  );
};
