import React, { FormEvent } from 'react';
import Typography from 'antd/es/typography';
import Form from 'antd/es/form';
import Spin from 'antd/es/spin';
import message from 'antd/es/message';
import { Button, FormItem, Input } from '@axmit/clp-library';
import { compose } from 'redux';
import { FormComponentProps } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import { RouterHelper } from 'common/helpers';
import { AuthHooks } from 'entities/Auth/hooks/Auth.hooks';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

type AllProps = FormComponentProps & IAuthConnectedProps;

const NewPasswordFormComponent = (props: AllProps) => {
  const { i18n } = useTranslation();
  const { form, authRestoreModel } = props;
  const { getFieldDecorator, validateFields, getFieldValue } = form;
  const query = RouterHelper.useQuery();

  const { confirmBlur, newPassRules, confirmPassRules } = AuthHooks.usePasswordCreating({
    newFieldName: 'password',
    validateFields,
    getFieldValue
  });

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const code = query.get('code');

    if (!code) {
      message.error(i18n.t('errorNewPasswordHaveNoCode'));

      return;
    }

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      props.updateAuthRestoreModel({ password: values.password, code: code });
    });
  };

  return (
    <Spin spinning={authRestoreModel.loading}>
      <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-25">
        {i18n.t('newPassHeader')}
      </Typography.Title>

      <Form {...formLayout} className="mt-30" onSubmit={submit}>
        <FormItem label={i18n.t('form.labels.password')} hasFeedback>
          {getFieldDecorator('password', {
            rules: newPassRules
          })(<Input type="password" />)}
        </FormItem>

        <FormItem label={i18n.t('form.labels.passwordConfirm')} hasFeedback>
          {getFieldDecorator('confirm', {
            rules: confirmPassRules
          })(<Input type="password" onBlur={confirmBlur} />)}
        </FormItem>

        <FormItem wrapperCol={{ md: 24 }}>
          <Button className="ant-btn_mob-block" type="primary" htmlType="submit">
            {i18n.t('defaultBtnSave')}
          </Button>
        </FormItem>
      </Form>
    </Spin>
  );
};

const formLayout = {
  labelCol: {
    span: 24
  },
  wrapperCol: {
    md: 12
  }
};

const containers = compose(communicationAuth.injector, Form.create<AllProps>());

export const NewPasswordForm = containers(NewPasswordFormComponent);
