import React, { useEffect } from 'react';
import { Container, LandingRow } from '@axmit/clp-library';
import { useLocation } from 'react-router-dom';
import Typography from 'antd/es/typography';
import { Col } from 'antd/es/grid';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'common/components';
import imageKeyHolder from 'app/assets/images/keyHolder.png';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

type AllProps = IAuthConnectedProps;

const ConfirmationComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const { addAuthConfirmation } = props;
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const code = useQuery().get('code');
  const sub = useQuery().get('sub');

  useEffect(() => {
    if (!code || !sub) {
      return;
    }

    addAuthConfirmation({ code, cognitoSub: sub });
  }, [code, sub, addAuthConfirmation]);

  const { authConfirmation } = props;
  const { errors, loading } = authConfirmation;
  const hasErrors = !code || !sub || !!errors;

  return (
    <Container>
      <LandingRow
        leftSlot={
          <Col>
            <Spinner spinning={loading}>
              {hasErrors ? (
                <Typography.Title className="clp-typography-title clp-typography-title_size_sm mob-text_center pr-10" level={2}>
                  {t('errorDefault')}
                </Typography.Title>
              ) : (
                ''
              )}
            </Spinner>
          </Col>
        }
        rightSlot={<img src={imageKeyHolder} alt="auth" className="max-w_100 mt-50 mb-100" />}
      />

      <img src={imageKeyHolder} alt="map" className="hidden_desk w-100p mt-25 mb-30" />
    </Container>
  );
};

export const Confirmation = communicationAuth.injector(ConfirmationComponent);
