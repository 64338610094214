import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  EActionsTypes,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import message from 'antd/es/message';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { MessageHelper, RequestLoadingHelper } from 'common/helpers';
import { EErrorStatus } from 'common/models/Request.models';
import { EPrivateRoutes } from 'common/const';
import i18n from 'app/locales/i18n';
import {
  ISubscriptionPlansCollection,
  ISubscriptionPlansCollectionParams,
  ISubscriptionPlansCreateParams,
  ISubscriptionPlansModel,
  ISubscriptionPlansUpdateParams
} from 'entities/SubscriptionPlans/SubscriptionPlans.models';
import { subscriptionPlansTransport } from 'entities/SubscriptionPlans/SubscriptionPlans.transport';
import { EBEErrorMessages } from 'entities/SubscriptionPlans/SubscriptionPlans.consts';

const namespace = 'subscriptionPlans';

export interface ISubscriptionPlansConnectedProps {
  subscriptionPlansModel: StoreBranch<ISubscriptionPlansModel>;
  getSubscriptionPlansModel(id: string): void;
  addSubscriptionPlansModel(params: ISubscriptionPlansCreateParams): void;
  updateSubscriptionPlansModel(params: ISubscriptionPlansUpdateParams): void;
  archiveSubscriptionPlansModel(id: string): void;
  unArchiveSubscriptionPlansModel(id: string): void;
  clearSubscriptionPlansModel(): void;

  subscriptionPlansCollection: StoreBranch<ISubscriptionPlansCollection>;
  getSubscriptionPlansCollection(params: ISubscriptionPlansCollectionParams): void;
  clearSubscriptionPlansCollection(): void;

  subscriptionPlansActiveCollection: StoreBranch<ISubscriptionPlansCollection>;
  getSubscriptionPlansActiveCollection(params: ISubscriptionPlansCollectionParams): void;
  clearSubscriptionPlansActiveCollection(): void;

  subscriptionPlansForEmployeesCollection: StoreBranch<ISubscriptionPlansCollection>;
  getSubscriptionPlansForEmployeesCollection(params: ISubscriptionPlansCollectionParams): void;
  clearSubscriptionPlansForEmployeesCollection(): void;

  subscriptionPlansPreview: StoreBranch<ISubscriptionPlansModel>;
  getSubscriptionPlansPreview(id: string): void;
  clearSubscriptionPlansPreview(): void;

  subscriptionPlansForEmployeeCollection: StoreBranch<ISubscriptionPlansCollection>;
  getSubscriptionPlansForEmployeeCollection(params: ISubscriptionPlansCollectionParams): void;
  clearSubscriptionPlansForEmployeeCollection(): void;
}

const collectionApiProvider = [
  new APIProvider(EActionsTypes.get, subscriptionPlansTransport.getCollection, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    onFail: response => {
      if (response?.status === EErrorStatus.Validation) {
        const errors = response?.errors;
        const isAmountLess1 =
          (errors?.amountFrom && errors?.amountFrom[0] === EBEErrorMessages.AmountFromLess1) ||
          (errors?.amountTo && errors?.amountTo[0] === EBEErrorMessages.AmountToLess1);

        if (isAmountLess1) {
          message.error(
            i18n.t('errorLabeledValueMustGreaterOrEqual', { label: i18n.t('plansPage.table.labelForErrorInAmount'), min: 1 })
          );

          return;
        }

        const isPeriodLess1 = errors?.period && errors?.period[0] === EBEErrorMessages.PeriodLess1;

        if (isPeriodLess1) {
          message.error(
            i18n.t('errorLabeledValueMustGreaterOrEqual', { label: i18n.t('plansPage.table.labelForErrorInPeriod'), min: 1 })
          );

          return;
        }

        const isAmountMore99999 =
          (errors?.amountFrom && errors?.amountFrom[0] === EBEErrorMessages.AmountFromMore99999) ||
          (errors?.amountTo && errors?.amountTo[0] === EBEErrorMessages.AmountToMore99999);

        if (isAmountMore99999) {
          message.error(
            i18n.t('errorLabeledValueCannotLonger', { label: i18n.t('plansPage.table.labelForErrorInAmount'), max: 99999 })
          );

          return;
        }

        const isPeriodMore12 = errors?.period && errors?.period[0] === EBEErrorMessages.PeriodMore12;

        if (isPeriodMore12) {
          message.error(
            i18n.t('errorLabeledValueCannotLonger', { label: i18n.t('plansPage.table.labelForErrorInPeriod'), max: 12 })
          );

          return;
        }

        message.error(errors?.period || errors?.amountFrom || errors?.amountTo);

        return;
      }

      MessageHelper.requestError(response);
    }
  })
];

const modelApiProvider = [
  new APIProvider<ISubscriptionPlansModel>(EActionsTypes.get, subscriptionPlansTransport.get, {
    onFail: MessageHelper.requestError
  }),
  new APIProvider<ISubscriptionPlansModel, string>('archive', subscriptionPlansTransport.archive, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    onSuccess: MessageHelper.updateSuccess,
    onFail: MessageHelper.requestError
  }),
  new APIProvider<ISubscriptionPlansModel, string>('unArchive', subscriptionPlansTransport.unArchive, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    onSuccess: MessageHelper.updateSuccess,
    onFail: MessageHelper.requestError
  }),
  new APIProvider<ISubscriptionPlansModel, ISubscriptionPlansUpdateParams>(
    EActionsTypes.update,
    subscriptionPlansTransport.update,
    {
      preRequestDataMapper: RequestLoadingHelper.setOldData,
      onSuccess: MessageHelper.updateSuccess,
      onFail(response) {
        if (response?.status !== EErrorStatus.Validation) {
          MessageHelper.requestError(response);
        }
      }
    }
  ),
  new APIProvider<ISubscriptionPlansModel, ISubscriptionPlansCreateParams>(EActionsTypes.add, subscriptionPlansTransport.add, {
    *onSuccess() {
      message.success(i18n.t('successCreateDefault'));
      yield put(push(EPrivateRoutes.Plans));
      yield put({ type: getStartType(namespace, 'model', EActionsTypes.clear) });
    }
  })
];

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('preview', modelApiProvider),
  new Branch('collection', collectionApiProvider),
  new Branch('forEmployeesCollection', collectionApiProvider),
  new Branch('forEmployeeCollection', collectionApiProvider),
  new Branch('activeCollection', collectionApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationSubscriptionPlans = buildCommunication<ISubscriptionPlansConnectedProps>(strategy);
