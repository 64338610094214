import { useCallback } from 'react';
import debounce from 'lodash.debounce';
import { SEARCH_WAIT_TIME } from 'common/config';

function useDebounce<T>(callback: (params: T) => void) {
  return useCallback(debounce(callback, SEARCH_WAIT_TIME), [callback]);
}

export const SearchHooks = {
  useDebounce
};
