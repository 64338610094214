import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { EmployeePageForm } from 'entities/Employees/components/EmployeePageForm';
import { communicationEmployees, IEmployeesConnectedProps } from 'entities/Employees/Employees.communication';

type AllProps = IEmployeesConnectedProps;

const EmployeePageComponent = (props: AllProps) => {
  const { getEmployeesModel, employeesModel, clearEmployeesModel } = props;
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    return () => clearEmployeesModel();
  }, [clearEmployeesModel]);

  useEffect(() => {
    id && getEmployeesModel(id);
  }, [id, getEmployeesModel]);

  const employee = employeesModel?.data;

  if (!id || !employee) {
    return null;
  }

  return <EmployeePageForm employee={employee} />;
};

export const EmployeePage = communicationEmployees.injector(EmployeePageComponent);
