import React, { Dispatch, SetStateAction, useEffect } from 'react';
import Form, { FormComponentProps } from 'antd/es/form';
import { communicationPayment, ICheckoutForm, IPaymentConnectedProps } from 'entities/Payment';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { CheckoutCardForm } from 'entities/Payment/components/CheckoutCardForm';

interface IComponentProps {
  setStripeLoading: Dispatch<SetStateAction<boolean>>;
  currencyIsValid: boolean;
  successCallback?: () => void;
  isOwner?: boolean;
  submit: (params: ICheckoutForm) => void;
  invoiceRecipientEmail?: string;
  onlyNewCard?: boolean;
}

type AllProps = IUsersConnectedProps & IComponentProps & FormComponentProps & IPaymentConnectedProps;

const CheckoutFormComponent = (props: AllProps) => {
  const { onlyNewCard, getPaymentMethodCollection, paymentMethodCollection, usersCurrent } = props;

  useEffect(() => {
    const userId = usersCurrent.data?.id;

    userId && getPaymentMethodCollection({ userId });
  }, [usersCurrent.data, getPaymentMethodCollection]);

  if (paymentMethodCollection.loading) {
    return null;
  }

  const hasSavedCards = usersCurrent.data?.id && paymentMethodCollection.data?.data.length;

  return <CheckoutCardForm withSelector={!onlyNewCard && !!hasSavedCards} {...props} />;
};

export const CheckoutForm = communicationPayment.injector(
  communicationUsers.injector(Form.create<AllProps>()(CheckoutFormComponent))
);
