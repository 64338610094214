import React from 'react';
import { Col, Row } from 'antd/es/grid';
import { Paragraph } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DatePicker } from 'common/components/DatePicker';

interface IComponentProps {
  onChangeDateFrom: (value: moment.Moment | null | undefined) => void;
  dateFrom?: moment.Moment | null;
  onChangeDateTo: (value: moment.Moment | null | undefined) => void;
  dateTo?: moment.Moment | null;
}

export const EmployeeRequestHistoryFilters = (props: IComponentProps) => {
  const { onChangeDateFrom, dateFrom, onChangeDateTo, dateTo } = props;
  const { t } = useTranslation();

  return (
    <Row gutter={[24, 24]} className="mb-10 mt-5">
      <Col lg={12} xl={9}>
        <Paragraph size="xs">{t('employeePage.history.fromSelectorLabel')}</Paragraph>

        <DatePicker allowClear onChange={onChangeDateFrom} value={dateFrom} />
      </Col>

      <Col lg={12} xl={9}>
        <Paragraph size="xs">{t('employeePage.history.toSelectorLabel')}</Paragraph>

        <DatePicker allowClear onChange={onChangeDateTo} value={dateTo} />
      </Col>
    </Row>
  );
};
