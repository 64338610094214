import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { AnyAction, combineReducers, Reducer } from 'redux';
import { communicationEmployees } from 'entities/Employees/Employees.communication';
import { communicationCompanyInvitation } from 'entities/CompanyInvitations/Invitations.communication';
import { communicationSupport } from 'entities/Support/Support.communication';
import { communicationCompanies } from 'entities/Companies/Companies.communication';
import { communicationRequests } from 'entities/Requests/Requests.communication';
import { communicationPayment } from 'entities/Payment';
import { communicationAuth } from 'entities/Auth/Auth.communication';
import { communicationUsers } from 'entities/User/Users.communication';
import { communicationCountries } from 'entities/Countries/Countries.communication';
import { communicationSubscriptionPlans } from 'entities/SubscriptionPlans/SubscriptionPlans.communication';
import { communicationCompanyPayment } from 'entities/CompanyPayment/CompanyPayment.communication';

type RoutingReducer = Reducer<RouterState, AnyAction>;

export interface IApplicationState {
  routing?: RoutingReducer | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const reducers = (history: History) =>
  combineReducers<IApplicationState>({
    router: connectRouter(history),
    ...communicationAuth.reducers,
    ...communicationPayment.reducers,
    ...communicationSupport.reducers,
    ...communicationRequests.reducers,
    ...communicationCompanies.reducers,
    ...communicationCompanyInvitation.reducers,
    ...communicationCompanyPayment.reducers,
    ...communicationEmployees.reducers,
    ...communicationCountries.reducers,
    ...communicationSubscriptionPlans.reducers,
    ...communicationUsers.reducers
  });
