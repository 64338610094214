import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { EPrivateRoutes } from 'common/const';
import { PlansCreateForm, PlansUpdate, SubscriptionPlansList } from 'entities/SubscriptionPlans/components';

const PlansComponent = () => {
  return (
    <Switch>
      <Route path={`${EPrivateRoutes.Plans}/:id`} component={PlansUpdate} />
      <Route path={EPrivateRoutes.CreatePlans} component={PlansCreateForm} />
      <Route path="*" component={SubscriptionPlansList} />
    </Switch>
  );
};

export const Plans = PlansComponent;
