import Typography from 'antd/es/typography';
import { PropsWithChildren } from 'react';
import * as React from 'react';
import { Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Desktop } from 'common/components/Desktop';

interface IComponentProps {
  headerKey?: string;
  desktopUrl?: string;
}

export const MobilePage = ({ headerKey, children, desktopUrl }: PropsWithChildren<IComponentProps>) => {
  const { i18n } = useTranslation();

  return (
    <>
      {headerKey && (
        <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-20">
          {i18n.t(headerKey)}
        </Typography.Title>
      )}

      {children}

      {desktopUrl && (
        <Desktop>
          <Redirect to={desktopUrl} />
        </Desktop>
      )}
    </>
  );
};
