export enum EPublicRoutes {
  LogIn = '/login',
  GetConfirm = '/get-confirm',
  SignUpCommon = '/signup',
  SignUpCorporate = '/signup-corporate',
  SignUpPersonal = '/signup-personal',
  NewPassword = '/new-password/code',
  RestorePassword = '/restore-password'
}

export enum ECommonRoutes {
  Logout = '/logout',
  Invite = '/company-invitation/code',
  Confirmation = '/confirmation',
  ContactUs = '/contact-us',
  CreateRequest = '/requests-create',
  FinalizeRequest = '/requests-finalize'
}

export enum EPrivateRoutes {
  Employee = '/employees',
  EmployeeMobile = '/employee-view',
  DepositCompany = '/deposit-company',
  Deposit = '/deposit',
  RequestViewPage = '/requests',
  Employees = '/employees',
  EmployeesMobile = '/employees-view',
  Transfer = '/transfer',
  PaymentInfoMobile = '/payment-view',
  PaymentInfo = '/payment-info',
  PersonalInfoMobile = '/personal-view',
  CompanyInfoMobile = '/company-view',
  PersonalInfo = '/personal-info',
  CompanyInfo = '/company-info',
  Plans = '/plans',
  CreatePlans = '/plans-create',
  Account = '/account',
  AccountMobile = '/account-view',
  AutoPayment = '/auto-payment',
  AutoPaymentMobile = '/auto-payment-view',
  ChangePasswordMobile = '/change-password-view',
  TransactionGiftRequest = '/transaction-gift-request',
  Main = '/'
}

export enum ELandingRoutes {
  How = 'how',
  FAQ = 'faq',
  SignInfoCorporate = 'benefits-corporate',
  SignInfo = 'benefits',
  TOC = 'toc',
  PrivacyPolicy = 'privacy-policy',
  Philosophy = 'philosophy',
  Main = ''
}
