import { Container } from '@axmit/clp-library';
import * as React from 'react';
import { EPrivateRoutes } from 'common/const';
import { MobilePage } from 'common/components';
import { CompanyAccount } from 'entities/Companies/components';

export const CompanyAccountMobile = () => {
  return (
    <Container>
      <MobilePage headerKey="accountPage.summary.header" desktopUrl={EPrivateRoutes.Account}>
        <CompanyAccount />
      </MobilePage>
    </Container>
  );
};
