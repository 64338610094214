import { Paragraph } from '@axmit/clp-library';
import * as React from 'react';

interface IComponentProps {
  className?: string;
  leftSlot: JSX.Element | string;
  rightSlot: JSX.Element | string;
}

export const TwoColorsField = ({ className = '', leftSlot, rightSlot }: IComponentProps) => {
  return (
    <Paragraph className={className} size="sm">
      <span className="color_light opacity_light">{leftSlot}:</span> <b className="color_primary">{rightSlot}</b>
    </Paragraph>
  );
};
