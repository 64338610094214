import { AntdFormHelper } from '@axmit/antd-helpers';
import { Button, FormItem, Input } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import Spin from 'antd/es/spin';
import { FormComponentProps } from 'antd/es/form';
import React, { FormEvent } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { EPublicRoutes } from 'common/const';
import { CheckboxHooks } from 'common/hooks';
import { TwoColumnsWithImage } from 'common/components/TwoColumnsWithImage';
import imgMountains565 from 'app/assets/images/mountains_w_565.jpg';
import imgMountains700 from 'app/assets/images/mountains_w_700.jpg';
import imgMountains1440 from 'app/assets/images/mountains_w_1440.jpg';
import imgMountains2000 from 'app/assets/images/mountains_w_2000.jpg';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { IUserCompanyCreateParams } from 'entities/User/Users.models';
import { AgreeTocCheckbox } from 'entities/Auth/components/AgreeTOCCheckbox';
import { CountriesSelector } from 'entities/Countries/components/CountriesSelector';

interface IComponentProps {
  saveData: (newData: IUserCompanyCreateParams) => void;
}

type AllProps = FormComponentProps & IUsersConnectedProps & IComponentProps;

const SignUpCorporateAdditiveFormComponent = (props: AllProps) => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const country = HooksHelper.useStateBuilder<string | undefined>(undefined);

  const { form, usersCompany } = props;
  const { getFieldDecorator } = form;

  const agreeTOC = CheckboxHooks.useCheckbox(false);

  const submit = (e: FormEvent<HTMLFormElement>) => {
    const { saveData } = props;
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      saveData({ ...values, company: { ...values.company, country: country.value } });
    });
  };

  return (
    <TwoColumnsWithImage
      imageSlot={
        <img
          sizes="calc(50vw - 20px)"
          srcSet={`
            ${imgMountains565} 565w,
            ${imgMountains700} 700w,
            ${imgMountains1440} 1440w,
            ${imgMountains2000} 2000w`}
          src={imgMountains700}
          alt="mountains"
          className="img-sign"
        />
      }
      contentSlot={
        <Spin spinning={usersCompany.loading}>
          <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-25" level={2}>
            {i18n.t('signUpCorporateFormHeader')}
          </Typography.Title>

          <Typography.Text className="clp-typography-text clp-typography-text_size_md mob-text_center mb-50">
            {i18n.t('signUpMessageCorporateAdditiveForm')}
          </Typography.Text>

          <Form className="mt-20" onSubmit={submit}>
            <Row gutter={22}>
              <Col {...wrapperCol}>
                <FormItem label={i18n.t('form.labels.beneficiaryName')}>
                  {getFieldDecorator('company.beneficiaryName')(<Input />)}
                </FormItem>
              </Col>
            </Row>

            <Row gutter={22}>
              <Col {...wrapperCol}>
                <CountriesSelector form={form} fieldName="company.country" updateCountry={country.set} />
              </Col>

              <Col {...wrapperCol}>
                <FormItem label={i18n.t('form.labels.address')}>{getFieldDecorator('company.address')(<Input />)}</FormItem>
              </Col>
            </Row>

            <AgreeTocCheckbox onChange={agreeTOC.set} />

            <Row align="middle" gutter={22}>
              <Col {...wrapperCol} className="mob-form-wrapper">
                <Button
                  className="ant-btn_mob-block mob-form-wrapper"
                  disabled={usersCompany.loading || !agreeTOC.value}
                  type="primary"
                  htmlType="submit"
                  size="small"
                  block
                >
                  {i18n.t('signUpSubmit')}
                </Button>
              </Col>

              <Col {...wrapperCol} className="mob-text_center">
                <Button type="link" className="pl-0 pr-0" onClick={() => history.push(EPublicRoutes.LogIn)}>
                  {i18n.t('signUpLogInLink')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      }
    />
  );
};

const wrapperCol = { xs: 24, xl: 11 };

export const SignUpCorporateAdditiveForm = communicationUsers.injector(
  Form.create<AllProps>({
    mapPropsToFields(props: AllProps) {
      const { usersCompany } = props;

      return AntdFormHelper.mapValidationToFields(usersCompany?.params, usersCompany?.errors);
    }
  })(SignUpCorporateAdditiveFormComponent)
);
