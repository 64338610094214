import { Container } from '@axmit/clp-library';
import React from 'react';
import { MobilePage } from 'common/components';
import { EPrivateRoutes } from 'common/const';
import { PersonalInfo } from 'entities/User/components/PersonalInfo';

export const PersonalMobile = () => {
  return (
    <Container>
      <MobilePage headerKey="personalInfo" desktopUrl={EPrivateRoutes.PersonalInfo}>
        <PersonalInfo />
      </MobilePage>
    </Container>
  );
};
