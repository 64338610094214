import axios from 'axios';
import { BaseTransport } from 'common/dto/base.transport';
import { IPaymentIntentData } from 'entities/Payment/Payment.models';
import { IUserInvitedModelParams, IUserModel } from 'entities/User/Users.models';

export class UsersTransport extends BaseTransport<IUserModel> {
  public addInvited = (params: IUserInvitedModelParams): Promise<IPaymentIntentData> => {
    return axios.post(`${this.path}/with-badge`, params);
  };
}
