import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { DateHelper, FormatHelper } from 'common/helpers';
import { BE_DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT } from 'common/config';
import { IEmployeesCollectionParams, IEmployeesModel, IEmployeeRequestModel } from 'entities/Employees/Employees.models';
import { EmployeesHelper } from 'entities/Employees/helpers/Employees.helper';

export enum EEmployeeHistoryColumns {
  createdAt = 'createdAt',
  title = 'title',
  amount = 'amount'
}

export const employeeHistoryColumns: ColumnProps<IEmployeeRequestModel>[] = [
  {
    dataIndex: EEmployeeHistoryColumns.createdAt,
    title: `employeePage.history.table.columns.date`,
    render: DateHelper.getDateWithYear
  },
  { dataIndex: EEmployeeHistoryColumns.title, title: `employeePage.history.table.columns.request` },
  {
    dataIndex: EEmployeeHistoryColumns.amount,
    title: `employeePage.history.table.columns.amount`,
    render: FormatHelper.getCurrency
  }
];

enum EEmployeesColumns {
  Name = 'name',
  Amount = 'subscriptionPlanAmount',
  Date = 'nextTransferDate',
  Balance = 'balance',
  Received = 'amountReceived',
  Requests = 'requestCount',
  Plan = 'subscriptionPlanName'
}

export const employeesCollectionColumns: ColumnProps<IEmployeesModel>[] = [
  {
    dataIndex: EEmployeesColumns.Name,
    title: `employeesPage.table.columns.name`,
    render: EmployeesHelper.formatEmployeeTableLink
  },
  {
    dataIndex: EEmployeesColumns.Amount,
    title: `employeesPage.table.columns.amount`,
    render: value => (value ? FormatHelper.getCurrency(value) : '-')
  },
  {
    dataIndex: EEmployeesColumns.Date,
    title: `employeesPage.table.columns.date`,
    render: (value, model) => {
      if (!model.subscriptionPlan || !value) {
        return '-';
      }

      return moment(value, BE_DATE_FORMAT_DEFAULT).format(DATE_FORMAT_DEFAULT);
    }
  },
  {
    dataIndex: EEmployeesColumns.Balance,
    title: `employeesPage.table.columns.balance`,
    render: FormatHelper.getCurrency
  },
  {
    dataIndex: EEmployeesColumns.Received,
    title: `employeesPage.table.columns.received`,
    render: FormatHelper.getCurrency
  },
  {
    dataIndex: EEmployeesColumns.Requests,
    title: `employeesPage.table.columns.requests`
  },
  {
    dataIndex: EEmployeesColumns.Plan,
    title: `employeesPage.table.columns.plan`,
    render: value => value || '-'
  }
];

export const EMPLOYEES_PER_PAGE = 5;
export const FILTERS_EMPLOYEES_TABLE: IEmployeesCollectionParams = {
  limit: EMPLOYEES_PER_PAGE,
  isDeleted: false,
  showBalance: true
};
