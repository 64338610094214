import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Paragraph } from '@axmit/clp-library';
import { Col, Row } from 'antd/es/grid';
import Spin from 'antd/es/spin';
import { Desktop, Spinner } from 'common/components';
import { SvgClose } from 'common/SVG/SvgClose';
import { CompanyCardCreateForm } from 'entities/CompanyPayment/components/CompanyCardCreateForm';
import { communicationCompanyPayment, ICompanyPaymentConnectedProps } from 'entities/CompanyPayment/CompanyPayment.communication';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';

type AllProps = ICompaniesConnectedProps & ICompanyPaymentConnectedProps;

const AutoPaymentComponent = (props: AllProps) => {
  const { getCompanyPaymentCollection, companiesCurrent, companyPaymentCollection, deleteCompanyPaymentModel } = props;
  const companyId = companiesCurrent?.data?.data?.id;
  const { t } = useTranslation();
  const { data: paymentCollection, loading } = companyPaymentCollection;
  const hasCard = paymentCollection?.data[0]?.id;

  const lastNumbers = paymentCollection?.data[0]?.card?.last4;

  useEffect(() => {
    companyId && getCompanyPaymentCollection(companyId);
  }, [getCompanyPaymentCollection, companyId]);

  if (loading) {
    return (
      <Col xs={24} md={14} lg={14} xl={12}>
        <Spin />
      </Col>
    );
  }

  return (
    <Spinner spinning={loading}>
      <Row>
        <Col xs={24} md={14} lg={14} xl={12}>
          <Desktop>
            <Paragraph className="color_primary t_bold mb-25" size="sm">
              {t(hasCard ? 'autoPaymentPage.withCard.header' : 'autoPaymentPage.header')}
            </Paragraph>
          </Desktop>

          <Paragraph className="mb-20" size="xs">
            {t(hasCard ? 'autoPaymentPage.withCard.description' : 'autoPaymentPage.description')}
          </Paragraph>
        </Col>
      </Row>

      <Row>
        {hasCard ? (
          <div>
            <Button
              type="link"
              size="small"
              className="pl-0"
              onClick={() => {
                const paymentId = paymentCollection?.data[0].id;

                if (paymentId && companyId) {
                  deleteCompanyPaymentModel({ companyId, paymentId });
                }
              }}
            >
              <span className="mr-5">
                <SvgClose />
              </span>

              <b>{t('autoPaymentPage.withCard.removeBtn')}</b>
            </Button>
            * {lastNumbers}
          </div>
        ) : (
          <Col xs={24} lg={22} xl={19}>
            <CompanyCardCreateForm />
          </Col>
        )}
      </Row>
    </Spinner>
  );
};

export const AutoPayment = communicationCompanies.injector(communicationCompanyPayment.injector(AutoPaymentComponent));
