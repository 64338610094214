import { ColumnProps } from 'antd/lib/table';
import i18n from 'i18next';
import { ISelectorOption } from 'common/models/Selector.models';
import { DateHelper, FormatHelper } from 'common/helpers';
import { ICompanyTransferHistoryModel } from 'entities/Companies/Companies.models';

enum ETransferHistoryColumns {
  createdAt = 'createdAt',
  type = 'type',
  amount = 'amount',
  employee = 'employee'
}

export enum ETransferHistoryTypes {
  Payment = 'payment',
  Transfer = 'transfer'
}

export const transferHistoryColumns: ColumnProps<ICompanyTransferHistoryModel>[] = [
  {
    dataIndex: ETransferHistoryColumns.createdAt,
    title: `accountPage.historyTable.columns.date`,
    render: DateHelper.getDateWithYear
  },
  {
    dataIndex: ETransferHistoryColumns.type,
    title: `accountPage.historyTable.columns.type`,
    render: value => {
      return value === ETransferHistoryTypes.Transfer
        ? i18n.t(`accountPage.historyTable.typeTransfer`)
        : i18n.t(`accountPage.historyTable.typePayment`);
    }
  },
  {
    dataIndex: ETransferHistoryColumns.amount,
    title: `accountPage.historyTable.columns.amount`,
    render: FormatHelper.getCurrency
  },
  {
    dataIndex: ETransferHistoryColumns.employee,
    title: `accountPage.historyTable.columns.employee`,
    render: (value, { employeeFirstName, employeeLastName }) => {
      if (!employeeLastName || !employeeFirstName) {
        return '-';
      }

      return `${employeeLastName} ${employeeFirstName.charAt(0)}.`;
    }
  }
];

export const transferHistoryOptions: Record<'types', ISelectorOption[]> = {
  types: [
    { value: ETransferHistoryTypes.Payment, label: `employeePage.history.type.${ETransferHistoryTypes.Payment}` },
    { value: ETransferHistoryTypes.Transfer, label: `employeePage.history.type.${ETransferHistoryTypes.Transfer}` }
  ]
};

export const COMPANY_HISTORY_ITEMS_PER_PAGE = 10;
