import React from 'react';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Typography from 'antd/es/typography';
import Table from 'antd/es/table';
import uuid from 'uuid';
import { PaginationHelper, TableFormatHelper } from 'common/helpers';
import { communicationRequests, IRequestsConnectedProps } from 'entities/Requests/Requests.communication';
import { REQUESTS_TRANSACTIONS_PER_PAGE, requestsHistoryColumns } from 'entities/Requests/Request.consts';
import { IRequestTransactionModel } from 'entities/Requests/Requests.models';

interface IComponentProps {
  id: string;
}

type AllProps = IComponentProps & IRequestsConnectedProps;

const RequestHistoryComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const { id, getRequestsTransactions, clearRequestsTransactions, requestsTransactions } = props;

  useEffect(() => {
    return () => clearRequestsTransactions();
  }, [clearRequestsTransactions]);

  useEffect(() => {
    getRequestsTransactions({ id, limit: REQUESTS_TRANSACTIONS_PER_PAGE });
  }, [getRequestsTransactions, id]);

  if (!requestsTransactions?.data?.data.length) {
    return null;
  }

  const onChangePage = (limit: number, offset: number) => {
    getRequestsTransactions({
      id: id,
      limit,
      offset
    });
  };

  const {
    data: { data, meta },
    loading
  } = requestsTransactions;
  const listPagination = PaginationHelper.getListsPagination(onChangePage, REQUESTS_TRANSACTIONS_PER_PAGE, meta.count);

  const mappedData = [...data, getTotalField(meta.totalAmount)];

  return (
    <>
      <Typography.Title className="clp-typography-title clp-typography-title_size_xs mb-30" level={3}>
        {t('transactions')}
      </Typography.Title>

      <Table
        scroll={{ x: true }}
        className="clw-table clw-table_last-row-bold"
        size="middle"
        loading={loading}
        columns={TableFormatHelper.translateTitles(requestsHistoryColumns, t)}
        dataSource={mappedData}
        pagination={listPagination}
        rowKey={record => record.id}
        showHeader={false}
      />
    </>
  );
};

const containers = compose(communicationRequests.injector);

export const RequestHistory = containers(RequestHistoryComponent);

const getTotalField = (total = 0): IRequestTransactionModel => {
  return {
    id: uuid(),
    createdAt: '',
    updatedAt: '',
    gift: uuid(),
    // @ts-ignore Reason: mock title
    type: 'Total:',
    description: '',
    amount: total,
    currency: 'usd',
    balanceTransactionId: uuid(),
    user: uuid(),
    employee: uuid()
  };
};
