import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  EActionsTypes,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import { put } from '@redux-saga/core/effects';
import message from 'antd/es/message';
import { MessageHelper, RequestLoadingHelper } from 'common/helpers';
import { EErrorStatus } from 'common/models/Request.models';
import i18n from 'app/locales/i18n';
import {
  IPaymentBalance,
  IPaymentCreateViewParams,
  IPaymentIntentData,
  IPaymentIntentParams,
  IPaymentMethodCollection,
  IPaymentMethodCollectionParams,
  IPaymentMethodDetachParams,
  IPaymentMethodShort
} from 'entities/Payment/Payment.models';
import { PaymentMethodsTransport } from 'entities/Payment/Payment.transport';
import { EPaymentBEErrors } from 'entities/Payment/Payment.const';

const namespace = 'payment';

export interface IPaymentConnectedProps {
  paymentMethod: StoreBranch<IPaymentMethodShort>;
  addPaymentMethod(params: IPaymentCreateViewParams): void;
  deletePaymentMethod(params: IPaymentMethodDetachParams): void;
  clearPaymentMethod(): void;

  paymentMethodCollection: StoreBranch<IPaymentMethodCollection>;
  getPaymentMethodCollection(params: IPaymentMethodCollectionParams): void;
  clearPaymentMethodCollection(): void;

  paymentIntent: StoreBranch<IPaymentIntentData>;
  addPaymentIntent(params: IPaymentIntentParams): void;
  addEmployeesPaymentIntent(params: IPaymentIntentParams): void;

  paymentBalance: StoreBranch<IPaymentBalance>;
  getPaymentBalance(id: string): void;
}

const methodTransport = new PaymentMethodsTransport();

const methodApiProvider = [
  new APIProvider<IPaymentMethodShort, IPaymentCreateViewParams>(EActionsTypes.add, methodTransport.add, {
    clearParams: true,
    onFail: response => {
      if (response?.code === EPaymentBEErrors.IncorrectCardNumber) {
        message.error(i18n.t('cardCreate.labelForErrorInNumber'));

        return;
      }

      if (response?.status !== EErrorStatus.Validation) {
        MessageHelper.requestError(response);
      }
    },
    onSuccess: function*(response, originalParams) {
      yield originalParams && reloadMethodCollection(originalParams.userId);
    }
  }),
  new APIProvider(EActionsTypes.get, methodTransport.getCollection, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  }),
  new APIProvider(EActionsTypes.delete, methodTransport.delete, {
    onSuccess: function*(response, originalParams) {
      yield originalParams && reloadMethodCollection(originalParams.id);
    }
  })
];

function* reloadMethodCollection(id: string) {
  yield put({
    type: getStartType(namespace, 'methodCollection', EActionsTypes.get),
    payload: { userId: id }
  });
}

const mapIntentParams = (originalParams?: IPaymentIntentParams) => {
  if (!originalParams) {
    return {};
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { stripeConfirm, ...rest } = originalParams;

  return rest;
};

const onSuccessIntent = (response: IPaymentIntentData, originalParams: IPaymentIntentParams | undefined) => {
  if (!originalParams?.stripeConfirm) {
    return;
  }

  if (!response.clientSecret) {
    message.error(i18n.t('errorPaymentInfo'));

    return;
  }

  originalParams.stripeConfirm(response.clientSecret);
};

const intentApiProvider = [
  new APIProvider<IPaymentIntentData, IPaymentIntentParams>(EActionsTypes.add, methodTransport.addUserIntent, {
    clearParams: true,
    mapParams: mapIntentParams,
    onSuccess: onSuccessIntent
  }),
  new APIProvider<IPaymentIntentData, IPaymentIntentParams>('addEmployees', methodTransport.addEmployeeIntent, {
    mapParams: mapIntentParams,
    onSuccess: onSuccessIntent
  })
];

const balanceApiProvider = [new APIProvider<IPaymentBalance>(EActionsTypes.get, methodTransport.getBalance)];

const branches = [
  new Branch('method', methodApiProvider),
  new Branch('methodCollection', methodApiProvider),
  new Branch('intent', intentApiProvider),
  new Branch('balance', balanceApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationPayment = buildCommunication<IPaymentConnectedProps>(strategy);
