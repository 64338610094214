import { APIProvider, BaseStrategy, Branch, buildCommunication, EActionsTypes, StoreBranch } from '@axmit/redux-communications';
import message from 'antd/es/message';
import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';
import { IValidationError } from '@axmit/antd-helpers';
import { COOKIES_NAME, EPublicRoutes } from 'common/const';
import { BaseTransport } from 'common/dto/base.transport';
import { CookiesHelper, RequestLoadingHelper } from 'common/helpers';
import { IUpdateParams } from 'common/models/Request.models';
import i18n from 'app/locales/i18n';
import { UsersTransport } from 'entities/User/Users.transport';
import {
  IUserCompanyCreateParams,
  IUserInvitedModelParams,
  IUserModel,
  IUserModelParams,
  IUserModelUpdateParams
} from 'entities/User/Users.models';
import { clearInviteCode } from 'entities/CompanyInvitations/Invitations.communication';

const namespace = 'users';

export interface IUsersConnectedProps {
  usersModel: StoreBranch<IUserModel>;
  addUsersModel(params: IUserModelParams): void;
  addInvitedUsersModel(params: IUserInvitedModelParams): void;
  clearUsersModel(): void;

  usersCurrent: StoreBranch<IUserModel>;
  getUsersCurrent(id: string): void;
  updateUsersCurrent(params: IUpdateParams<IUserModelUpdateParams>): void;

  usersCompany: StoreBranch<IUserModel, IUserCompanyCreateParams, IValidationError<IUserCompanyCreateParams> | null>;
  addUsersCompany(params: IUserCompanyCreateParams): void;
  clearUsersCompany(): void;
}

const transport = new UsersTransport(namespace);

const currentApiProvider = [
  new APIProvider<IUserModel>(EActionsTypes.get, transport.get, {
    onSuccess: response => {
      response?.firstName && CookiesHelper.setCookies(COOKIES_NAME, response.firstName);
    }
  }),
  new APIProvider(EActionsTypes.update, transport.update, {
    preRequestDataMapper: (response, payload, branchState) => {
      return RequestLoadingHelper.setOldDataForUpdating(payload, branchState);
    },
    onSuccess: () => {
      message.success(i18n.t('successUpdateDefault'));
    }
  })
];

const modelApiProvider = [
  new APIProvider<IUserModel, IUserCompanyCreateParams>(EActionsTypes.add, transport.add, {
    onSuccess: function*(response, originalParams) {
      message.success(i18n.t('signUpSuccessfullyCreated'));
      yield put(push(EPublicRoutes.GetConfirm, { email: originalParams?.email }));
    }
  }),
  new APIProvider<IUserModel, IUserInvitedModelParams>('addInvited', transport.addInvited, {
    onSuccess: function*(response, originalParams) {
      message.success(i18n.t('signUpSuccessfullyCreated'));
      yield put(push(EPublicRoutes.GetConfirm, { email: originalParams?.email }));
      yield clearInviteCode();
    },
    onFail: function*() {
      yield clearInviteCode();
    }
  })
];

const companyTransport = new BaseTransport<IUserModel, null, IUserCompanyCreateParams>(`${namespace}/with-company`);

const companyApiProvider = [
  new APIProvider(EActionsTypes.add, companyTransport.add, {
    onSuccess: function*(response, originalParams) {
      message.success(i18n.t('signUpSuccessfullyCreated'));
      yield put(push(EPublicRoutes.GetConfirm, { email: originalParams?.email }));
    }
  })
];

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('current', currentApiProvider),
  new Branch('company', companyApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationUsers = buildCommunication<IUsersConnectedProps>(strategy);
