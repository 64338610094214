import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { MobilePage } from 'common/components';
import { EPrivateRoutes } from 'common/const';
import { DashboardRequests } from 'app/pages/DashboardRequests';

export const DashboardPersonalMobile = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === EPrivateRoutes.PaymentInfo) {
      history.push(EPrivateRoutes.PaymentInfoMobile);
    }

    if (location.pathname === EPrivateRoutes.PersonalInfo) {
      history.push(EPrivateRoutes.PersonalInfoMobile);
    }
  }, [location, history]);

  return (
    <MobilePage headerKey="requests" desktopUrl={EPrivateRoutes.Main}>
      <DashboardRequests />
    </MobilePage>
  );
};
