import React from 'react';
import { Route, Switch } from 'react-router';
import { EPrivateRoutes } from 'common/const';
import { EmployeePage } from 'entities/Employees/components';
import { Employees } from 'entities/User/components/Employees';

export const EmployeesPage = () => {
  return (
    <Switch>
      <Route path={`${EPrivateRoutes.Employee}/:id`} component={EmployeePage} exact />
      <Route path="*" component={Employees} />
    </Switch>
  );
};
