import * as React from 'react';
import { useCallback } from 'react';
import { Input, Paragraph } from '@axmit/clp-library';
import debounce from 'lodash.debounce';
import { InputProps } from 'antd/es/input';
import { SEARCH_WAIT_TIME } from 'common/config';

interface IComponentProps {
  label: string;
  onSearch: (value: string) => void;
  labelClassName?: string;
  labelOnInput?: boolean;
  inputProps?: InputProps;
}

export const QuickSearch = (props: IComponentProps) => {
  const { onSearch, labelClassName = '', labelOnInput, label, inputProps } = props;

  const debounceSearch = useCallback(debounce(onSearch, SEARCH_WAIT_TIME), [onSearch]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    debounceSearch(value);
  };

  return (
    <>
      {!labelOnInput && (
        <Paragraph size="xs" className={labelClassName}>
          {label}
        </Paragraph>
      )}

      <Input onChange={handleSearch} placeholder={labelOnInput ? label : undefined} {...inputProps} />
    </>
  );
};
