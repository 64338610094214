export const FontProximaNovaSrc =
  "url('https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')";

export const SPIN_WAIT_TIME = 500;

export const DATE_FORMAT_DEFAULT = 'DD.MM.YYYY';
export const BE_DATE_FORMAT_DEFAULT = 'YYYY-MM-DD';
export const TIME_FORMAT_DEFAULT = 'HH:mm';

export const MEDIA_WIDTH_DESKTOP = 768;
export const MEDIA_WIDTH_DESKTOP_LG = 992;

export const PINTEREST_LINK = 'https://www.pinterest.com/lesembellisseurs/';
export const INSTAGRAM_LINK = 'https://www.instagram.com/cleprivee/';
export const WHATSAPP_LINK = 'https://api.whatsapp.com/send?phone=33644635520';

export const CLP_LANDING_LINK: string =
  // @ts-ignore
  process.env.NODE_ENV === 'development' ? process.env.REACT_APP_CLP_LANDING_LINK : window?.clp_landing_link || '';

export const SEARCH_WAIT_TIME = 500;
export const SEARCH_INIT_LIMIT = 5;

export const envConfig = (process.env.NODE_ENV === 'development' ? process.env : (window as any).env) as {
  SENTRY_DSN: string;
  REACT_APP_SENTRY_DSN: string;
  LES_GOOGLE_ANALYTICS_ID: string;
  REACT_APP_LES_GOOGLE_ANALYTICS_ID: string;
  NODE_ENV: string;
};

export const MIN_EMPLOYEE_MONTHLY_BUDGET = 40;
