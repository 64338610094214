import { useLocation } from 'react-router';

const host: string = window.location.hostname;
const subDomain: string = 'app.';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function useQueryParam<T>(params: Array<keyof T>): T {
  const query = useQuery();

  const result = params.reduce((accumulator, current) => {
    return {
      ...accumulator,
      [current]: query.get(String(current))
    };
  }, {});

  return result as T;
}

function getDomain() {
  const index = host.indexOf(subDomain);

  return index < 0 ? host : host.slice(index + subDomain.length);
}

export const RouterHelper = { useQuery, getDomain, useQueryParam };
