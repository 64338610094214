import { Button, Tabs } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import AntTabs from 'antd/es/tabs';
import * as React from 'react';
import { useHistory } from 'react-router';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { EPrivateRoutes } from 'common/const';
import { TabsHooks } from 'common/hooks';
import { DashboardRequests } from 'app/pages/DashboardRequests';
import { PersonalInfo } from 'entities/User/components';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { CardCreateForm, CardsList } from 'entities/Payment/components';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';

type AllProps = IAuthConnectedProps & IUsersConnectedProps;

const DashboardDesktopComponent = ({ usersCurrent, deleteAuthModel, authAccount }: AllProps) => {
  const { i18n } = useTranslation();
  const history = useHistory();

  const name = usersCurrent?.data?.firstName || '';
  const activeTab = TabsHooks.useInitFromRoute([EPrivateRoutes.Main, EPrivateRoutes.PaymentInfo, EPrivateRoutes.PersonalInfo]);
  const { companyName } = authAccount.data || {};

  const onChangeTab = (key: string) => {
    history.push(key);
    activeTab.set(key);
  };

  return (
    <>
      <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-25" level={2}>
        {i18n.t('dashboard.header')}, {name} {companyName && <span className="color_light">({companyName})</span>}
      </Typography.Title>

      <Tabs
        className="mt-15"
        tabBarExtraContent={
          <Button type="link" className="pr-0 " onClick={() => deleteAuthModel()}>
            <span className="t_decoration_none">{i18n.t('signOut')}</span>
          </Button>
        }
        activeKey={activeTab.value}
        onChange={onChangeTab}
      >
        <AntTabs.TabPane tab={i18n.t('requests')} key={EPrivateRoutes.Main}>
          <DashboardRequests />
        </AntTabs.TabPane>

        <AntTabs.TabPane tab={i18n.t('paymentInformation')} key={EPrivateRoutes.PaymentInfo}>
          <CardCreateForm />

          <CardsList />
        </AntTabs.TabPane>

        <AntTabs.TabPane tab={i18n.t('personalInfo')} key={EPrivateRoutes.PersonalInfo}>
          <PersonalInfo />
        </AntTabs.TabPane>
      </Tabs>
    </>
  );
};

const containers = compose(communicationAuth.injector, communicationUsers.injector);

export const DashboardPersonalDesktop = containers(DashboardDesktopComponent);
