import Table from 'antd/es/table';
import React, { useCallback } from 'react';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '@axmit/clp-library';
import { useEffect } from 'react';
import { Col } from 'antd/es/grid';
import Row from 'antd/es/grid/row';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { PaginationHelper, TableFormatHelper } from 'common/helpers';
import { EmptyData } from 'common/components';
import { communicationEmployees, IEmployeesConnectedProps } from 'entities/Employees/Employees.communication';
import { employeesCollectionColumns, EMPLOYEES_PER_PAGE, FILTERS_EMPLOYEES_TABLE } from 'entities/Employees/Employees.consts';
import { EmployeesListFilters } from 'entities/Employees/components';
import { ESubscriptionPlansStatus } from 'entities/SubscriptionPlans/SubscriptionPlans.consts';
import { IEmployeesCollectionParams } from 'entities/Employees/Employees.models';

interface IComponentProps {
  companyId: string;
}

type AllProps = IComponentProps & IEmployeesConnectedProps;

const EmployeesListComponent = (props: AllProps) => {
  const { companyId, getEmployeesCollection, clearEmployeesCollection, employeesCollection } = props;
  const { t } = useTranslation();
  const searchEmployee = HooksHelper.useStateBuilder<string | undefined>(undefined);
  const plan = HooksHelper.useStateBuilder<ESubscriptionPlansStatus | undefined>(undefined);

  useEffect(() => {
    return () => clearEmployeesCollection();
  }, [clearEmployeesCollection]);

  const reloadCollection = useCallback(
    (newParams?: Partial<IEmployeesCollectionParams>) => {
      if (!companyId) {
        return;
      }

      const params: IEmployeesCollectionParams = { ...FILTERS_EMPLOYEES_TABLE, company: companyId, ...newParams };

      if (searchEmployee.value) {
        params.quickSearch = searchEmployee.value;
      }

      if (plan.value) {
        params.subscriptionPlan = plan.value;
      }

      getEmployeesCollection(params);
    },
    [getEmployeesCollection, companyId, plan.value, searchEmployee.value]
  );

  useEffect(() => {
    reloadCollection();
  }, [reloadCollection]);

  const onChangePage = (limit: number, offset: number) => {
    getEmployeesCollection({
      ...FILTERS_EMPLOYEES_TABLE,
      company: companyId,
      limit,
      offset
    });
  };

  const { data, loading } = employeesCollection;
  const listPagination = PaginationHelper.getListsPagination(onChangePage, EMPLOYEES_PER_PAGE, data?.meta.count);

  return (
    <>
      <Paragraph className="color_primary t_bold mb-25 mt-50" size="sm">
        {t('employeesPage.table.header')}
      </Paragraph>

      <Row className="mt-30 mb-30" gutter={[24, 24]} type="flex">
        <Col xs={{ span: 24, order: 2 }} sm={{ span: 15, order: 1 }} lg={17} xl={18}>
          <EmployeesListFilters onSearchEmployees={searchEmployee.set} changePlan={plan.set} />
        </Col>
      </Row>

      <Table
        className="clw-table clw-table_align-left clw-table_without-no-data clw-table_first-col-bold mb-25 mt-15"
        size="middle"
        loading={loading}
        columns={TableFormatHelper.translateTitles(employeesCollectionColumns, t)}
        dataSource={data?.data}
        pagination={listPagination}
        rowKey={record => record.id}
        footer={!!data?.data.length || loading ? undefined : () => <EmptyData />}
        scroll={{ x: true }}
        bordered
      />
    </>
  );
};

const containers = compose(communicationEmployees.injector);

export const EmployeesList = containers(EmployeesListComponent);
