import React, { useCallback } from 'react';
import { Col, Row } from 'antd/es/grid';
import { Paragraph } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import AntSelect from 'antd/es/select';
import debounce from 'lodash.debounce';
import Spin from 'antd/es/spin';
import { EmptyData, QuickSearch } from 'common/components';
import { SEARCH_WAIT_TIME } from 'common/config';
import { PLANS_PARAMS_ACTIVE } from 'entities/SubscriptionPlans/SubscriptionPlans.consts';
import {
  communicationSubscriptionPlans,
  ISubscriptionPlansConnectedProps
} from 'entities/SubscriptionPlans/SubscriptionPlans.communication';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';

interface IComponentProps {
  onSearchEmployees: (value: string) => void;
  changePlan: (value: any) => void;
}

type AllProps = IComponentProps & ISubscriptionPlansConnectedProps & ICompaniesConnectedProps;

const EmployeesListFiltersComponents = (props: AllProps) => {
  const {
    onSearchEmployees,
    getSubscriptionPlansForEmployeesCollection,
    subscriptionPlansForEmployeesCollection,
    changePlan,
    companiesCurrent
  } = props;
  const { t } = useTranslation();
  const { loading, data: plans } = subscriptionPlansForEmployeesCollection;
  const plansList = plans?.data;
  const companyId = companiesCurrent?.data?.data?.id;

  const debounceSearch = useCallback(
    debounce((value: string) => {
      companyId && getSubscriptionPlansForEmployeesCollection({ ...PLANS_PARAMS_ACTIVE, company: companyId, title: value });
    }, SEARCH_WAIT_TIME),
    [getSubscriptionPlansForEmployeesCollection]
  );

  return (
    <Row gutter={[24, 24]}>
      <Col lg={12} xl={5}>
        <Paragraph size="xs">{t('employeesPage.table.plan')}</Paragraph>

        <AntSelect
          dropdownClassName="clp-select__dropdown"
          className="clp-select clp-select_bordered_bottom clp-select_width_sm w-100p"
          allowClear
          onChange={changePlan}
          notFoundContent={loading ? <Spin size="small" /> : <EmptyData />}
          showSearch
          filterOption={false}
          onSearch={debounceSearch}
          onDropdownVisibleChange={open => {
            if (!companyId || !open) {
              return;
            }

            getSubscriptionPlansForEmployeesCollection({
              ...PLANS_PARAMS_ACTIVE,
              company: companyId,
              title: ''
            });
          }}
        >
          {plansList?.map(({ title, id }) => (
            <AntSelect.Option key={id} value={id}>
              {title}
            </AntSelect.Option>
          ))}
        </AntSelect>
      </Col>

      <Col lg={12} xl={5}>
        <QuickSearch label={t('employeesPage.table.employee')} onSearch={onSearchEmployees} />
      </Col>
    </Row>
  );
};

export const EmployeesListFilters = communicationCompanies.injector(
  communicationSubscriptionPlans.injector(EmployeesListFiltersComponents)
);
