import React, { useEffect } from 'react';
import Icon from 'antd/es/icon';
import List from 'antd/es/list';
import { compose } from 'redux';
import { Button } from '@axmit/clp-library';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { communicationPayment, IPaymentConnectedProps } from 'entities/Payment/Payment.communication';

type AllProps = IPaymentConnectedProps & IUsersConnectedProps;

const CardsListComponent = ({
  usersCurrent,
  getPaymentMethodCollection,
  paymentMethodCollection,
  clearPaymentMethodCollection,
  deletePaymentMethod,
  paymentMethod
}: AllProps) => {
  const { data: methods } = paymentMethodCollection;
  const userId = usersCurrent?.data?.id;

  useEffect(() => {
    userId && getPaymentMethodCollection({ userId });

    return () => clearPaymentMethodCollection();
  }, [userId, getPaymentMethodCollection, clearPaymentMethodCollection]);

  if (!userId || !methods?.data?.length) {
    return null;
  }

  return (
    <List
      className="mt-30"
      loading={paymentMethodCollection.loading || paymentMethod.loading}
      dataSource={methods.data}
      renderItem={({ id, card }) => {
        if (!card) {
          return '-';
        }

        return (
          <div key={id}>
            **** **** **** {card.last4}
            <Button
              type="link"
              size="small"
              onClick={() => {
                deletePaymentMethod({ id: userId, idPm: id });
              }}
            >
              <Icon type="close" />
            </Button>
          </div>
        );
      }}
    />
  );
};

const containers = compose(communicationPayment.injector, communicationUsers.injector);

export const CardsList = containers(CardsListComponent);
