import { Paragraph } from '@axmit/clp-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'antd/es/grid/row';
import Button from 'antd/es/button/button';
import List from 'antd/es/list';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import { ButtonProps } from 'antd/lib/button';
import { Spinner } from 'common/components';
import { SvgClose } from 'common/SVG/SvgClose';
import { PaginationHelper } from 'common/helpers';
import {
  communicationCompanyInvitation,
  ICompanyInvitationConnectedProps
} from 'entities/CompanyInvitations/Invitations.communication';
import { INVITES_PER_PAGE } from 'entities/CompanyInvitations/CompanyInvitations.consts';

interface IComponentProps {
  companyId: string;
}

type AllProps = ICompanyInvitationConnectedProps & IComponentProps;

const PendingListComponent = (props: AllProps) => {
  const {
    getCompanyInvitationsCollection,
    deleteCompanyInvitationsModel,
    initCompanyInvitationsReInvite,
    companyInvitationsReInvite,
    companyInvitationsCollection,
    companyId
  } = props;
  const { i18n } = useTranslation();
  const [modalId, showModal] = useState<string | undefined>();

  useEffect(() => {
    getCompanyInvitationsCollection({ company: companyId, isAccepted: false, limit: INVITES_PER_PAGE });
  }, [getCompanyInvitationsCollection, companyId]);

  const { loading: reInviteLoading } = companyInvitationsReInvite;
  const { data, loading } = companyInvitationsCollection;
  const { data: items, meta } = data || {};
  const hasItems = !!items?.length;

  const onChangePage = (limit: number, offset: number) => {
    getCompanyInvitationsCollection({
      company: companyId,
      isAccepted: false,
      limit,
      offset
    });
  };

  const resend = async () => {
    if (!modalId) {
      return;
    }

    await initCompanyInvitationsReInvite(modalId);
    showModal(undefined);
  };

  const listPagination = PaginationHelper.getListsPagination(onChangePage, INVITES_PER_PAGE, meta?.count);

  return (
    <Spinner spinning={loading || reInviteLoading}>
      <Paragraph className="color_primary t_bold" size="sm">
        {i18n.t('pending')}
      </Paragraph>

      {hasItems ? (
        <List
          dataSource={items}
          pagination={listPagination}
          renderItem={({ email, id }) => (
            <Row key={id} type="flex" align="middle">
              <Button
                className="pl-0 pl-0 pr-5 pr-0 pt-2"
                type="link"
                onClick={() => {
                  deleteCompanyInvitationsModel(id);
                }}
              >
                <SvgClose />
              </Button>

              <Paragraph size="xs" className="m-0 pb-2">
                {email}
              </Paragraph>

              <Button
                className="pl-0 pl-5 pr-5 pb-2 pt-2"
                type="link"
                onClick={() => {
                  showModal(id);
                }}
              >
                <Icon type="redo" className="svg-icon_black" />
              </Button>
            </Row>
          )}
        />
      ) : (
        !loading && (
          <Paragraph className="mb-20" size="xs">
            {i18n.t('employeesPage.pendingList.noDataPlaceholder')}
          </Paragraph>
        )
      )}

      <Modal
        className="alert-modal"
        title={i18n.t('employeesPage.pendingList.resendHeader')}
        visible={!!modalId}
        onOk={resend}
        onCancel={() => showModal(undefined)}
        okButtonProps={okButtonProps}
        cancelButtonProps={cancelButtonProps}
        okText={i18n.t('employeesPage.pendingList.resendBtn')}
        cancelText={i18n.t('defaultBtnCancel')}
      >
        <Spinner spinning={reInviteLoading}>{i18n.t('employeesPage.pendingList.resendDescription')}</Spinner>
      </Modal>
    </Spinner>
  );
};

export const PendingList = communicationCompanyInvitation.injector(PendingListComponent);

const okButtonProps: ButtonProps = { className: 'clp-btn ml-20', size: 'small' };
const cancelButtonProps: ButtonProps = { className: 'clp-btn ml-20', size: 'small', type: 'default' };
