import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { getCreds } from '@axmit/axios-patch-jwt';
import { EPrivateRoutes, EPublicRoutes } from 'common/const/Routes.const';
import { AuthPage, SignUpCorporate, SignUpForm } from 'entities/Auth/components';

class LayoutPublicComponent extends React.PureComponent<RouteComponentProps> {
  async componentDidMount() {
    const creds = await getCreds();

    if (creds.access) {
      this.props.history.push(EPrivateRoutes.Main);
    }
  }

  public render() {
    return (
      <Switch>
        <Route path={EPublicRoutes.SignUpCorporate} component={SignUpCorporate} />
        <Route path={EPublicRoutes.SignUpPersonal} component={SignUpForm} />
        <Route path="*" component={AuthPage} />
      </Switch>
    );
  }
}

export const LayoutPublic = withRouter(LayoutPublicComponent);
