import { AntdFormHelper } from '@axmit/antd-helpers';
import { Button, Paragraph } from '@axmit/clp-library';
import { Col, Row } from 'antd/es/grid';
import Form, { FormComponentProps } from 'antd/es/form';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormItem from 'antd/es/form/FormItem';
import AntSelect from 'antd/es/select';
import Spin from 'antd/es/spin';
import debounce from 'lodash.debounce';
import moment from 'moment';
import { Desktop, EmptyData, Mobile, TwoColorsField } from 'common/components';
import { Breadcrumb } from 'common/components/Breadcrumb';
import { EPrivateRoutes } from 'common/const';
import { DateHelper, FormatHelper, TableFormatHelper, ValidateHelper } from 'common/helpers';
import { SvgClose } from 'common/SVG/SvgClose';
import { BE_DATE_FORMAT_DEFAULT, SEARCH_WAIT_TIME } from 'common/config';
import { DatePicker } from 'common/components/DatePicker';
import { EmployeeRequestHistory } from 'entities/Employees/components/EmployeeRequestHistory';
import { communicationEmployees, IEmployeesConnectedProps } from 'entities/Employees/Employees.communication';
import { IEmployeesModel, IEmployeesUpdateParams } from 'entities/Employees/Employees.models';
import { PLANS_PARAMS_ACTIVE } from 'entities/SubscriptionPlans/SubscriptionPlans.consts';
import {
  communicationSubscriptionPlans,
  ISubscriptionPlansConnectedProps
} from 'entities/SubscriptionPlans/SubscriptionPlans.communication';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';

interface IComponentProps {
  employee: IEmployeesModel;
}

type AllProps = IComponentProps &
  FormComponentProps &
  IEmployeesConnectedProps &
  ISubscriptionPlansConnectedProps &
  ICompaniesConnectedProps;

const EmployeePageFormComponent = (props: AllProps) => {
  const {
    deleteEmployeesModel,
    employee,
    updateEmployeesModel,
    form,
    companiesCurrent,
    clearSubscriptionPlansForEmployeeCollection,
    subscriptionPlansForEmployeeCollection,
    getSubscriptionPlansPreview,
    subscriptionPlansPreview,
    clearSubscriptionPlansPreview,
    getSubscriptionPlansForEmployeeCollection
  } = props;
  const { loading: plansLoading, data: plans } = subscriptionPlansForEmployeeCollection;
  const companyId = companiesCurrent?.data?.data?.id;
  const plansList = plans?.data;
  const { t } = useTranslation();

  const {
    balance,
    amountReceived,
    subscriptionPlanAmount,
    subscriptionPlanPeriod,
    subscriptionPlanName,
    subscriptionPlan,
    nextTransferDate
  } = employee;
  const name = TableFormatHelper.formatFullName('', employee);
  const { id: employeeId } = employee;
  const { getFieldError } = form;

  const [plan, setPlan] = useState<string | undefined>(subscriptionPlanName || '');
  const planError = getFieldError('subscriptionPlan');

  const [date, setDate] = useState<moment.Moment | undefined>(
    plan && nextTransferDate ? moment(nextTransferDate, BE_DATE_FORMAT_DEFAULT) : undefined
  );
  const disabledDate = !plan;
  const dateError = getFieldError('nextTransferDate');

  useEffect(() => {
    return () => clearSubscriptionPlansPreview();
  }, [clearSubscriptionPlansPreview]);

  useEffect(() => {
    return () => clearSubscriptionPlansForEmployeeCollection();
  }, [clearSubscriptionPlansForEmployeeCollection]);

  const submit = () => {
    form.validateFields(err => {
      if (err) {
        return;
      }

      const planId = (plan === subscriptionPlanName ? subscriptionPlan : plan) || null;
      const params: IEmployeesUpdateParams = { id: employeeId, data: { subscriptionPlan: planId } };
      const formattedDate: string | null = date?.format(BE_DATE_FORMAT_DEFAULT) || null;
      const dateWasChanged = formattedDate !== nextTransferDate;

      if (dateWasChanged) {
        params.data.nextTransferDate = date?.format(BE_DATE_FORMAT_DEFAULT) || undefined;
      }

      updateEmployeesModel(params);
    });
  };

  const updatePlan = (newPlan: string) => {
    newPlan && getSubscriptionPlansPreview(newPlan);
    setPlan(newPlan);
  };

  const debounceSearch = useCallback(
    debounce((value: string) => {
      companyId && getSubscriptionPlansForEmployeeCollection({ ...PLANS_PARAMS_ACTIVE, company: companyId, title: value });
    }, SEARCH_WAIT_TIME),
    [getSubscriptionPlansForEmployeeCollection, companyId]
  );

  if (!companyId) {
    return null;
  }

  const { data: newPlanPreview, loading: newPlanLoading } = subscriptionPlansPreview;
  const { amount, period } = newPlanPreview || {};

  return (
    <>
      <Desktop>
        <Breadcrumb previous={t('employeePage.breadcrumbMain')} previousHref={EPrivateRoutes.Employees} current={name} />
      </Desktop>

      <Mobile>
        <Paragraph className="color_primary t_bold" size="sm">
          {name}
        </Paragraph>
      </Mobile>

      <Row gutter={[50, 30]}>
        <Col xl={12}>
          <Paragraph className="color_primary t_bold" size="sm">
            {t('employeePage.details.header')}
          </Paragraph>

          <TwoColorsField
            leftSlot={t('employeePage.details.balance')}
            rightSlot={FormatHelper.getCurrency(balance)}
            className="mb-10"
          />

          <TwoColorsField
            leftSlot={t('employeePage.details.totalReceived')}
            rightSlot={FormatHelper.getCurrency(amountReceived)}
            className="mb-30"
          />

          <>
            <Paragraph className="color_primary t_bold" size="sm">
              {t('employeePage.plan.header')}
            </Paragraph>

            <FormItem
              label={t('employeePage.plan.planSelectorLabel')}
              className="mob-form-wrapper"
              validateStatus={planError && planError[0] ? ValidateHelper.getValidateStatus(planError[0]) : undefined}
              help={planError}
            >
              <AntSelect
                defaultValue={plan}
                dropdownClassName="clp-select__dropdown"
                className="clp-select clp-select_bordered_bottom clp-select_width_md"
                allowClear
                notFoundContent={plansLoading ? <Spin size="small" /> : <EmptyData />}
                showSearch
                onChange={updatePlan}
                filterOption={false}
                onSearch={debounceSearch}
                onDropdownVisibleChange={open =>
                  open && getSubscriptionPlansForEmployeeCollection({ ...PLANS_PARAMS_ACTIVE, company: companyId, title: '' })
                }
              >
                {plansList?.map(({ title, id }) => (
                  <AntSelect.Option key={id} value={id}>
                    {title}
                  </AntSelect.Option>
                ))}
              </AntSelect>
            </FormItem>

            <TwoColorsField
              leftSlot={t('employeePage.plan.amount')}
              rightSlot={newPlanLoading ? '' : FormatHelper.getCurrency(amount || subscriptionPlanAmount)}
              className="mb-10"
            />

            <TwoColorsField
              leftSlot={t('employeePage.plan.period')}
              rightSlot={newPlanLoading ? '' : t('employeePage.plan.month', { count: period || subscriptionPlanPeriod || 0 })}
              className="mb-10"
            />

            <FormItem
              label={t('employeePage.plan.date')}
              className="mob-form-wrapper"
              validateStatus={dateError && dateError[0] ? ValidateHelper.getValidateStatus(dateError[0]) : undefined}
              help={dateError}
            >
              <DatePicker
                disabled={disabledDate}
                className="mt-10"
                disabledDate={DateHelper.isTodayOrBefore}
                minDate={DateHelper.getTomorrow().toDate()}
                value={date}
                onChange={value => setDate(value || undefined)}
              />
            </FormItem>

            <Button className="ant-btn_mob-block float_l" htmlType="submit" type="primary" onClick={submit}>
              {t('defaultBtnSave')}
            </Button>

            <Paragraph size="sm" className=" ">
              <Button
                className="pr-5"
                type="link"
                size="small"
                onClick={() => {
                  deleteEmployeesModel(employeeId);
                }}
              >
                <SvgClose />
              </Button>
              {t('employeePage.plan.btnRemove')}
            </Paragraph>
          </>
        </Col>

        <Col xl={12}>
          <EmployeeRequestHistory employeeId={employeeId} />
        </Col>
      </Row>
    </>
  );
};

export const EmployeePageForm = communicationCompanies.injector(
  communicationSubscriptionPlans.injector(
    communicationEmployees.injector(
      Form.create<AllProps>({
        mapPropsToFields(props: AllProps) {
          const { employeesModel } = props;

          return AntdFormHelper.mapValidationToFields(employeesModel?.params?.data, employeesModel?.errors);
        }
      })(EmployeePageFormComponent)
    )
  )
);
