import { Container } from '@axmit/clp-library';
import React from 'react';
import { useHistory } from 'react-router';
import { MobilePage } from 'common/components';
import { EPrivateRoutes } from 'common/const';
import { ChangePasswordForm } from 'entities/Auth/components/ChangePasswordForm';

export const ChangePasswordMobilePage = () => {
  const history = useHistory();

  const handleCancel = () => {
    history.push(EPrivateRoutes.PersonalInfoMobile);
  };

  return (
    <Container>
      <MobilePage headerKey="changePassword.header" desktopUrl={EPrivateRoutes.PersonalInfo}>
        <ChangePasswordForm cancel={handleCancel} />
      </MobilePage>
    </Container>
  );
};
