import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { ValidateFieldsOptions } from 'antd/es/form/Form';
import React from 'react';
import { CreatePasswordValidationRules } from 'common/const';
import i18n from 'app/locales/i18n';

interface IPassCreating {
  validateFields: (fields: string[], options: ValidateFieldsOptions) => void;
  getFieldValue: (fieldName: string) => any;
  confirmFieldName?: string;
  newFieldName?: string;
}

function usePasswordCreating({
  confirmFieldName = 'confirm',
  newFieldName = 'newPassword',
  validateFields,
  getFieldValue
}: IPassCreating) {
  const confirmDirty = HooksHelper.useStateBuilder(false);

  const validateToNextPassword = (rule: string, value: string, callback: Function) => {
    if (value && confirmDirty.value) {
      validateFields([confirmFieldName], { force: true });
    }

    return callback();
  };

  const compareToFirstPassword = (rule: string, value: string, callback: Function) => {
    if (value && value !== getFieldValue(newFieldName)) {
      return callback(i18n.t('errorNewPasswordInconsistentPasswords'));
    }

    return callback();
  };

  const newPassRules = [
    ...CreatePasswordValidationRules,
    {
      validator: validateToNextPassword
    }
  ];

  const confirmPassRules = [
    {
      required: true,
      message: () => i18n.t('errorPasswordConfirmation')
    },
    {
      validator: compareToFirstPassword
    }
  ];

  const confirmBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;

    confirmDirty.set(prevValue => prevValue || !value);
  };

  return {
    confirmBlur,
    newPassRules,
    confirmPassRules
  };
}

export const AuthHooks = {
  usePasswordCreating
};
