import { DataSourceItemType } from 'antd/lib/auto-complete';
import { ICountriesCollection } from 'entities/Countries/Countries.models';

function formatData(data?: ICountriesCollection | null): DataSourceItemType[] | undefined {
  return data?.data.map(item => ({ value: item.id, text: item.name }));
}

export const CountriesHelper = {
  formatData
};
