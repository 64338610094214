import axios, { AxiosInstance } from 'axios';
import { BaseHttpTransport } from '@axmit/transport';
import {
  ISubscriptionPlansCollection,
  ISubscriptionPlansCollectionParams,
  ISubscriptionPlansCreateParams,
  ISubscriptionPlansModel,
  ISubscriptionPlansUpdateParams
} from 'entities/SubscriptionPlans/SubscriptionPlans.models';

class SubscriptionPlansTransportClass extends BaseHttpTransport<
  AxiosInstance,
  ISubscriptionPlansModel,
  ISubscriptionPlansCollection,
  ISubscriptionPlansCreateParams,
  ISubscriptionPlansUpdateParams,
  ISubscriptionPlansCollectionParams
> {
  public archive = (id: string) => {
    return axios.post(`${this.path}/${id}/archive`);
  };

  public unArchive = (id: string) => {
    return axios.post(`${this.path}/${id}/unarchive`);
  };
}

export const subscriptionPlansTransport = new SubscriptionPlansTransportClass('subscription-plans', axios);
