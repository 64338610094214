import { AntdFormHelper } from '@axmit/antd-helpers';
import { Button, FormItem, Input, PhoneInput } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import Spin from 'antd/es/spin';
import { FormComponentProps } from 'antd/es/form';
import React, { FormEvent } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EPublicRoutes } from 'common/const';
import { PhoneHooks } from 'common/hooks';
import { TwoColumnsWithImage } from 'common/components/TwoColumnsWithImage';
import imgOrchestra565 from 'app/assets/images/orchestra_w_565.jpg';
import imgOrchestra700 from 'app/assets/images/orchestra_w_700.jpg';
import imgOrchestra1440 from 'app/assets/images/orchestra_w_1440.jpg';
import imgOrchestra2000 from 'app/assets/images/orchestra_w_2000.jpg';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { IUserCompanyCreateParams } from 'entities/User/Users.models';
import { CorporateSignUpFormFields } from 'entities/Auth/Auth.const';
import { PasswordInput } from 'entities/Auth/components/PasswordInput';

interface IComponentProps {
  saveData: (newData: IUserCompanyCreateParams) => void;
}

type AllProps = FormComponentProps & IUsersConnectedProps & IComponentProps;

const SignUpCorporateFormComponent = (props: AllProps) => {
  const { form, usersCompany } = props;
  const { getFieldDecorator, getFieldError } = form;

  const { i18n } = useTranslation();
  const history = useHistory();

  const { phone, phoneValidateMessage, validatePhone, changePhone, phoneValidateStatus } = PhoneHooks.usePhone(
    usersCompany?.params?.phoneNumber || undefined,
    getFieldError('phoneNumber')
  );

  const submit = (e: FormEvent<HTMLFormElement>) => {
    const { saveData } = props;

    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      saveData({ ...values, phoneNumber: phone });
    });
  };

  return (
    <TwoColumnsWithImage
      imageSlot={
        <img
          sizes="calc(50vw - 20px)"
          srcSet={`
            ${imgOrchestra565} 565w,
            ${imgOrchestra700} 700w,
            ${imgOrchestra1440} 1440w,
            ${imgOrchestra2000} 2000w`}
          src={imgOrchestra700}
          alt="orchestra"
          className="img-sign"
        />
      }
      contentSlot={
        <Spin spinning={usersCompany.loading}>
          <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-25" level={2}>
            {i18n.t('signUpCorporateFormHeader')}
          </Typography.Title>

          <Typography.Text className="clp-typography-text clp-typography-text_size_md mob-text_center mb-50">
            {i18n.t('signUpMessageCorporate')}
          </Typography.Text>

          <Form className="mt-20" onSubmit={submit}>
            <Row gutter={22}>
              <Col {...wrapperCol}>
                <FormItem label={i18n.t('form.labels.companyName')}>
                  {getFieldDecorator(CorporateSignUpFormFields[0], {
                    rules: [
                      {
                        required: true,
                        message: () => i18n.t('errorRequiredDefault')
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>
            </Row>

            <Row gutter={22}>
              <Col {...wrapperCol}>
                <FormItem label={i18n.t('form.labels.firstName')}>
                  {getFieldDecorator(CorporateSignUpFormFields[1], {
                    rules: [
                      {
                        required: true,
                        message: () => i18n.t('errorRequiredDefault')
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>

              <Col {...wrapperCol}>
                <FormItem label={i18n.t('form.labels.lastName')}>
                  {getFieldDecorator(CorporateSignUpFormFields[2], {
                    rules: [
                      {
                        required: true,
                        message: () => i18n.t('errorRequiredDefault')
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>

              <Col {...wrapperCol}>
                <FormItem label={i18n.t('form.labels.email')} className="email-input">
                  {getFieldDecorator(CorporateSignUpFormFields[3], {
                    rules: [
                      {
                        type: 'email',
                        message: () => i18n.t('errorEmailNotEmail')
                      },
                      {
                        required: true,
                        message: () => i18n.t('errorRequiredDefault')
                      }
                    ]
                  })(<Input type="email" />)}
                </FormItem>
              </Col>

              <Col {...wrapperCol}>
                <FormItem
                  label={i18n.t('form.labels.phoneNumber')}
                  validateStatus={phoneValidateStatus}
                  help={phoneValidateMessage}
                >
                  <PhoneInput
                    defaultValue={usersCompany?.params?.phoneNumber || undefined}
                    onChange={changePhone}
                    validateTrigger={validatePhone}
                    block
                  />
                </FormItem>
              </Col>

              <Col {...wrapperCol}>
                <PasswordInput
                  label={i18n.t('form.labels.password')}
                  getFieldDecorator={getFieldDecorator}
                  fieldName={CorporateSignUpFormFields[4]}
                />
              </Col>
            </Row>

            <Row align="middle" gutter={22}>
              <Col {...wrapperCol} className="mob-form-wrapper">
                <Button
                  className="ant-btn_mob-block mob-form-wrapper"
                  disabled={usersCompany.loading}
                  type="primary"
                  htmlType="submit"
                  block
                >
                  {i18n.t('next')}
                </Button>
              </Col>

              <Col {...wrapperCol} className="mob-text_center">
                <Button type="link" className="pl-0 pr-0" onClick={() => history.push(EPublicRoutes.LogIn)}>
                  {i18n.t('signUpLogInLink')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      }
    />
  );
};

const wrapperCol = { xs: 24, xl: 11 };

export const SignUpCorporateForm = communicationUsers.injector(
  Form.create<AllProps>({
    mapPropsToFields(props: AllProps) {
      const { usersCompany } = props;

      return AntdFormHelper.mapValidationToFields(usersCompany?.params, usersCompany?.errors);
    }
  })(SignUpCorporateFormComponent)
);
