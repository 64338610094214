import { Container, LandingRow } from '@axmit/clp-library';
import React from 'react';
import { Route, Switch } from 'react-router';
import { EPublicRoutes } from 'common/const';
import imageKeyHolder from 'app/assets/images/keyHolder.png';
import { RestorePasswordForm } from 'entities/Auth/components/RestorePasswordForm';
import { NewPasswordForm } from 'entities/Auth/components/NewPasswordForm';
import { LogInForm } from 'entities/Auth/components/LogInForm';
import { GetConfirm } from 'entities/Auth/components/GetConfirm';
import { SignUpCommon } from 'entities/Auth/components/SignUpCommon';

export const AuthPage = () => {
  return (
    <Container>
      <LandingRow
        leftSlot={
          <Switch>
            <Route path={EPublicRoutes.SignUpCommon} component={SignUpCommon} />
            <Route path={EPublicRoutes.NewPassword} component={NewPasswordForm} />
            <Route path={EPublicRoutes.RestorePassword} component={RestorePasswordForm} />
            <Route path={EPublicRoutes.GetConfirm} component={GetConfirm} />
            <Route paths={[EPublicRoutes.LogIn, '*']} component={LogInForm} />
          </Switch>
        }
        rightSlot={<img src={imageKeyHolder} alt="auth" className="max-w_100 mt-50 mb-100" />}
      />

      <img src={imageKeyHolder} alt="map" className="hidden_desk w-100p mt-25 mb-30" />
    </Container>
  );
};
