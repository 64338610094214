import { useWindowSize } from 'react-use';

interface IInitialProps {
  min?: number;
  max?: number;
}

function useWidth({ min: from, max: to }: IInitialProps) {
  const { width } = useWindowSize();

  const inRange = (from ? width >= from : true) && (to ? width <= to : true);

  return { inRange };
}

export const EnvironmentHooks = {
  useWidth
};
