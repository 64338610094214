import React, { FormEvent, useEffect } from 'react';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import { FormItem, Input } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import { AntdFormHelper } from '@axmit/antd-helpers';
import { FormComponentProps } from 'antd/es/form';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { Spinner, MobFormButton } from 'common/components';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';
import { CountriesSelector } from 'entities/Countries/components/CountriesSelector';

type AllProps = FormComponentProps & ICompaniesConnectedProps;

const CompanyUpdateComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const { form, companiesModel, getCompaniesModel, companiesCurrent } = props;
  const { getFieldDecorator } = form;
  const country = HooksHelper.useStateBuilder<string | undefined>(undefined);
  const companyId = companiesCurrent?.data?.data?.id;

  useEffect(() => {
    companyId && getCompaniesModel(companyId);
  }, [getCompaniesModel, companyId]);

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { updateCompaniesModel } = props;

    form.validateFields((err, values) => {
      if (err || !companiesModel.data) {
        return;
      }

      const { id, name } = companiesModel.data;

      updateCompaniesModel({
        id,
        data: {
          id,
          name,
          country: country.value,
          ...values
        }
      });
    });
  };

  if (!companiesModel?.data) {
    return null;
  }

  const { beneficiaryName, address, countryData } = companiesModel.data;

  return (
    <Spinner spinning={companiesModel.loading}>
      <Form className="pt-20" onSubmit={submit}>
        <Row gutter={22}>
          <Col {...wrapperCol}>
            <FormItem label={t('form.labels.beneficiaryName')}>
              {getFieldDecorator('beneficiaryName', { initialValue: beneficiaryName })(<Input />)}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={22}>
          <Col {...wrapperCol}>
            <CountriesSelector
              form={form}
              fieldName="company.country"
              updateCountry={country.set}
              initialValue={countryData?.name}
            />
          </Col>

          <Col {...wrapperCol}>
            <FormItem label={t('form.labels.address')}>
              {getFieldDecorator('address', { initialValue: address })(<Input />)}
            </FormItem>
          </Col>
        </Row>

        <MobFormButton size="small" disabled={companiesModel.loading} type="primary" htmlType="submit" className="mt-20">
          {t('defaultBtnSave')}
        </MobFormButton>
      </Form>
    </Spinner>
  );
};

export const CompanyUpdate = communicationCompanies.injector(
  Form.create<AllProps>({
    mapPropsToFields(props: AllProps) {
      const { companiesModel } = props;

      return AntdFormHelper.mapValidationToFields(companiesModel?.params, companiesModel?.errors);
    }
  })(CompanyUpdateComponent)
);

const wrapperCol = { xs: 24, md: 12, lg: 8, xl: 6 };
