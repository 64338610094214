import React, { useEffect, useState } from 'react';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { SignUpCorporateForm } from 'entities/Auth/components/SignUpCorporateForm';
import { SignUpCorporateAdditiveForm } from 'entities/Auth/components/SignUpCorporateAdditiveForm';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { IUserCompanyCreateParams } from 'entities/User/Users.models';
import { CorporateSignUpFormFields } from 'entities/Auth/Auth.const';

type AllProps = IUsersConnectedProps;

const SignUpCorporateComponent = (props: AllProps) => {
  const [isVisibleAdditive, setVisibleAdditive] = useState<boolean>(false);
  const data = HooksHelper.useStateBuilder<IUserCompanyCreateParams | undefined>(undefined);
  const { usersCompany } = props;

  useEffect(() => {
    if (usersCompany.errors?.errors) {
      const { errors } = usersCompany.errors;

      const errorOnFirstForm = CorporateSignUpFormFields.some(item => !!errors[item]);

      setVisibleAdditive(!errorOnFirstForm);
    }
  }, [usersCompany.errors, setVisibleAdditive]);

  const saveData = (newData: IUserCompanyCreateParams) => {
    if (isVisibleAdditive) {
      const { addUsersCompany } = props;

      data.value && addUsersCompany({ ...data.value, ...newData, company: { ...data.value?.company, ...newData.company } });
    } else {
      data.set(newData);
      setVisibleAdditive(true);
    }
  };

  return isVisibleAdditive ? <SignUpCorporateAdditiveForm saveData={saveData} /> : <SignUpCorporateForm saveData={saveData} />;
};

export const SignUpCorporate = communicationUsers.injector(SignUpCorporateComponent);
