import React from 'react';
import { FormItem, Input } from '@axmit/clp-library';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { useTranslation } from 'react-i18next';
import { FormatHelper, ValidateHelper } from 'common/helpers';

interface IComponentProps {
  currency?: string | any;
  amount?: number;
  setCurrency?: (value: any) => void;
  setAmount: (value?: number) => void;
  setValid?: (value: boolean) => void;
  minAmount?: number;
  initValue?: number;
  isInvite?: boolean;
}

export const CurrencyForm = ({ amount, setAmount, setValid, minAmount = 1, initValue, isInvite }: IComponentProps) => {
  const { t } = useTranslation();

  const errorValueMoreMessage = t(isInvite ? 'errorValueMoreThenInvite' : 'errorValueMoreThen', { min: minAmount });
  const validateMessage = HooksHelper.useStateBuilder<string | undefined>(
    ValidateHelper.getAmountValidationMessage(minAmount, amount, errorValueMoreMessage)
  );
  const validateStatus = ValidateHelper.getValidateStatus(validateMessage.value);

  const changeAmountValue = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (!value) {
      validateMessage.set(t('errorRequiredDefault'));
      setValid && setValid(false);
      setAmount(undefined);

      return;
    }

    const formattedValue = FormatHelper.formatAmount(value);
    const isValid = formattedValue >= minAmount;

    validateMessage.set(isValid ? undefined : errorValueMoreMessage);
    setValid && setValid(isValid);
    setAmount(formattedValue);
  };

  return (
    <FormItem help={validateMessage.value} validateStatus={validateStatus}>
      <Input
        type="number"
        step={1}
        min={minAmount}
        value={amount}
        onChange={changeAmountValue}
        addonBefore="€"
        defaultValue={initValue}
      />
    </FormItem>
  );
};
