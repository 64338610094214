import { ColProps } from 'antd/es/grid';
import { ColumnProps } from 'antd/lib/table';
import { FormatHelper } from 'common/helpers';
import { IRequestTransactionModel } from 'entities/Requests/Requests.models';

export const REQUESTS_PER_PAGE = 8;
export const REQUESTS_COL: ColProps = { xl: 6, lg: 8, sm: 12, xs: 24 };

export const REQUESTS_TRANSACTIONS_PER_PAGE = 5;

enum ERequestHistoryColumns {
  type = 'type',
  description = 'description',
  amount = 'amount'
}

export const requestsHistoryColumns: ColumnProps<IRequestTransactionModel>[] = [
  { dataIndex: ERequestHistoryColumns.type, title: `type` },
  {
    dataIndex: ERequestHistoryColumns.description,
    title: `transferDate`
  },
  { dataIndex: ERequestHistoryColumns.amount, title: `amount`, render: FormatHelper.getCurrency, className: 'ta-r' }
];

export enum EContactDateType {
  now = 'now',
  other = 'other'
}

export enum EContactType {
  phone = 'phone',
  email = 'email',
  whatsApp = 'whatsApp'
}

export const contactTypes = [
  { label: 'By Email', value: EContactType.email },
  { label: 'By Phone', value: EContactType.phone },
  { label: 'By WhatsApp', value: EContactType.whatsApp }
];
