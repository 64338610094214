import Pagination from 'antd/es/pagination';
import { Row, Col } from 'antd/es/grid';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { EmptyData, Spinner } from 'common/components';
import { EPrivateRoutes } from 'common/const';
import { PaginationHelper } from 'common/helpers';
import { UsersHooks } from 'common/hooks/Users.hooks';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { RequestsListItem } from 'entities/Requests/components/RequestsListItem';
import { REQUESTS_COL, REQUESTS_PER_PAGE } from 'entities/Requests/Request.consts';
import { communicationRequests, IRequestsConnectedProps } from 'entities/Requests/Requests.communication';

type AllProps = IRequestsConnectedProps & IAuthConnectedProps;

const RequestsListComponent = ({ getRequestsCollection, clearRequestsCollection, requestsCollection, authAccount }: AllProps) => {
  const { currentUserId } = UsersHooks.useCurrent();

  useEffect(() => {
    return () => {
      clearRequestsCollection();
    };
  }, [clearRequestsCollection]);

  useEffect(() => {
    currentUserId &&
      getRequestsCollection({
        limit: REQUESTS_PER_PAGE,
        userRecipient: currentUserId,
        userSender: currentUserId,
        isEmployee: !!authAccount.data?.id,
        employee: authAccount.data?.id
      });
  }, [getRequestsCollection, currentUserId, authAccount.data]);

  const onChangePage = (limit: number, offset: number, page: number) => {
    setCurrentPage(page);
    getRequestsCollection({
      userSender: currentUserId,
      userRecipient: currentUserId,
      limit,
      offset,
      isEmployee: !!authAccount.data?.id,
      employee: authAccount.data?.id
    });
  };

  const data = requestsCollection?.data;
  const loading = requestsCollection?.loading;

  const collection = data?.data || [];
  const count = data?.meta.count;

  const employeeId = authAccount.data?.id;
  const [currentPage, setCurrentPage] = React.useState(1);
  useEffect(() => {
    setCurrentPage(1);
  }, [setCurrentPage, employeeId]);

  if (!count) {
    return <EmptyData />;
  }

  const listPagination = PaginationHelper.getListsPagination(onChangePage, REQUESTS_PER_PAGE, count, currentPage);

  return (
    <Spinner spinning={loading}>
      <Row gutter={[16, 16]} type="flex">
        {collection.map(item => (
          <Col {...REQUESTS_COL} key={item.id}>
            <Link to={`${EPrivateRoutes.RequestViewPage}/${item.id}`}>
              <RequestsListItem request={item} />
            </Link>
          </Col>
        ))}
      </Row>

      {listPagination && <Pagination size="small" {...listPagination} />}
    </Spinner>
  );
};

const containers = compose(communicationRequests.injector, communicationAuth.injector);

export const RequestsList = containers(RequestsListComponent);
