import { useSelector } from 'react-redux';
import { IApplicationState } from 'app/store/reducers';

interface IUseCurrent {
  currentUserId?: string;
}

function useCurrent(): IUseCurrent {
  const userId = useSelector<IApplicationState>(state => state?.users.current?.data?.id);

  return { currentUserId: userId ? String(userId) : undefined };
}

function useCompany() {
  const isCompanyAccount = useSelector<IApplicationState>(state => state?.companies.current?.data?.isCompanyAccount);

  return { isCompanyAccount: isCompanyAccount ? Boolean(isCompanyAccount) : undefined };
}

export const UsersHooks = {
  useCompany,
  useCurrent
};
