import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import {
  communicationSubscriptionPlans,
  ISubscriptionPlansConnectedProps
} from 'entities/SubscriptionPlans/SubscriptionPlans.communication';
import { PlansUpdateForm } from 'entities/SubscriptionPlans/components/PlansUpdateForm';

type AllProps = ISubscriptionPlansConnectedProps;

const PlansUpdateComponent = (props: AllProps) => {
  const { clearSubscriptionPlansModel, getSubscriptionPlansModel } = props;
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    return () => clearSubscriptionPlansModel();
  }, [clearSubscriptionPlansModel]);

  useEffect(() => {
    id && getSubscriptionPlansModel(id);
  }, [getSubscriptionPlansModel, id]);

  return <PlansUpdateForm />;
};

export const PlansUpdate = communicationSubscriptionPlans.injector(PlansUpdateComponent);
