import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { MobilePage } from 'common/components';
import { EPrivateRoutes } from 'common/const';
import { Plans } from 'entities/SubscriptionPlans/components';

export const DashboardCompanyMobile = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname.startsWith(`${EPrivateRoutes.Employee}/`)) {
      history.push(location.pathname.replace(EPrivateRoutes.Employee, EPrivateRoutes.EmployeeMobile), location.state);
    }

    if (location.pathname === EPrivateRoutes.Employees) {
      history.push(EPrivateRoutes.EmployeesMobile);
    }

    if (location.pathname === EPrivateRoutes.PersonalInfo) {
      history.push(EPrivateRoutes.PersonalInfoMobile);
    }

    if (location.pathname === EPrivateRoutes.CompanyInfo) {
      history.push(EPrivateRoutes.CompanyInfoMobile);
    }

    if (location.pathname === EPrivateRoutes.AutoPayment) {
      history.push(EPrivateRoutes.AutoPaymentMobile);
    }

    if (location.pathname === EPrivateRoutes.Account) {
      history.push(EPrivateRoutes.AccountMobile);
    }
  }, [location, history]);

  if (location.pathname === EPrivateRoutes.CreatePlans) {
    return (
      <MobilePage desktopUrl={EPrivateRoutes.CreatePlans} headerKey="plansPage.breadcrumbPlansCreate">
        <Plans />
      </MobilePage>
    );
  }

  return (
    <MobilePage desktopUrl={EPrivateRoutes.Main}>
      <Plans />
    </MobilePage>
  );
};
