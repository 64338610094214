import { AntdFormHelper } from '@axmit/antd-helpers';
import { Button, FormItem, Input, MonthPicker } from '@axmit/clp-library';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { Col, Row } from 'antd/es/grid';
import Form, { FormComponentProps } from 'antd/es/form';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardCvcValidationRules, CardNumberValidationRules } from 'common/const';
import { DateHelper } from 'common/helpers';
import { Spinner } from 'common/components';
import { communicationCompanyPayment, ICompanyPaymentConnectedProps } from 'entities/CompanyPayment/CompanyPayment.communication';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';

type AllProps = ICompaniesConnectedProps & ICompanyPaymentConnectedProps & FormComponentProps;

const CompanyCardCreateFormComponent = (props: AllProps) => {
  const { form, companiesCurrent, companyPaymentModel, addCompanyPaymentModel } = props;
  const { i18n } = useTranslation();
  const { loading } = companyPaymentModel;
  const { getFieldDecorator } = form;
  const companyId = companiesCurrent?.data?.data?.id;

  const expiry = HooksHelper.useStateBuilder<moment.Moment>(DateHelper.getNextYear());

  const submit = () => {
    form.validateFields((err, values) => {
      if (err || !companyId) {
        return;
      }

      addCompanyPaymentModel({
        cvc: values.cvc,
        // eslint-disable-next-line @typescript-eslint/camelcase
        exp_month: Number(expiry.value.format('MM')),
        // eslint-disable-next-line @typescript-eslint/camelcase
        exp_year: Number(expiry.value.format('YY')),
        number: values.number,
        companyId
      });
    });
  };

  return (
    <Spinner spinning={loading}>
      <Form>
        <Row gutter={30}>
          <Col {...wrapperCol} lg={12}>
            <FormItem label={i18n.t('cardCreate.number.label')}>
              {getFieldDecorator('number', {
                rules: CardNumberValidationRules
              })(<Input />)}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={30}>
          <Col {...wrapperCol}>
            <FormItem label={i18n.t('cardCreate.expiry.label')}>
              <MonthPicker
                allowClear={false}
                format="MM/YY"
                defaultValue={expiry.value}
                disabledDate={DateHelper.isTodayOrBefore}
                onChange={date => date && expiry.set(date)}
              />
            </FormItem>
          </Col>

          <Col {...wrapperCol}>
            <FormItem label={i18n.t('cardCreate.cvc.label')}>
              {getFieldDecorator('cvc', {
                rules: CardCvcValidationRules
              })(<Input />)}
            </FormItem>
          </Col>

          <Col xs={24}>
            <Button type="primary" onClick={submit} className="ant-btn_mob-block" htmlType="submit" disabled={loading}>
              {i18n.t('autoPaymentPage.btnSubmit')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Spinner>
  );
};

const wrapperCol = { xs: 24, md: 12, lg: 6 };

export const CompanyCardCreateForm = communicationCompanies.injector(
  communicationCompanyPayment.injector(
    Form.create<AllProps>({
      mapPropsToFields({ companyPaymentModel }) {
        return AntdFormHelper.mapValidationToFields(companyPaymentModel.params, companyPaymentModel.errors);
      }
    })(CompanyCardCreateFormComponent)
  )
);
