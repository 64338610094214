import { default as Spin, SpinProps } from 'antd/es/spin';
import { PropsWithChildren } from 'react';
import * as React from 'react';
import { SPIN_WAIT_TIME } from 'common/config';

interface IComponentProps extends SpinProps {}

export const Spinner = (props: PropsWithChildren<IComponentProps>) => {
  return <Spin delay={SPIN_WAIT_TIME} {...props} />;
};
