import axios from 'axios';
import {
  IPaymentBalance,
  IPaymentCreateViewParams,
  IPaymentIntentData,
  IPaymentIntentParams,
  IPaymentMethodCollection,
  IPaymentMethodCollectionParams,
  IPaymentMethodDetachParams,
  IPaymentMethodShort
} from 'entities/Payment/Payment.models';

const baseMethodsPath = '/users/';
const baseEmployeesPath = '/employees/';

export class PaymentMethodsTransport {
  public add = ({ userId, ...rest }: IPaymentCreateViewParams): Promise<IPaymentMethodShort> => {
    return axios.post(`${baseMethodsPath}${userId}/payment-methods`, rest);
  };

  public delete = ({ id, idPm }: IPaymentMethodDetachParams): Promise<void> => {
    return axios.delete(`${baseMethodsPath}${id}/payment-methods/${idPm}`);
  };

  public getCollection = ({ userId }: IPaymentMethodCollectionParams): Promise<IPaymentMethodCollection> => {
    return axios.get(`${baseMethodsPath}${userId}/payment-methods`);
  };

  public addUserIntent = ({ userId, ...rest }: IPaymentIntentParams): Promise<IPaymentIntentData> => {
    return axios.post(`${baseMethodsPath}${userId}/payment-intent`, rest);
  };

  public addEmployeeIntent = ({ userId, ...rest }: IPaymentIntentParams): Promise<IPaymentIntentData> => {
    return axios.post(`${baseEmployeesPath}${userId}/payment-intent`, rest);
  };

  public getBalance = (id: string): Promise<IPaymentBalance> => {
    return axios.get(`${baseMethodsPath}${id}/balance`);
  };
}
