import { ColumnProps } from 'antd/lib/table';
import { TFunction } from 'i18next';

function formatFullName<T extends { lastName: string; firstName: string }>(value: string, { lastName, firstName }: T) {
  return `${lastName} ${firstName.charAt(0)}.`;
}

function translateTitles<T>(data: ColumnProps<T>[], t: TFunction): ColumnProps<T>[] {
  return data.map(({ title, ...rest }) => ({ title: title ? t(String(title)) : '', ...rest }));
}

export const TableFormatHelper = {
  translateTitles,
  formatFullName
};
