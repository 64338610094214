import * as React from 'react';
import { Route, Switch } from 'react-router';
import { EPrivateRoutes } from 'common/const';
import { AccountRequests } from 'app/pages/AccountRequests';
import { RequestView } from 'entities/Requests/components/RequestView';

export const DashboardRequests = () => {
  return (
    <Switch>
      <Route path={`${EPrivateRoutes.RequestViewPage}/:id`} component={RequestView} />
      <Route path="*" component={AccountRequests} />
    </Switch>
  );
};
