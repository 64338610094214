import axios from 'axios';
import { BaseTransport } from 'common/dto/base.transport';
import {
  ICompanyCollection,
  ICompanyCollectionParams,
  ICompanyTransactionFullAmount,
  ICompanyTransactionFullAmountParams,
  ICompanyTransferHistory,
  ICompanyTransferHistoryParams
} from 'entities/Companies/Companies.models';

export class CompaniesTransport extends BaseTransport<null, ICompanyCollection, null, null, ICompanyCollectionParams> {
  public getSummary = (id: string) => {
    return axios.get(`${this.path}/${id}/summary`);
  };

  public getTransferHistory = ({ id, ...rest }: ICompanyTransferHistoryParams): Promise<ICompanyTransferHistory> => {
    return axios.get(`${this.path}/${id}/transfer-history`, { params: rest });
  };

  public getFullAmount = (params: ICompanyTransactionFullAmountParams): Promise<ICompanyTransactionFullAmount> => {
    return axios.get(`${this.path}/full-amount`, { params });
  };
}
