import React from 'react';

export const SvgClose = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
    <g clipPath="url(#clip0)">
      <path
        d="M11.625 2.48206L10.0179 0.875L6 4.89294L1.98206 0.875L0.375 2.48206L4.39294 6.5L0.375 10.5179L1.98206 12.125L6 8.10706L10.0179 12.125L11.625 10.5179L7.60706 6.5L11.625 2.48206Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
