import React, { useEffect } from 'react';
import List from 'antd/es/list';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '@axmit/clp-library';
import { PaginationHelper, TableFormatHelper } from 'common/helpers';
import { EPrivateRoutes } from 'common/const';
import { communicationEmployees, IEmployeesConnectedProps } from 'entities/Employees/Employees.communication';
import { PLANS_EMPLOYEES_PER_PAGE } from 'entities/SubscriptionPlans/SubscriptionPlans.consts';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';
import {
  communicationSubscriptionPlans,
  ISubscriptionPlansConnectedProps
} from 'entities/SubscriptionPlans/SubscriptionPlans.communication';

type AllProps = ISubscriptionPlansConnectedProps & IEmployeesConnectedProps & ICompaniesConnectedProps;

const PlansArchiveInfoComponent = (props: AllProps) => {
  const {
    subscriptionPlansModel,
    getEmployeesPlanCollection,
    clearEmployeesPlanCollection,
    employeesPlanCollection,
    companiesCurrent
  } = props;
  const { data: employees } = employeesPlanCollection;
  const { data: company } = companiesCurrent;
  const { data: plan } = subscriptionPlansModel;
  const planId = plan?.id;
  const companyId = company?.data?.id;

  const { t } = useTranslation();

  useEffect(() => {
    const hasData = companyId && planId;

    hasData && getEmployeesPlanCollection({ subscriptionPlan: planId, company: companyId, limit: PLANS_EMPLOYEES_PER_PAGE });
  }, [getEmployeesPlanCollection, planId, companyId]);

  useEffect(() => {
    return () => {
      clearEmployeesPlanCollection();
    };
  }, [clearEmployeesPlanCollection]);

  if (!employees?.data?.length) {
    return null;
  }

  const onChangePage = (limit: number, offset: number) => {
    getEmployeesPlanCollection({ subscriptionPlan: planId, company: companyId, limit, offset });
  };

  const listPagination = PaginationHelper.getListsPagination(
    onChangePage,
    PLANS_EMPLOYEES_PER_PAGE,
    employeesPlanCollection?.data?.meta.count
  );

  return (
    <>
      <Paragraph className="color_primary t_bold mb-25 mt-20" size="sm">
        {t('plansPage.form.archivePlansWithEmployeeHeader')}
      </Paragraph>

      <Paragraph className="mb-20 mt-10" size="xs">
        {t('plansPage.form.archivePlansWithEmployee')}
      </Paragraph>

      <List
        pagination={listPagination}
        dataSource={employees?.data}
        renderItem={({ id, firstName, lastName }) => (
          <div className="mb-15">
            <Link className="link" to={`${EPrivateRoutes.Employees}/${id}`} title={`${lastName || ''} ${firstName || ''}`}>
              {TableFormatHelper.formatFullName(firstName, { firstName, lastName })}
            </Link>
          </div>
        )}
      />
    </>
  );
};

export const PlansArchiveInfo = communicationCompanies.injector(
  communicationEmployees.injector(communicationSubscriptionPlans.injector(PlansArchiveInfoComponent))
);
