import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { CardInfo, Button } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import { Row, Col } from 'antd/es/grid';
import Spin from 'antd/es/spin';
import message from 'antd/es/message';
import { useTranslation } from 'react-i18next';
import i18n from 'app/locales/i18n';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

type AllProps = IAuthConnectedProps;

const GetConfirmComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const history = useHistory<{ email?: string }>();

  const [seconds, setSeconds] = useState<number>(timeout);
  const [isTimerActive, setIsTimerActive] = useState<boolean>(true);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isTimerActive) {
      interval = setInterval(() => {
        setSeconds(oldValue => oldValue - 1);
      }, 1000);
    }

    if (isTimerActive && seconds === 0) {
      setIsTimerActive(false);
      interval && clearInterval(interval);
    }

    return () => {
      interval && clearInterval(interval);
    };
  }, [isTimerActive, seconds, setIsTimerActive]);

  const resend = () => {
    const { repeatAuthConfirmation } = props;

    setIsTimerActive(true);
    setSeconds(timeout);

    const email = history.location.state?.email;

    if (!email) {
      message.error(i18n.t('errorDefault'));

      return;
    }

    repeatAuthConfirmation({ email });
  };

  return (
    <Spin spinning={false}>
      <Row gutter={22}>
        <Col {...wrapperCol}>
          <CardInfo
            header={
              <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center" level={2}>
                {t('requestConfirmationPage.header')}
              </Typography.Title>
            }
            content={
              <Typography.Text className="clp-typography-text clp-typography-text_size_sm mob-form-wrapper mt-15 mb-30">
                {t('requestConfirmationPage.description')}
              </Typography.Text>
            }
          />

          {isTimerActive && (
            <Typography.Title className="clp-typography-title clp-typography-title_size_xs mob-form-wrapper pt-5 pb-2" level={4}>
              {t('requestConfirmationPage.message', { count: seconds })}
            </Typography.Title>
          )}

          {!isTimerActive && (
            <Button type="primary" size="small" onClick={resend} className="ant-btn_mob-block" block>
              {t('requestConfirmationPage.btn')}
            </Button>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export const GetConfirm = communicationAuth.injector(GetConfirmComponent);

const wrapperCol = { xs: 24, xl: 12 };
const timeout = 60;
