import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  EActionsTypes,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import message from 'antd/es/message';
import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';
import { EPrivateRoutes } from 'common/const';
import { MessageHelper, RequestLoadingHelper } from 'common/helpers';
import i18n from 'app/locales/i18n';
import {
  IEmployeeRequests,
  IEmployeeRequestsParams,
  IEmployeesBalance,
  IEmployeesCollection,
  IEmployeesCollectionParams,
  IEmployeesModel,
  IEmployeesUpdateParams
} from 'entities/Employees/Employees.models';
import { EmployeesTransport } from 'entities/Employees/Employees.transport';

const namespace = 'employees';

export interface IEmployeesConnectedProps {
  employeesCurrent: StoreBranch<IEmployeesCollection>;
  getEmployeesCurrent(params: IEmployeesCollectionParams): void;
  clearEmployeesCurrent(): void;

  employeesBalance: StoreBranch<IEmployeesBalance>;
  getEmployeesBalance(id: string): void;
  clearEmployeesBalance(): void;

  employeesModel: StoreBranch<IEmployeesModel, IEmployeesUpdateParams>;
  getEmployeesModel(id: string): void;
  deleteEmployeesModel(id: string): void;
  updateEmployeesModel(params: IEmployeesUpdateParams): void;
  clearEmployeesModel(): void;

  employeesRequests: StoreBranch<IEmployeeRequests>;
  getEmployeesRequests(params: IEmployeeRequestsParams): void;
  clearEmployeesRequests(): void;

  employeesPlanCollection: StoreBranch<IEmployeesCollection>;
  getEmployeesPlanCollection(params: IEmployeesCollectionParams): void;
  clearEmployeesPlanCollection(): void;

  employeesForTransferCollection: StoreBranch<IEmployeesCollection>;
  getEmployeesForTransferCollection(params: IEmployeesCollectionParams): void;
  clearEmployeesForTransferCollection(): void;

  employeesCollection: StoreBranch<IEmployeesCollection>;
  getEmployeesCollection(params: IEmployeesCollectionParams): void;
  clearEmployeesCollection(): void;
}

const transport = new EmployeesTransport(namespace);

const currentApiProvider = [
  new APIProvider<IEmployeesCollection, IEmployeesCollectionParams>(EActionsTypes.get, transport.getCollection, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

const modelApiProvider = [
  new APIProvider(EActionsTypes.get, transport.get),
  new APIProvider(EActionsTypes.update, transport.update, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    onSuccess: MessageHelper.updateSuccess
  }),
  new APIProvider(EActionsTypes.delete, transport.delete, {
    onSuccess: function*() {
      message.success(i18n.t('successDeleteDefault'));
      yield put(push(EPrivateRoutes.Employees));
    }
  })
];

const requestsApiProvider = [
  new APIProvider(EActionsTypes.get, transport.getRequests, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

const collectionApiProvider = [
  new APIProvider(EActionsTypes.get, transport.getCollection, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

export function* reloadEmployeesCollection(params: IEmployeesCollectionParams) {
  yield put({ type: getStartType(namespace, 'collection', EActionsTypes.get), payload: params });
}

const balanceApiProvider = [new APIProvider(EActionsTypes.get, transport.getBalance)];

const branches = [
  new Branch('current', currentApiProvider),
  new Branch('balance', balanceApiProvider),
  new Branch('requests', requestsApiProvider),
  new Branch('planCollection', collectionApiProvider),
  new Branch('forTransferCollection', collectionApiProvider),
  new Branch('forAccountTransfer', collectionApiProvider),
  new Branch('collection', collectionApiProvider),
  new Branch('model', modelApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationEmployees = buildCommunication<IEmployeesConnectedProps>(strategy);
