import axios from 'axios';
import { BaseTransport } from 'common/dto/base.transport';
import { ICompanyInvitationCollection, ICompanyInvitationModel } from 'entities/CompanyInvitations/Invintations.models';
import {
  ICompanyEmployeesTransferCreateParams,
  ICompanyEmployeesTransferModel,
  ICompanyInvoiceModel,
  ICompanyInvoiceParams,
  ICompanyPaymentIntent,
  ICompanyPaymentIntentParams,
  ICompanyPaymentMethodCreateParams,
  ICompanyPaymentMethodDeleteParams
} from 'entities/CompanyPayment/CompanyPayment.models';

export class CompanyPaymentTransport extends BaseTransport<ICompanyInvitationModel, ICompanyInvitationCollection> {
  public generateInvoice = ({ id, ...rest }: ICompanyInvoiceParams): Promise<ICompanyInvoiceModel> => {
    return axios.post(`${this.path}/${id}/invoice`, rest, { responseType: 'blob' });
  };

  public payToCompany = ({ id, ...rest }: ICompanyPaymentIntentParams): Promise<ICompanyPaymentIntent> => {
    return axios.post(`${this.path}/${id}/payment-intent`, rest);
  };

  public getMethods = (id: string) => {
    return axios.get(`${this.path}/${id}/payment-methods`);
  };

  public deleteMethod = ({ companyId, paymentId }: ICompanyPaymentMethodDeleteParams) => {
    return axios.delete(`${this.path}/${companyId}/payment-methods/${paymentId}`);
  };

  public addMethod = ({ companyId, ...rest }: ICompanyPaymentMethodCreateParams) => {
    return axios.post(`${this.path}/${companyId}/payment-methods`, rest);
  };

  public transfer = ({ id, ...rest }: ICompanyEmployeesTransferCreateParams): Promise<ICompanyEmployeesTransferModel> => {
    return axios.post(`${this.path}/${id}/employee-transfer`, rest);
  };
}
