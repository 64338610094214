import React from 'react';
import { Container } from '@axmit/clp-library';
import { Row, Col } from 'antd/es/grid';
import imageKeyHolder from 'app/assets/images/julesCle.png';
import { ContactUsForm } from 'entities/Support/components/ContactUsForm';

export const ContactUs = () => {
  return (
    <Container>
      <Row type="flex" align="middle">
        <Col sm={10}>
          <ContactUsForm />
        </Col>

        <Col xs={24} sm={{ span: 12, offset: 2 }}>
          <div className="pb-30 pt-30">
            <img src={imageKeyHolder} alt="contact us" className="max-w_100" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
