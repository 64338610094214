import { AntdFormHelper } from '@axmit/antd-helpers';
import { Button, FormItem, Input } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import Spin from 'antd/es/spin';
import { FormComponentProps } from 'antd/es/form';
import React, { FormEvent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EPrivateRoutes, EPublicRoutes } from 'common/const/Routes.const';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { PasswordInput } from 'entities/Auth/components/PasswordInput';
import {
  communicationCompanyInvitation,
  ICompanyInvitationConnectedProps
} from 'entities/CompanyInvitations/Invitations.communication';

type AllProps = IAuthConnectedProps & FormComponentProps & ICompanyInvitationConnectedProps;

const LogInFormComponent = (props: AllProps) => {
  const { i18n } = useTranslation();
  const { form, authModel, addAuthModel, companyInvitationsCode, activateCompanyInvitationsEmployee } = props;
  const { getFieldDecorator } = form;

  const history = useHistory();

  const redirectToRestorePassword = useCallback(() => {
    history.push(EPublicRoutes.RestorePassword);
  }, [history]);

  const submit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      form.validateFields((err, values) => {
        if (err) {
          return;
        }

        addAuthModel({
          callbackAfterSuccess: () => {
            history.push(EPrivateRoutes.Main);

            const code = companyInvitationsCode?.data;
            code && activateCompanyInvitationsEmployee(code);
          },
          ...values
        });
      });
    },
    [form, history, addAuthModel, activateCompanyInvitationsEmployee, companyInvitationsCode]
  );

  return (
    <Spin spinning={authModel.loading}>
      <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-25">
        {i18n.t('logInFormHeader')}
      </Typography.Title>

      <Form labelCol={{ span: 24 }} className="mt-30" wrapperCol={wrapperCol} onSubmit={submit}>
        <FormItem label={i18n.t('form.labels.email')}>
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: () => i18n.t('errorEmailNotEmail')
              },
              {
                required: true,
                message: () => i18n.t('errorRequiredDefault')
              }
            ]
          })(<Input />)}
        </FormItem>

        <PasswordInput label={i18n.t('form.labels.password')} getFieldDecorator={getFieldDecorator} fieldName="password" />

        <Button type="link" className="p-0" onClick={redirectToRestorePassword}>
          {i18n.t('logInForgotLink')}
        </Button>

        <Row align="middle" gutter={16}>
          <Col {...wrapperCol} className="mob-form-wrapper">
            <Button type="primary" block className="ant-btn_mob-block mob-form-wrapper" htmlType="submit">
              {i18n.t('headerLogin')}
            </Button>
          </Col>

          <Col {...wrapperCol} className="mob-text_center">
            <Button
              type="link"
              onClick={() => {
                history.push(EPublicRoutes.SignUpCommon);
              }}
              className="pl-0 pr-0"
            >
              {i18n.t('logInSignUpLink')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

const wrapperCol = { xs: 24, lg: 12 };

export const LogInForm = communicationCompanyInvitation.injector(
  communicationAuth.injector(
    Form.create<AllProps>({
      mapPropsToFields(props: AllProps) {
        const { authModel } = props;

        return AntdFormHelper.mapValidationToFields(authModel?.params, authModel?.errors);
      }
    })(LogInFormComponent)
  )
);
