import { Button } from '@axmit/clp-library';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';
import { communicationCompanyPayment, ICompanyPaymentConnectedProps } from 'entities/CompanyPayment/CompanyPayment.communication';

interface IComponentProps {
  amount: number;
}

type AllProps = IComponentProps & ICompaniesConnectedProps & ICompanyPaymentConnectedProps;

const InvoiceGeneratorComponent = (props: AllProps) => {
  const { i18n } = useTranslation();
  const { addCompanyPaymentInvoice, companyPaymentInvoice, clearCompanyPaymentInvoice, companiesCurrent, amount } = props;
  const { loading } = companyPaymentInvoice;

  const canGenerate =
    companiesCurrent?.data?.data?.beneficiaryName &&
    companiesCurrent?.data?.data?.address &&
    companiesCurrent?.data?.data?.country;
  const companyId = companiesCurrent?.data?.data?.id;
  const hasAmount = !!amount && amount > 0 && amount < 999999;

  useEffect(() => {
    return () => {
      clearCompanyPaymentInvoice();
    };
  }, [clearCompanyPaymentInvoice]);

  const generate = () => {
    if (!amount || !companyId) {
      return;
    }

    addCompanyPaymentInvoice({ id: companyId, amount });
  };

  return (
    <Button
      className="ant-btn_mob-block mb-10"
      type="primary"
      size="small"
      onClick={generate}
      disabled={!canGenerate || loading || !hasAmount}
    >
      {i18n.t('accountPage.paymentForm.btnInvoice')}
    </Button>
  );
};

export const InvoiceGenerator = communicationCompanyPayment.injector(communicationCompanies.injector(InvoiceGeneratorComponent));
