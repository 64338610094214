import { AntdFormHelper } from '@axmit/antd-helpers';
import { Button, Container, FormItem, Input, TextArea } from '@axmit/clp-library';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import Typography from 'antd/es/typography';
import { Col, Row } from 'antd/es/grid';
import Form, { FormComponentProps } from 'antd/es/form';
import Divider from 'antd/es/divider';
import * as React from 'react';
import { FormEvent } from 'react';
import { useHistory } from 'react-router';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { EPrivateRoutes } from 'common/const';
import { DateHelper } from 'common/helpers';
import { Desktop, Spinner } from 'common/components';
import { DatePicker } from 'common/components/DatePicker';
import julesMapVuitton from 'app/assets/images/julesMapVuitton.png';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { communicationRequests, IRequestsConnectedProps } from 'entities/Requests/Requests.communication';
import { ContactForm } from 'entities/Requests/components/ContactForm';
import { EContactType } from 'entities/Requests/Request.consts';

type AllProps = FormComponentProps & IRequestsConnectedProps & IUsersConnectedProps & IAuthConnectedProps;

const RequestCreateFormComponent = ({
  form,
  addRequestsModel,
  usersCurrent,
  authAccount,
  authModel,
  addRequestsNonAuthUserMsg,
  requestsNonAuthUserMsg
}: AllProps) => {
  const { i18n } = useTranslation();

  const isLoggedIn = authModel.data?.id;

  const contactType = HooksHelper.useStateBuilder(EContactType.email);
  const contactDate = HooksHelper.useStateBuilder(DateHelper.getNextDay());
  const contactDetails = HooksHelper.useStateBuilder(usersCurrent.data?.email);

  const { getFieldDecorator } = form;
  const date = HooksHelper.useStateBuilder(DateHelper.getToday());
  const history = useHistory();

  const { loading } = requestsNonAuthUserMsg;

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const employeeId = authAccount.data?.id;
    form.validateFields((err, values) => {
      if (err || !date.value) {
        return;
      }

      const userId = usersCurrent.data?.id;
      const formattedDate = DateHelper.normalizeToday(date.value).toISOString();

      if (userId) {
        addRequestsModel({
          user: userId,
          deliveryDate: formattedDate,
          meta: values,
          employee: employeeId,
          contactType: contactType.value,
          contactDate: contactDate.value.toISOString(),
          contactDetail: contactDetails.value || ''
        });
      } else {
        const msg = {
          name: values.name,
          email: values.email,
          message: `${values.description} Date: ${formattedDate}`
        };

        addRequestsNonAuthUserMsg(msg);
      }
    });
  };

  const cancel = () => {
    history.push(EPrivateRoutes.Main);
  };

  return (
    <Container>
      <Spinner spinning={loading}>
        <Form onSubmit={submit}>
          <Row gutter={50} className="pt-30">
            <Col xs={24} md={12}>
              <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-30">
                {i18n.t('requestMake.header')} {authAccount.data && <span className="color_light">({i18n.t('employee')})</span>}
              </Typography.Title>

              {isLoggedIn ? (
                <FormItem
                  labelAlign="left"
                  labelCol={{ xs: 24 }}
                  wrapperCol={{ xs: 24, sm: 12 }}
                  label={i18n.t('requestMake.title')}
                >
                  {getFieldDecorator('title', {
                    rules: [
                      {
                        required: true,
                        message: () => i18n.t('errorRequiredDefault')
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              ) : (
                <Row gutter={30}>
                  <Col xs={24} sm={14} lg={isLoggedIn ? 13 : 10}>
                    <FormItem labelAlign="left" labelCol={{ xs: 24 }} wrapperCol={{ xs: 24 }} label={i18n.t('requestMake.name')}>
                      {getFieldDecorator('name', {
                        rules: [
                          {
                            required: true,
                            message: () => i18n.t('errorRequiredDefault')
                          }
                        ]
                      })(<Input />)}
                    </FormItem>
                  </Col>

                  <Col xs={24} sm={14} lg={10} className="non-auth-email-field-col">
                    <FormItem labelAlign="left" labelCol={{ xs: 24 }} wrapperCol={{ xs: 24 }} label={i18n.t('requestMake.email')}>
                      {getFieldDecorator('email', {
                        rules: [
                          {
                            type: 'email',
                            message: () => i18n.t('errorEmailNotEmail')
                          },
                          {
                            required: true,
                            message: () => i18n.t('errorRequiredDefault')
                          }
                        ]
                      })(<Input />)}
                    </FormItem>
                  </Col>
                </Row>
              )}

              <FormItem wrapperCol={{ xs: 24, sm: 20 }}>
                {getFieldDecorator('description')(
                  <TextArea placeholder={i18n.t('requestMake.describe')} autoSize={{ minRows: 4, maxRows: 8 }} maxLength={4096} />
                )}
              </FormItem>

              <FormItem label={i18n.t('requestMake.timing')}>
                <DatePicker
                  className="mt-10"
                  disabledDate={DateHelper.isYesterdayOrBefore}
                  minDate={DateHelper.getToday().toDate()}
                  value={date.value}
                  onChange={value => value && date.set(value)}
                />
              </FormItem>

              {isLoggedIn && (
                <ContactForm
                  contactType={contactType.value}
                  updateContactType={contactType.set}
                  updateDate={contactDate.set}
                  date={contactDate.value}
                  updateResource={contactDetails.set}
                />
              )}
            </Col>

            <Desktop>
              <Col md={12}>
                <img src={julesMapVuitton} alt="vuitton" className="w-100p" style={{ maxWidth: 300 }} />
              </Col>
            </Desktop>
          </Row>

          <Divider className="divider" />

          <Button size="small" className="ant-btn_mob-block mr-8 hidden_mob" onClick={cancel}>
            {i18n.t('defaultBtnCancel')}
          </Button>

          <Button type="primary" size="small" htmlType="submit" className="ant-btn_mob-block mb-25">
            {i18n.t('defaultBtnSubmitRequest')}
          </Button>

          <Button size="small" className="ant-btn_mob-block hidden_desk" onClick={cancel}>
            {i18n.t('defaultBtnCancel')}
          </Button>
        </Form>
      </Spinner>
    </Container>
  );
};

const containers = compose(
  Form.create<AllProps>({
    mapPropsToFields(props: AllProps) {
      const { requestsModel } = props;

      return AntdFormHelper.mapValidationToFields(requestsModel?.params, requestsModel?.errors);
    }
  }),
  communicationRequests.injector,
  communicationAuth.injector,
  communicationUsers.injector
);

export const RequestCreateForm = containers(RequestCreateFormComponent);
