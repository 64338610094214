import { Button, Container } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Desktop } from 'common/components';
import { EPrivateRoutes } from 'common/const';
import julesMapPlane from 'app/assets/images/julesMapPlane.png';

export const FinalizeRequest = () => {
  const { i18n } = useTranslation();
  const history = useHistory();

  const back = () => {
    history.push(EPrivateRoutes.Main);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container>
        <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-30">
          {i18n.t('requestFinalize.header')}
        </Typography.Title>

        <Typography.Text className="clp-typography-text clp-typography-text_size_sm mob-form-wrapper mob-text_center mb-50">
          {i18n.t('requestFinalize.description')}
        </Typography.Text>
        <br />

        <Button type="primary" size="small" htmlType="submit" className="ant-btn_mob-block" onClick={back}>
          {i18n.t('defaultBtnBackToAccount')}
        </Button>
      </Container>

      <Desktop>
        <div className="position-relative">
          <img src={julesMapPlane} className="les-plane les-plane_desk" alt="merci" />
          <div className="les-plane__ribbon">{i18n.t('requestFinalize.ribbonText')}</div>
        </div>
      </Desktop>
    </>
  );
};
