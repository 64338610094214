import axios from 'axios';
import { BaseTransport } from 'common/dto/base.transport';
import {
  IEmployeesBalance,
  IEmployeesCollection,
  IEmployeesCollectionParams,
  IEmployeesCreateParams,
  IEmployeesModel,
  IEmployeeRequests,
  IEmployeeRequestsParams
} from 'entities/Employees/Employees.models';

export class EmployeesTransport extends BaseTransport<
  IEmployeesModel,
  IEmployeesCollection,
  IEmployeesCreateParams,
  null,
  IEmployeesCollectionParams
> {
  public getBalance = (id: string): Promise<IEmployeesBalance> => {
    return axios.get(`${this.path}/${id}/balance`);
  };

  public getRequests = ({ id, ...rest }: IEmployeeRequestsParams): Promise<IEmployeeRequests> => {
    return axios.get(`${this.path}/${id}/requests`, { params: rest });
  };
}
