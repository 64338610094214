import { default as message, MessageType } from 'antd/lib/message';
import i18n from 'i18next';
import { EErrorStatus } from 'common/models/Request.models';
import { IMappedValidateData, IErrorData, IValidateDetailsItem } from 'common/models/Validate.models';

function mapError(
  status: number,
  statusText: string,
  data: string | IErrorData
): null | string | IMappedValidateData | MessageType {
  switch (status) {
    case EErrorStatus.Forbidden:
    case EErrorStatus.NotFound:
    case EErrorStatus.Conflict:
    case EErrorStatus.InternalServerError:
      return typeof data === 'object' ? message.error(data.message || i18n.t('errorDefault')) : data;
    case EErrorStatus.Validation:
      return typeof data === 'object' ? mapValidationData(status, statusText, data) : data;
    case EErrorStatus.BadRequest:
    case EErrorStatus.Unauthorized:
      return typeof data === 'object' ? { message: data.message, status: data.statusCode, errors: {} } : null;
    default:
      return getDefaultError();
  }
}

const getDefaultError = () => {
  return message.error(i18n.t('errorDefault'));
};

function mapValidationData(status: number, statusText: string, data: IErrorData): IMappedValidateData {
  const { details } = data;

  return {
    errors: details ? getErrorsFromDetails(details) : {},
    message: statusText,
    status
  };
}

const getErrorsFromDetails = (details: IValidateDetailsItem[]) => {
  return details.reduce((accumulator, current) => {
    const missingProperty = current.params?.missingProperty || '';
    const key = current.dataPath.replace('.body.', '') || missingProperty;

    if (!key) {
      console.error('UNPROCESSABLE validation ERROR', current);

      return accumulator;
    }

    return {
      ...accumulator,
      [key]: [current.message]
    };
  }, {});
};

const getValidateStatus = (validateMessage?: string | number) => {
  return validateMessage ? 'error' : '';
};

const amountIsValid = (minAmount?: number, amount?: number): boolean => {
  if (amount === undefined) {
    return false;
  }

  if (minAmount === undefined) {
    return true;
  }

  return amount >= minAmount;
};

const getAmountValidationMessage = (minAmount?: number, amount?: number, errorMsg: string = ''): string => {
  return amountIsValid(minAmount, amount) ? '' : errorMsg;
};

const validateMinMaxNumber = (
  value: number,
  callback: Function,
  maxTransKey: string,
  minTransKey: string,
  max?: number,
  min?: number
) => {
  if (max !== undefined && value > max) {
    return callback(i18n.t(maxTransKey, { max }));
  }

  if (min !== undefined && value < min) {
    return callback(i18n.t(minTransKey, { min }));
  }

  return callback();
};

const getMaxLengthRule = (max: number, labelKey: string) => {
  return { max, message: () => i18n.t('errorLabeledValueCannotLonger', { max, label: i18n.t(labelKey) }) };
};

export const ValidateHelper = {
  getMaxLengthRule,
  validateMinMaxNumber,
  getValidateStatus,
  getAmountValidationMessage,
  mapError
};
