import { FontProximaNovaSrc } from 'common/config';

export const stripeStyles = {
  base: {
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: '16px',
    color: '#0F0D18',
    lineHeight: '24px'
  }
};

export const stripeFonts = [
  {
    src: FontProximaNovaSrc,
    family: 'proxima-nova'
  }
];

export enum ECardFormType {
  New = 'new',
  Saved = 'saved'
}

export const cardFormTypes: { value: ECardFormType; label: string }[] = [
  {
    value: ECardFormType.Saved,
    label: 'cardsSelector.label'
  },
  {
    value: ECardFormType.New,
    label: 'cardsSelector.showNew'
  }
];

export enum EPaymentBEErrors {
  IncorrectCardNumber = 'incorrect_number'
}
