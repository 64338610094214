import { ColumnProps } from 'antd/lib/table';
import { ValidationRule } from 'antd/es/form';
import { FormatHelper, ValidateHelper } from 'common/helpers';
import { ISelectorOption } from 'common/models/Selector.models';
import { RequiredRules } from 'common/const';
import { ISubscriptionPlansCollectionParams, ISubscriptionPlansModel } from 'entities/SubscriptionPlans/SubscriptionPlans.models';
import { SubscriptionPlansHelper } from 'entities/SubscriptionPlans/helpers/SubscriptionPlans.helper';

enum ESubscriptionPlansColumns {
  name = 'title',
  amount = 'amount',
  period = 'period',
  status = 'isDeleted'
}

export const subscriptionPlansColumns: ColumnProps<ISubscriptionPlansModel>[] = [
  {
    dataIndex: ESubscriptionPlansColumns.name,
    title: `plansPage.table.name`,
    render: SubscriptionPlansHelper.formatTitleTableLink
  },
  { dataIndex: ESubscriptionPlansColumns.amount, title: `plansPage.table.amount`, render: FormatHelper.getCurrency },
  { dataIndex: ESubscriptionPlansColumns.period, title: `plansPage.table.period` },
  { dataIndex: ESubscriptionPlansColumns.status, title: `plansPage.table.status`, render: SubscriptionPlansHelper.getStatus }
];

export const SUBSCRIPTION_PLANS_PER_PAGE = 10;
export const PLANS_EMPLOYEES_PER_PAGE = 5;

export enum ESubscriptionPlansStatus {
  Active = 'Active',
  Archived = 'Archived'
}

export const subscriptionPlansOptions: Record<'statuses', ISelectorOption[]> = {
  statuses: [
    { value: ESubscriptionPlansStatus.Active, label: `plansPage.table.status${ESubscriptionPlansStatus.Active}` },
    { value: ESubscriptionPlansStatus.Archived, label: `plansPage.table.status${ESubscriptionPlansStatus.Archived}` }
  ]
};

export const PLANS_PARAMS_ACTIVE: Partial<ISubscriptionPlansCollectionParams> = {
  isDeleted: false
};

export const PLANS_VALIDATION_RULES: Record<'TITLE' | 'DESC' | 'PERIOD' | 'AMOUNT', ValidationRule[]> = {
  TITLE: [...RequiredRules, ValidateHelper.getMaxLengthRule(64, 'plansPage.form.labelForErrorInName')],
  DESC: [ValidateHelper.getMaxLengthRule(1024, 'plansPage.form.labelForErrorInDesc')],
  PERIOD: [
    ...RequiredRules,
    {
      validator: (rule: string, value: number, callback: Function) =>
        ValidateHelper.validateMinMaxNumber(
          value,
          callback,
          'plansPage.form.periodMoreError',
          'plansPage.form.periodLessError',
          12,
          1
        )
    }
  ],
  AMOUNT: [
    ...RequiredRules,
    {
      validator: (rule: string, value: number, callback: Function) =>
        ValidateHelper.validateMinMaxNumber(
          value,
          callback,
          'plansPage.form.amountMoreError',
          'plansPage.form.amountLessError',
          99999,
          40
        )
    }
  ]
};

export enum EBEErrorMessages {
  AmountFromLess1 = 'amountFrom must not be less than 1',
  AmountFromMore99999 = 'amountFrom must not be greater than 99999',
  AmountToMore99999 = 'amountTo must not be greater than 99999',
  AmountToLess1 = 'amountTo must not be less than 1',
  PeriodLess1 = 'period must not be less than 1',
  PeriodMore12 = 'period must not be greater than 12'
}
