import React from 'react';
import { Link } from 'react-router-dom';
import { Footer as ClpFooter } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import { AppLandingHelper } from '@axmit/router-app-landing/index';
import { ECommonRoutes, ELandingRoutes } from 'common/const';
import { SvgPinterest } from 'common/SVG/SvgPinterest';
import { SvgWhatsApp } from 'common/SVG/SvgWhatsApp';
import { PINTEREST_LINK, CLP_LANDING_LINK, INSTAGRAM_LINK, WHATSAPP_LINK } from 'common/config';
import { SvgInstagram } from 'common/SVG/SvgInstagram';
import portraitMobile from 'app/assets/images/portraitMob.png';
import portraitDesktop from 'app/assets/images/portraitDesk.png';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <ClpFooter
      footerImage={portraitDesktop}
      footerImageMobile={portraitMobile}
      socials={[
        <a key="pinterest" href={PINTEREST_LINK} target="_blank" rel="noopener noreferrer">
          <SvgPinterest />
        </a>,
        <a key="Instagram" href={INSTAGRAM_LINK} target="_blank" rel="noopener noreferrer">
          <SvgInstagram />
        </a>,
        <a key="Whatsapp" href={WHATSAPP_LINK} target="_blank" rel="noopener noreferrer">
          <SvgWhatsApp />
        </a>
      ]}
    >
      <nav className="clp-nav">
        <a href={CLP_LANDING_LINK}>Clé Privée</a>
        <Link to="/" onClick={() => AppLandingHelper.toggleRoute(ELandingRoutes.TOC)}>
          {t('ToS')}
        </Link>
        <Link to="/" onClick={() => AppLandingHelper.toggleRoute(ELandingRoutes.PrivacyPolicy)}>
          {t('policy')}
        </Link>
        <Link to={ECommonRoutes.ContactUs}> {t('contactUs')}</Link>
      </nav>
    </ClpFooter>
  );
};
