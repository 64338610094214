import { AntdFormHelper } from '@axmit/antd-helpers';
import { Button, FormItem, MonthPicker, Input } from '@axmit/clp-library';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import { FormComponentProps } from 'antd/es/form';
import moment from 'moment';
import React, { useEffect } from 'react';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { CardCvcValidationRules, CardNumberValidationRules } from 'common/const';
import { DateHelper } from 'common/helpers';
import { EmptyData, Spinner } from 'common/components';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { communicationPayment, IPaymentConnectedProps } from 'entities/Payment/Payment.communication';

type AllProps = IPaymentConnectedProps & IUsersConnectedProps & FormComponentProps;

const CardCreateFormComponent = ({ usersCurrent, form, paymentMethod, addPaymentMethod, clearPaymentMethod }: AllProps) => {
  const { i18n } = useTranslation();
  const { loading } = paymentMethod;
  const userId = usersCurrent?.data?.id;
  const { getFieldDecorator } = form;

  const expiry = HooksHelper.useStateBuilder<moment.Moment>(DateHelper.getNextYear());

  useEffect(() => {
    return () => {
      clearPaymentMethod();
    };
  }, [clearPaymentMethod]);

  if (!userId) {
    return <EmptyData />;
  }

  const submit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      addPaymentMethod({
        cvc: values.cvc,
        // eslint-disable-next-line @typescript-eslint/camelcase
        exp_month: Number(expiry.value.format('MM')),
        // eslint-disable-next-line @typescript-eslint/camelcase
        exp_year: Number(expiry.value.format('YY')),
        number: values.number,
        userId
      });
    });
  };

  return (
    <Spinner spinning={loading}>
      <Form>
        <Row gutter={30}>
          <Col {...wrapperCol} lg={12}>
            <FormItem label={i18n.t('cardCreate.number.label')}>
              {getFieldDecorator('number', {
                rules: CardNumberValidationRules
              })(<Input />)}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={30}>
          <Col {...wrapperCol}>
            <FormItem label={i18n.t('cardCreate.expiry.label')}>
              <MonthPicker
                allowClear={false}
                format="MM/YY"
                defaultValue={expiry.value}
                disabledDate={DateHelper.isTodayOrBefore}
                onChange={date => date && expiry.set(date)}
              />
            </FormItem>
          </Col>

          <Col {...wrapperCol}>
            <FormItem label={i18n.t('cardCreate.cvc.label')}>
              {getFieldDecorator('cvc', {
                rules: CardCvcValidationRules
              })(<Input />)}
            </FormItem>
          </Col>

          <Col xs={24}>
            <Button type="primary" onClick={submit} className="ant-btn_mob-block" htmlType="submit" disabled={loading}>
              {i18n.t('defaultBtnSave')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Spinner>
  );
};

const wrapperCol = { xs: 24, md: 12, lg: 6 };

const containers = compose(communicationPayment.injector, communicationUsers.injector);

export const CardCreateForm = containers(
  Form.create<AllProps>({
    mapPropsToFields({ paymentMethod }) {
      return AntdFormHelper.mapValidationToFields(paymentMethod.params, paymentMethod.errors);
    }
  })(CardCreateFormComponent)
);
