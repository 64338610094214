import { Container, HorizontalMenu, SvgArrowLang } from '@axmit/clp-library';
import { AppLandingHelper } from '@axmit/router-app-landing';
import Layout from 'antd/es/layout';
import { Col, Row } from 'antd/es/grid';
import SubMenu from 'antd/es/menu/SubMenu';
import Menu from 'antd/lib/menu';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { UsersHooks } from 'common/hooks/Users.hooks';
import { ECommonRoutes, ELandingRoutes, EPrivateRoutes, EPublicRoutes } from 'common/const/Routes.const';
import { TabsHooks } from 'common/hooks';
import { SvgLogo } from 'common/SVG/SvgLogo';
import julesMobile from 'app/assets/images/julesMobile.png';
import { languages } from 'app/locales/i18n';
import { communicationEmployees, IEmployeesConnectedProps } from 'entities/Employees/Employees.communication';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';

type AllProps = IUsersConnectedProps & IAuthConnectedProps & IEmployeesConnectedProps;

const HeaderComponent = (props: AllProps) => {
  const history = useHistory();
  const { i18n, t } = useTranslation();

  const { usersCurrent, employeesCurrent, updateAuthAccount, authAccount, clearAuthAccount } = props;
  const name = usersCurrent?.data?.firstName || '';
  const isAuthorized = !!usersCurrent?.data;
  const { loading: usersLoading } = usersCurrent;
  const { isCompanyAccount } = UsersHooks.useCompany();
  const isCompany = isAuthorized && isCompanyAccount;
  const isPersonal = isAuthorized && !isCompanyAccount;
  const hasEmployees = !!employeesCurrent.data?.data.length;
  const showEmployeesAccount = isAuthorized && !isCompany && hasEmployees;
  const showName = isAuthorized && (isCompany || !hasEmployees);

  const defaultRoute = authAccount.data?.id || 'defaultAccount';

  const { activeTabs } = TabsHooks.useSelectedTabs(availableRoutes, showName ? 'deskDefaultAccount' : defaultRoute);

  useEffect(() => {
    return () => {
      clearAuthAccount();
    };
  }, [clearAuthAccount]);

  return (
    <Layout.Header className="clp-header">
      <Container>
        <Row>
          <Col xl={9} lg={11} md={15} xs={21}>
            <a href={AppLandingHelper.getLink(ELandingRoutes.Main)} className="logo-link">
              <SvgLogo />
            </a>
          </Col>

          <Col xl={15} lg={13} md={9} xs={3}>
            <HorizontalMenu
              selectedKeys={activeTabs}
              mobileImg={julesMobile}
              currentLanguage={i18n.language}
              languages={languages}
              updateLanguage={language => i18n.changeLanguage(language)}
            >
              {isCompany && (
                <Menu.Item
                  key={EPrivateRoutes.Plans}
                  onClick={() => history.push(EPrivateRoutes.Plans)}
                  className="hidden_desk mt-20"
                >
                  {t('headerCompanyMob.plans')}
                </Menu.Item>
              )}

              {isCompany && (
                <Menu.Item
                  key={EPrivateRoutes.Employees}
                  onClick={() => history.push(EPrivateRoutes.Employees)}
                  className="hidden_desk"
                >
                  {t('headerCompanyMob.employees')}
                </Menu.Item>
              )}

              {isCompany && (
                <Menu.Item
                  key={EPrivateRoutes.Account}
                  onClick={() => history.push(EPrivateRoutes.Account)}
                  className="hidden_desk"
                >
                  {t('headerCompanyMob.account')}
                </Menu.Item>
              )}

              {isPersonal && (
                <Menu.Item
                  key={EPrivateRoutes.RequestViewPage}
                  onClick={() => history.push(EPrivateRoutes.RequestViewPage)}
                  className="hidden_desk mt-20"
                >
                  {t('requests')}
                </Menu.Item>
              )}

              {isPersonal && (
                <Menu.Item
                  key={EPrivateRoutes.Deposit}
                  onClick={() => history.push(EPrivateRoutes.Deposit)}
                  className="hidden_desk"
                >
                  {t('deposit')}
                </Menu.Item>
              )}

              {isPersonal && (
                <Menu.Item
                  key={EPrivateRoutes.PaymentInfoMobile}
                  onClick={() => history.push(EPrivateRoutes.PaymentInfoMobile)}
                  className="hidden_desk"
                >
                  {t('paymentInformation')}
                </Menu.Item>
              )}

              {isPersonal && (
                <Menu.Item
                  key={EPrivateRoutes.PersonalInfoMobile}
                  onClick={() => history.push(EPrivateRoutes.PersonalInfoMobile)}
                  className="hidden_desk mb-20"
                >
                  {t('personalInfo')}
                </Menu.Item>
              )}

              <Menu.Item
                key="philosophy"
                className={isAuthorized ? 'hidden_mob' : ''}
                onClick={() => AppLandingHelper.toggleRoute(ELandingRoutes.Philosophy)}
              >
                {t('philosophy')}
              </Menu.Item>

              {!isAuthorized && (
                <Menu.Item
                  onClick={() => AppLandingHelper.toggleRoute(ELandingRoutes.How)}
                  key={ELandingRoutes.How}
                  className="hidden_desk"
                >
                  {i18n.t('howItWork')}
                </Menu.Item>
              )}

              {!isAuthorized && (
                <Menu.Item
                  onClick={() => AppLandingHelper.toggleRoute(ELandingRoutes.SignInfoCorporate)}
                  key={ELandingRoutes.SignInfoCorporate}
                  className="hidden_desk"
                >
                  {i18n.t('HIWSubMenuCorporate')}
                </Menu.Item>
              )}

              {!isAuthorized && (
                <Menu.Item
                  onClick={() => AppLandingHelper.toggleRoute(ELandingRoutes.SignInfo)}
                  key={ELandingRoutes.SignInfo}
                  className="hidden_desk"
                >
                  {i18n.t('HIWSubMenuPersonal')}
                </Menu.Item>
              )}

              <Menu.SubMenu
                popupClassName="clp-antd-submenu clp-antd-submenu_xs"
                className="hidden_mob"
                onTitleClick={() => AppLandingHelper.toggleRoute(ELandingRoutes.How)}
                title={i18n.t('howItWork')}
              >
                <Menu.Item
                  onClick={() => AppLandingHelper.toggleRoute(ELandingRoutes.SignInfoCorporate)}
                  key={ELandingRoutes.SignInfoCorporate}
                >
                  {i18n.t('HIWSubMenuCorporate')}
                </Menu.Item>

                <Menu.Item onClick={() => AppLandingHelper.toggleRoute(ELandingRoutes.SignInfo)} key={ELandingRoutes.SignInfo}>
                  {i18n.t('HIWSubMenuPersonal')}
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.Item
                key="faq"
                className={isAuthorized ? 'hidden_mob' : ''}
                onClick={() => AppLandingHelper.toggleRoute(ELandingRoutes.FAQ)}
              >
                {t('FAQ')}
              </Menu.Item>

              {!isAuthorized && !usersLoading && (
                <Menu.Item key={EPublicRoutes.SignUpCommon} onClick={() => history.push(EPublicRoutes.SignUpCommon)}>
                  {t('createAccount')}
                </Menu.Item>
              )}

              {!isAuthorized && (
                <Menu.Item key={EPublicRoutes.LogIn} onClick={() => history.push(EPublicRoutes.LogIn)} className="hidden_mob">
                  {t('login')}
                </Menu.Item>
              )}

              {showEmployeesAccount && (
                <SubMenu
                  key="name"
                  title={
                    <>
                      {name} <SvgArrowLang />
                    </>
                  }
                  popupClassName="clp-antd-submenu"
                  className="hidden_mob"
                >
                  <Menu.Item
                    onClick={() => {
                      clearAuthAccount();
                      history.push(EPrivateRoutes.Main);
                    }}
                    key="default_account"
                  >
                    {t('personal')}
                  </Menu.Item>
                  {employeesCurrent.data?.data.map(item => {
                    return (
                      <Menu.Item
                        onClick={() => {
                          updateAuthAccount(item);
                        }}
                        key={item.id}
                      >
                        {t('employee')} ({item.companyName})
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              )}

              {showName && (
                <Menu.Item
                  onClick={() => {
                    history.push(EPrivateRoutes.Main);
                  }}
                  key="deskDefaultAccount"
                  className="hidden_mob"
                >
                  {name}
                </Menu.Item>
              )}

              {isAuthorized && (
                <Menu.Item
                  onClick={() => {
                    clearAuthAccount();
                    history.push(EPrivateRoutes.Main);
                  }}
                  key="defaultAccount"
                  className="hidden_desk"
                >
                  {name}
                </Menu.Item>
              )}

              {showEmployeesAccount &&
                employeesCurrent.data?.data.map(item => {
                  return (
                    <Menu.Item
                      onClick={() => {
                        updateAuthAccount(item);
                      }}
                      key={item.id}
                      className="hidden_desk pl-200"
                    >
                      {t('employee')}
                      <small> ({item.companyName})</small>
                    </Menu.Item>
                  );
                })}

              {!isAuthorized && (
                <Menu.Item key="mobLogin" onClick={() => history.push(EPublicRoutes.LogIn)} className="hidden_desk">
                  {t('login')}
                </Menu.Item>
              )}

              {isAuthorized && (
                <Menu.Item key="mobLogout" onClick={() => history.push(ECommonRoutes.Logout)} className="hidden_desk">
                  {t('signOut')}
                </Menu.Item>
              )}
            </HorizontalMenu>
          </Col>
        </Row>
      </Container>
    </Layout.Header>
  );
};

export const Header = communicationAuth.injector(communicationUsers.injector(communicationEmployees.injector(HeaderComponent)));

const availableRoutes: string[] = [
  EPublicRoutes.SignUpCommon,
  EPublicRoutes.LogIn,
  EPrivateRoutes.Employees,
  EPrivateRoutes.Deposit,
  EPrivateRoutes.Account,
  EPrivateRoutes.Plans,
  EPrivateRoutes.RequestViewPage,
  EPrivateRoutes.PaymentInfoMobile,
  EPrivateRoutes.PersonalInfoMobile,
  EPrivateRoutes.Transfer
];
