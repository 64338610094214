import { Container } from '@axmit/clp-library';
import React from 'react';
import { Desktop, Mobile } from 'common/components';
import { DashboardCompanyDesktop, DashboardCompanyMobile } from 'app/pages';

const DashboardCompanyComponent = () => {
  return (
    <Container>
      <Desktop>
        <DashboardCompanyDesktop />
      </Desktop>

      <Mobile>
        <DashboardCompanyMobile />
      </Mobile>
    </Container>
  );
};

export const DashboardCompany = DashboardCompanyComponent;
