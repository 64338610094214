import Badge from 'antd/es/badge';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DateHelper } from 'common/helpers';
import { RequestHelper } from 'entities/Requests/helpers/Request.helper';
import { IRequestModel } from 'entities/Requests/Requests.models';

interface IComponentProps {
  request: IRequestModel;
}

export const RequestsListItem = ({ request }: IComponentProps) => {
  const { i18n } = useTranslation();

  const {
    meta: { title },
    deliveryDate,
    createdAt,
    status
  } = request;

  const statusIcon = RequestHelper.getStatusIcon(status);
  const statusText = RequestHelper.getStatusDescription(status);

  return (
    <div className="les-card">
      {title && <div className="t_bold color_default">{title}</div>}

      {status && <Badge status={statusIcon} text={statusText} />}

      <div className="ta-r">
        <span className="t_bold color_default float_l">{i18n.t('dueDate')}: </span>
        <span className="color_primary">{DateHelper.getDateWithYear(deliveryDate)}</span>
      </div>

      <div className="ta-r">
        <span className="t_bold color_default float_l">{i18n.t('created')}: </span>
        <span className="color_primary">{DateHelper.getDateWithYear(createdAt)} </span>
        <span className="color_default">{DateHelper.getTime(createdAt)}</span>
      </div>
    </div>
  );
};
