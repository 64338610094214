import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormItem, Input, TextArea } from '@axmit/clp-library';
import Form, { FormComponentProps } from 'antd/es/form';
import { Col, Row } from 'antd/es/grid';
import { AntdFormHelper } from '@axmit/antd-helpers';
import { Desktop, Mobile, Spinner } from 'common/components';
import { Breadcrumb } from 'common/components/Breadcrumb';
import { EPrivateRoutes } from 'common/const';
import { FormLabel } from 'common/components/FormLabel';
import { BtnBack } from 'common/components/BtnBack';
import { FormatHelper } from 'common/helpers';
import {
  communicationSubscriptionPlans,
  ISubscriptionPlansConnectedProps
} from 'entities/SubscriptionPlans/SubscriptionPlans.communication';
import { PlansArchiveBtn } from 'entities/SubscriptionPlans/components/PlansArchiveBtn';
import { PlansArchiveInfo } from 'entities/SubscriptionPlans/components/PlansArchiveInfo';
import { PLANS_VALIDATION_RULES } from 'entities/SubscriptionPlans/SubscriptionPlans.consts';

type AllProps = FormComponentProps & ISubscriptionPlansConnectedProps;

const PlansUpdateFormComponent = (props: AllProps) => {
  const { form, subscriptionPlansModel } = props;
  const { loading, data: plan } = subscriptionPlansModel;
  const { getFieldDecorator } = form;

  const { t } = useTranslation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      const { updateSubscriptionPlansModel } = props;
      const planId = plan?.id;

      if (err || !planId) {
        return;
      }

      updateSubscriptionPlansModel({ ...values, id: planId });
    });
  };

  const { title, description, amount, period } = plan || {};

  return (
    <Spinner spinning={loading}>
      <Form onSubmit={submit}>
        <Row className="mt-10">
          <Col xs={24} xl={13} md={14}>
            <Desktop>
              <Breadcrumb previous={t('plansPage.breadcrumbMain')} previousHref={EPrivateRoutes.Plans} current={title} />
            </Desktop>

            <Mobile>
              <BtnBack />
            </Mobile>

            <FormItem label={t('plansPage.form.name')}>
              {getFieldDecorator('title', {
                initialValue: title,
                validateFirst: true,
                rules: PLANS_VALIDATION_RULES.TITLE
              })(<Input />)}
            </FormItem>

            <FormItem label={t('plansPage.form.description')}>
              {getFieldDecorator('description', {
                rules: PLANS_VALIDATION_RULES.DESC,
                initialValue: description
              })(<TextArea placeholder={t('plansPage.form.placeholder')} autoSize={{ minRows: 4, maxRows: 8 }} />)}
            </FormItem>

            <FormLabel field="period" label={t('plansPage.form.period')} subLabel={t('plansPage.form.periodDescription')} />

            <FormItem>
              {getFieldDecorator('period', {
                initialValue: period,
                validateFirst: true,
                normalize: FormatHelper.normalizeNumber,
                rules: PLANS_VALIDATION_RULES.PERIOD
              })(<Input type="number" step={1} />)}
            </FormItem>

            <FormLabel field="amount" label={t('plansPage.form.amount')} subLabel={t('plansPage.form.amountDescription')} />

            <FormItem extra={<div className="form-sub-label">{t('plansPage.form.amountSubInfo')}</div>}>
              {getFieldDecorator('amount', {
                initialValue: amount,
                validateFirst: true,
                normalize: FormatHelper.normalizeCurrency,
                rules: PLANS_VALIDATION_RULES.AMOUNT
              })(<Input type="number" addonBefore="€" />)}
            </FormItem>
          </Col>
        </Row>

        <Row type="flex" justify="space-between" className="mt-10" gutter={4}>
          <Col xs={24} xl={13} md={14}>
            <Row type="flex" justify="space-between" gutter={4} className="m-0">
              <Button type="primary" htmlType="submit" className="ant-btn_mob-block mb-25">
                {t('plansPage.form.btnUpdatePlan')}
              </Button>

              <PlansArchiveBtn />
            </Row>

            <PlansArchiveInfo />
          </Col>
        </Row>
      </Form>
    </Spinner>
  );
};

export const PlansUpdateForm = communicationSubscriptionPlans.injector(
  Form.create<AllProps>({
    mapPropsToFields(props: AllProps) {
      const { subscriptionPlansModel } = props;

      return AntdFormHelper.mapValidationToFields(subscriptionPlansModel?.params, subscriptionPlansModel?.errors);
    }
  })(PlansUpdateFormComponent)
);
