import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  StoreBranch,
  getStartType
} from '@axmit/redux-communications';
import message from 'antd/es/message';
import { put } from 'redux-saga/effects';
import { getCreds } from '@axmit/axios-patch-jwt';
import { RequestLoadingHelper } from 'common/helpers';
import { EErrorStatus } from 'common/models/Request.models';
import i18n from 'app/locales/i18n';
import { CompanyInvitationsTransport } from 'entities/CompanyInvitations/CompanyInvitations.transport';
import {
  ICompanyInvitationCollection,
  ICompanyInvitationCollectionParams,
  ICompanyInvitationCreateParams,
  ICompanyInvitationModel
} from 'entities/CompanyInvitations/Invintations.models';
import { getEmployeeAccounts } from 'entities/Auth/Auth.communication';
import { EBEInviteErrorMessages } from 'entities/CompanyInvitations/CompanyInvitations.consts';

const namespace = 'companyInvitations';

export interface ICompanyInvitationConnectedProps {
  deleteCompanyInvitationsModel(id: string): void;

  companyInvitationsCreateModel: StoreBranch<ICompanyInvitationModel>;
  addCompanyInvitationsCreateModel(params: ICompanyInvitationCreateParams): void;

  companyInvitationsReInvite: StoreBranch<{}>;
  initCompanyInvitationsReInvite(id: string): void;

  companyInvitationsCollection: StoreBranch<ICompanyInvitationCollection>;
  getCompanyInvitationsCollection(params: ICompanyInvitationCollectionParams): void;

  activateCompanyInvitationsEmployee(id: string): void;

  companyInvitationsCode: StoreBranch<string>;
  initCompanyInvitationsCode(code: string): void;
  clearCompanyInvitationsCode(): void;
}

const transport = new CompanyInvitationsTransport('company-invitations');

const modelApiProvider = [
  new APIProvider<ICompanyInvitationModel, ICompanyInvitationCreateParams>(EActionsTypes.add, transport.add, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    clearParams: true,
    mapFail: response => {
      if (response?.status === EErrorStatus.Validation) {
        const errors = response?.errors;
        const isAlreadySetUpError = errors?.email && errors?.email[0] === EBEInviteErrorMessages.AlreadySetUp;

        if (isAlreadySetUpError) {
          errors.email[0] = i18n.t('employeesPage.inviteForm.emailUsedError');
        }

        const isInPending = errors?.email && errors?.email[0] === EBEInviteErrorMessages.InPending;

        if (isInPending) {
          errors.email[0] = i18n.t('employeesPage.inviteForm.emailInPending');
        }

        const isInviteExistsError = errors?.email && errors?.email[0] === EBEInviteErrorMessages.InviteExists;

        if (isInviteExistsError) {
          errors.email[0] = i18n.t('employeesPage.inviteForm.emailSetUp');
        }
      }

      return response;
    },
    onSuccess: function*(response, originalParams, branchState, fullState) {
      message.success(i18n.t('successSendDefault'));

      // @ts-ignore
      const { params = {} } = fullState?.companyInvitations?.collection;

      yield params && reloadCollection(params);
    }
  }),
  new APIProvider(EActionsTypes.delete, transport.delete, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    onSuccess: function*(response, originalParams, branchState, fullState) {
      // @ts-ignore
      const { params = {} } = fullState?.companyInvitations?.collection;

      yield params && reloadCollection(params);
    }
  })
];

function* reloadCollection(params: ICompanyInvitationCreateParams) {
  yield put({ type: getStartType(namespace, 'collection', EActionsTypes.get), payload: params });
}

const codeApiProvider = [new APIProvider(EActionsTypes.init, data => data)];

const reInviteApiProvider = [new APIProvider(EActionsTypes.init, transport.reInvite)];

export function* clearInviteCode() {
  yield put({ type: getStartType(namespace, 'code', EActionsTypes.clear) });
}

const collectionApiProvider = [
  new APIProvider(EActionsTypes.get, transport.getCollection, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

const employeeApiProvider = [
  new APIProvider('activate', transport.activate, {
    onSuccess: function*() {
      message.success(i18n.t('successActivated'));

      const AuthModelData = yield getCreds();
      const userId = AuthModelData?.id?.userId;

      yield userId && getEmployeeAccounts(userId);

      yield clearInviteCode();
    },
    onFail: function*(response) {
      if (response.status === EErrorStatus.BadRequest) {
        message.error(i18n.t('errorExpiredCode'));
      }
      yield clearInviteCode();
    }
  })
];

export function* activateCompanyEmployee(code: string) {
  yield put({ type: getStartType(namespace, 'employee', 'activate'), payload: code });
}

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('createModel', modelApiProvider),
  new Branch('collection', collectionApiProvider),
  new Branch('code', codeApiProvider),
  new Branch('reInvite', reInviteApiProvider),
  new Branch('employee', employeeApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationCompanyInvitation = buildCommunication<ICompanyInvitationConnectedProps>(strategy);
