import { FormItem, PhoneInput } from '@axmit/clp-library';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import Radio from 'antd/es/radio';
import Typography from 'antd/es/typography';
import moment from 'moment';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { DateHelper, ValidateHelper } from 'common/helpers';
import { PhoneHooks } from 'common/hooks';
import { DateTimeSplitPicker } from 'common/components/DateTimeSlitPicker';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { contactTypes, EContactDateType, EContactType } from 'entities/Requests/Request.consts';

interface IComponentProps {
  contactType: EContactType;
  updateContactType: Dispatch<SetStateAction<EContactType>>;
  updateDate: Dispatch<SetStateAction<moment.Moment>>;
  updateResource: Dispatch<SetStateAction<string | undefined>>;
  date: moment.Moment;
}

type AllProps = IUsersConnectedProps & IComponentProps;

const ContactFormComponent = ({ usersCurrent, contactType, updateContactType, updateDate, updateResource, date }: AllProps) => {
  const { t } = useTranslation();
  const dateType = HooksHelper.useStateBuilder(EContactDateType.now);

  const dateValidateMessage = HooksHelper.useStateBuilder('');
  const dateValidateStatus: 'error' | '' = ValidateHelper.getValidateStatus(dateValidateMessage.value);

  const { phone, phoneValidateMessage, validatePhone, changePhone, phoneValidateStatus } = PhoneHooks.usePhone(
    usersCurrent.data?.phoneNumber || undefined
  );

  const isVisiblePhone = contactType === EContactType.phone || contactType === EContactType.whatsApp;

  return (
    <>
      <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-25 mt-50" level={3}>
        {t('requestMakeDate.header')}
      </Typography.Title>

      <div className="description mb-20">{t('requestMakeDate.dateType.header')}</div>

      <Radio.Group onChange={e => dateType.set(e.target.value)} value={dateType.value} className="radio-group">
        <Radio value={EContactDateType.now}>{t('requestMakeDate.dateType.soon.header')}</Radio>

        <Radio value="other">
          {t('requestMakeDate.dateType.other.header')}

          <br />

          <FormItem validateStatus={dateValidateStatus} help={dateValidateMessage.value} className="ml-25">
            <DateTimeSplitPicker
              date={date}
              dateClassName="mt-10 mr-15"
              timeClassName="mt-10"
              disabled={dateType.value !== 'other'}
              disabledDate={DateHelper.isTodayOrBefore}
              minMobDate={DateHelper.getTomorrow().toDate()}
              onChange={value => value && updateDate(value)}
            />
          </FormItem>
        </Radio>
      </Radio.Group>

      <div className="description mb-20">{t('requestMakeDate.serviceType.header')}</div>

      <Radio.Group
        onChange={e => {
          const value = e.target.value;

          updateContactType(value);
          updateResource(
            value === EContactType.email ? usersCurrent.data?.email : phone || usersCurrent.data?.phoneNumber || undefined
          );
        }}
        value={contactType}
        className="radio-group"
      >
        {contactTypes.map(({ value, label }) => (
          <Radio key={value} value={value}>
            {label}
          </Radio>
        ))}
      </Radio.Group>

      {isVisiblePhone && (
        <FormItem
          label={t('requestMakeDate.serviceType.phone.label')}
          className="mob-form-wrapper"
          validateStatus={phoneValidateStatus}
          help={phoneValidateMessage}
        >
          <PhoneInput validateTrigger={validatePhone} onChange={changePhone} defaultValue={phone} />
        </FormItem>
      )}
    </>
  );
};

export const ContactForm = communicationUsers.injector(ContactFormComponent);
