import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FormItem, Input, Paragraph } from '@axmit/clp-library';
import Form, { FormComponentProps } from 'antd/es/form';
import { Col, Row } from 'antd/es/grid';
import { Link } from 'react-router-dom';
import { AntdFormHelper } from '@axmit/antd-helpers';
import { MobFormButton } from 'common/components';
import { EPrivateRoutes, RequiredRules } from 'common/const';
import { FormatHelper, ValidateHelper } from 'common/helpers';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';
import { communicationCompanyPayment, ICompanyPaymentConnectedProps } from 'entities/CompanyPayment/CompanyPayment.communication';
import { EmployeeCreditSelector } from 'entities/CompanyPayment/components/EmployeeCreditSelector';

type AllProps = FormComponentProps & ICompanyPaymentConnectedProps & ICompaniesConnectedProps;

const EmployeeCreditComponent = (props: AllProps) => {
  const { form, companiesCurrent, companyPaymentTransferModel } = props;
  const { getFieldDecorator } = form;
  const { t } = useTranslation();
  const [employee, setEmployee] = useState<string>();
  const [employeeError, setEmployeeError] = useState<string>();
  const companyId = companiesCurrent?.data?.data?.id;
  const { loading } = companyPaymentTransferModel;

  const submit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (!employee) {
        setEmployeeError(t('errorRequiredDefault'));

        return;
      }

      const { addCompanyPaymentTransferModel } = props;

      companyId && addCompanyPaymentTransferModel({ id: companyId, ...values, employee });
    });
  };

  return (
    <>
      <Paragraph className="color_primary t_bold mb-25" size="sm">
        {t('employeesPage.creditForm.header')}
      </Paragraph>

      <Paragraph className="mb-20" size="xs">
        <Trans i18nKey="employeesPage.creditForm.description">
          Offer a one-time credit for your colleagues. This amount will be automatically withdrawn from your account balance. If
          needed, you can top up this balance at any time under your{' '}
          <Link className="link link_bold" to={EPrivateRoutes.Account}>
            Account
          </Link>
          .
        </Trans>
      </Paragraph>

      <Form className="mb-15">
        <Row gutter={30}>
          <Col xs={24} sm={12} lg={8} xl={7}>
            <FormItem label={t('employeesPage.creditForm.amount')} className="mob-form-wrapper">
              {getFieldDecorator('amount', {
                validateFirst: true,
                normalize: FormatHelper.normalizeNumber,
                rules: [
                  ...RequiredRules,
                  {
                    validator: (rule: string, value: number, callback: Function) =>
                      ValidateHelper.validateMinMaxNumber(
                        value,
                        callback,
                        'employeesPage.creditForm.amountMoreError',
                        'employeesPage.creditForm.amountLessError',
                        99999,
                        1
                      )
                  }
                ]
              })(<Input type="number" step={1} addonBefore="€" />)}
            </FormItem>
          </Col>

          <Col xs={24} sm={12} lg={8} xl={9}>
            <FormItem
              label={t('employeesPage.creditForm.employee')}
              className="mob-form-wrapper"
              help={employeeError}
              validateStatus={ValidateHelper.getValidateStatus(employeeError)}
            >
              <EmployeeCreditSelector
                setEmployee={value => {
                  setEmployee(value);
                  setEmployeeError(undefined);
                }}
                employee={employee}
              />
            </FormItem>
          </Col>

          <Col xs={24} lg={8}>
            <MobFormButton onClick={submit} type="primary" disabled={loading} size="small" className="mt-20">
              {t('employeesPage.creditForm.btnSubmit')}
            </MobFormButton>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export const EmployeeCredit = communicationCompanies.injector(
  communicationCompanyPayment.injector(
    Form.create<AllProps>({
      mapPropsToFields(props: AllProps) {
        const { companyPaymentTransferModel } = props;

        return AntdFormHelper.mapValidationToFields(companyPaymentTransferModel?.params, companyPaymentTransferModel?.errors);
      }
    })(EmployeeCreditComponent)
  )
);
