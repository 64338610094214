import { FormItem, Input, Paragraph } from '@axmit/clp-library';
import { Col, Row } from 'antd/es/grid';
import Form, { FormComponentProps } from 'antd/es/form';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import AntSelect from 'antd/es/select';
import Spin from 'antd/es/spin';
import debounce from 'lodash.debounce';
import { AntdFormHelper } from '@axmit/antd-helpers';
import { MobFormButton, Spinner } from 'common/components';
import { EmailBaseRules, RequiredRules } from 'common/const';
import { DateHelper, ValidateHelper } from 'common/helpers';
import { DatePicker } from 'common/components/DatePicker';
import { BE_DATE_FORMAT_DEFAULT, SEARCH_WAIT_TIME } from 'common/config';
import {
  communicationCompanyInvitation,
  ICompanyInvitationConnectedProps
} from 'entities/CompanyInvitations/Invitations.communication';
import {
  communicationSubscriptionPlans,
  ISubscriptionPlansConnectedProps
} from 'entities/SubscriptionPlans/SubscriptionPlans.communication';
import { PLANS_PARAMS_ACTIVE } from 'entities/SubscriptionPlans/SubscriptionPlans.consts';
import { ICompanyInvitationCreateParams } from 'entities/CompanyInvitations/Invintations.models';

interface IComponentProps {
  companyId: string;
}

type AllProps = IComponentProps & FormComponentProps & ICompanyInvitationConnectedProps & ISubscriptionPlansConnectedProps;

const EmployeesInviteFormComponent = (props: AllProps) => {
  const {
    form,
    getSubscriptionPlansActiveCollection,
    companyInvitationsCreateModel,
    subscriptionPlansActiveCollection,
    companyId
  } = props;
  const { loading, data: plans } = subscriptionPlansActiveCollection;
  const plansList = plans?.data;
  const { i18n } = useTranslation();
  const { getFieldDecorator, getFieldValue, getFieldError } = form;

  const [date, setDate] = useState<moment.Moment | undefined>(undefined);
  const disabledDate = !getFieldValue('subscriptionPlan');
  const dateError = getFieldError('planActivationDate');

  const submit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      const { addCompanyInvitationsCreateModel } = props;
      const params: ICompanyInvitationCreateParams = { ...values, company: companyId };

      if (values?.subscriptionPlan && date) {
        params.planActivationDate = date?.format(BE_DATE_FORMAT_DEFAULT);
      }

      addCompanyInvitationsCreateModel(params);
    });
  };

  const debounceSearch = useCallback(
    debounce((value: string) => {
      getSubscriptionPlansActiveCollection({ ...PLANS_PARAMS_ACTIVE, company: companyId, title: value });
    }, SEARCH_WAIT_TIME),
    [getSubscriptionPlansActiveCollection]
  );

  return (
    <>
      <Paragraph className="color_primary t_bold mb-25" size="sm">
        {i18n.t('employeesPage.inviteForm.header')}
      </Paragraph>

      <Paragraph className="mb-20" size="xs">
        <Trans i18nKey="employeesPage.inviteForm.description1">
          You can invite a colleague at any time and choose which plan you would like to offer them, as well as the start date
          when they will begin receiving periodic credits (or you can leave this blank and your colleague will begin receiving
          credits as soon as they set up their account). Should you like to offer your colleagues credits at your own pace, you
          can leave the plan field and start date blank and offer credits via the <b>one-time credit</b> option at the bottom of
          this page.
        </Trans>
      </Paragraph>

      <Form className="mb-15">
        <Spinner spinning={companyInvitationsCreateModel.loading}>
          <Row gutter={30} justify="start" type="flex">
            <Col xs={24} md={12} xl={8}>
              <FormItem label={i18n.t('employeesPage.inviteForm.email')} className="mob-form-wrapper">
                {getFieldDecorator('email', {
                  rules: EmailBaseRules
                })(<Input />)}
              </FormItem>
            </Col>

            <Col xs={24} md={12} xl={8}>
              <FormItem label={i18n.t('employeesPage.inviteForm.name')} className="mob-form-wrapper">
                {getFieldDecorator('name', { rules: RequiredRules })(<Input />)}
              </FormItem>
            </Col>

            <Col xs={24} md={12} xl={8}>
              <FormItem label={i18n.t('employeesPage.inviteForm.plan')} className="mob-form-wrapper">
                {getFieldDecorator('subscriptionPlan')(
                  <AntSelect
                    dropdownClassName="clp-select__dropdown"
                    className="clp-select clp-select_bordered_bottom clp-select_width_sm w-100p"
                    allowClear
                    notFoundContent={
                      loading ? (
                        <Spin size="small" />
                      ) : (
                        <div className="t_white-space_normal">{i18n.t('employeesPage.inviteForm.noPlansPlaceholder')}</div>
                      )
                    }
                    showSearch
                    filterOption={false}
                    onSearch={debounceSearch}
                    onDropdownVisibleChange={open =>
                      open && getSubscriptionPlansActiveCollection({ ...PLANS_PARAMS_ACTIVE, company: companyId, title: '' })
                    }
                  >
                    {plansList?.map(({ title, id }) => (
                      <AntSelect.Option key={id} value={id}>
                        {title}
                      </AntSelect.Option>
                    ))}
                  </AntSelect>
                )}
              </FormItem>
            </Col>

            <Col xs={24} md={12} xl={8}>
              <FormItem
                label={i18n.t('employeesPage.inviteForm.startDate')}
                className="mob-form-wrapper"
                validateStatus={dateError && dateError[0] ? ValidateHelper.getValidateStatus(dateError[0]) : undefined}
                help={dateError}
              >
                <DatePicker
                  disabled={disabledDate}
                  className="mt-10"
                  allowClear
                  disabledDate={DateHelper.isTodayOrBefore}
                  minDate={DateHelper.getTomorrow().toDate()}
                  value={date}
                  onChange={value => setDate(value || undefined)}
                />
              </FormItem>
            </Col>

            <Col xs={24}>
              <MobFormButton onClick={submit} type="primary" disabled={companyInvitationsCreateModel.loading} size="small">
                {i18n.t('employeesPage.inviteForm.btnSubmit')}
              </MobFormButton>
            </Col>
          </Row>
        </Spinner>
      </Form>
    </>
  );
};

export const EmployeesInviteForm = communicationCompanyInvitation.injector(
  Form.create<any>({
    mapPropsToFields(props: AllProps) {
      const { companyInvitationsCreateModel } = props;

      return AntdFormHelper.mapValidationToFields(companyInvitationsCreateModel?.params, companyInvitationsCreateModel?.errors);
    }
  })(communicationSubscriptionPlans.injector(EmployeesInviteFormComponent))
);
