import { AnyAction } from 'redux';
import { all, takeEvery } from 'redux-saga/effects';
import { EErrorStatus } from 'common/models/Request.models';
import { communicationEmployees } from 'entities/Employees/Employees.communication';
import { communicationCompanyInvitation } from 'entities/CompanyInvitations/Invitations.communication';
import { communicationSupport } from 'entities/Support/Support.communication';
import { communicationCompanies } from 'entities/Companies/Companies.communication';
import { communicationRequests } from 'entities/Requests/Requests.communication';
import { communicationPayment } from 'entities/Payment';
import { clearAuth } from 'entities/Auth/Auth.communication';
import { communicationAuth } from 'entities/Auth/Auth.communication';
import { communicationUsers } from 'entities/User/Users.communication';
import { communicationCountries } from 'entities/Countries/Countries.communication';
import { communicationSubscriptionPlans } from 'entities/SubscriptionPlans/SubscriptionPlans.communication';
import { communicationCompanyPayment } from 'entities/CompanyPayment/CompanyPayment.communication';

function* errorWatcher() {
  yield takeEvery('*', function* logger(action: AnyAction) {
    if (action.type.match('FAIL')) {
      const status = action?.payload?.status;
      const message = action?.payload?.message;

      const cognitoValidationMessage = 'Incorrect username or password.';
      if (status === EErrorStatus.Unauthorized && message !== cognitoValidationMessage) {
        yield clearAuth();
      }

      // eslint-disable-next-line no-console
      console.log('ERROR', action.payload);
    }
  });
}

export function* rootSaga() {
  yield all([
    errorWatcher(),
    ...communicationAuth.sagas,
    ...communicationSupport.sagas,
    ...communicationPayment.sagas,
    ...communicationRequests.sagas,
    ...communicationCompanies.sagas,
    ...communicationCompanyInvitation.sagas,
    ...communicationCompanyPayment.sagas,
    ...communicationEmployees.sagas,
    ...communicationCountries.sagas,
    ...communicationSubscriptionPlans.sagas,
    ...communicationUsers.sagas
  ]);
}
