import * as React from 'react';
import { default as AntDatePicker } from 'antd/es/date-picker';
import moment from 'moment';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import Calendar from 'antd-mobile/lib/calendar';
import { Desktop } from 'common/components/Desktop';
import { Mobile } from 'common/components/Mobile';
import 'antd-mobile/lib/calendar/style/css';
import { DATE_FORMAT_DEFAULT } from 'common/config';
import { localeCalendar } from 'common/const';

interface IComponentProps {
  value?: moment.Moment | null;
  className?: string;
  allowClear?: boolean;
  disabled?: boolean;
  disabledDate?: (date: moment.Moment | null) => boolean;
  minDate?: Date;
  onChange: (date: moment.Moment | null) => void;
}

type AllProps = IComponentProps;

export const DatePicker = (props: AllProps) => {
  const { value, disabled, onChange, className, disabledDate, minDate, allowClear = false } = props;
  const visibleMobDateModal = HooksHelper.useStateBuilder<boolean>(false);

  const onMobSelect = (dateTime?: Date) => {
    dateTime && onChange(dateTime ? moment(dateTime) : null);
    visibleMobDateModal.set(false);
  };

  const calendarDate: [Date] | undefined = value && value?.toDate ? [value?.toDate()] : undefined;

  return (
    <>
      <Desktop>
        <AntDatePicker
          format={DATE_FORMAT_DEFAULT}
          value={value}
          disabled={disabled}
          showToday={false}
          allowClear={allowClear}
          dropdownClassName="clp-datepicker"
          disabledDate={disabledDate}
          className={`clp-datepicker__input ${className}`}
          onChange={onChange}
          placeholder={DATE_FORMAT_DEFAULT}
        />
      </Desktop>

      <Mobile>
        <>
          <AntDatePicker
            format={DATE_FORMAT_DEFAULT}
            value={value}
            dropdownClassName="clp-datepicker"
            className={`clp-datepicker__input ${className}`}
            allowClear={false}
            open={false}
            disabled={disabled}
            onOpenChange={() => !disabled && visibleMobDateModal.set(true)}
          />

          {!disabled && (
            <Calendar
              type="one"
              visible={visibleMobDateModal.value}
              locale={localeCalendar}
              onCancel={() => visibleMobDateModal.set(false)}
              onConfirm={onMobSelect}
              defaultValue={calendarDate}
              minDate={minDate}
            />
          )}
        </>
      </Mobile>
    </>
  );
};
