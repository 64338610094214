import moment from 'moment';
import { DATE_FORMAT_DEFAULT, TIME_FORMAT_DEFAULT } from 'common/config';

const getNextDay = (): moment.Moment => {
  return moment().add(1, 'day');
};

const getNextYear = (): moment.Moment => {
  return moment().add(1, 'year');
};

const mergeDateWithTime = (date: moment.Moment, time: moment.Moment) => {
  return date.set({ hours: time?.hours(), minutes: time?.minutes() });
};

const isTodayOrBefore = (currentDate: moment.Moment | null) => {
  return currentDate ? currentDate.isSameOrBefore(moment(), 'day') : false;
};

const isYesterdayOrBefore = (currentDate: moment.Moment | null) => {
  return currentDate ? currentDate.isBefore(moment(), 'day') : false;
};

const isToday = (date: string, format?: string): boolean => {
  return moment(date, format).isSame(
    moment()
      .clone()
      .startOf('day')
  );
};

const isYesterday = (date: string, format?: string): boolean => {
  return moment(date, format).isSame(
    moment()
      .clone()
      .subtract(1, 'days')
      .startOf('day')
  );
};

const getDateWithYear = (date: Date | string) => {
  if (!date) {
    return '';
  }

  return moment(date).format(DATE_FORMAT_DEFAULT);
};

const getTime = (date: Date | string) => {
  return moment(date).format(TIME_FORMAT_DEFAULT);
};

const formatDate = (format: string, date: string = '') => {
  return moment(date).format(format);
};

const getTomorrow = () => {
  return moment('00:01', 'HH:mm')
    .clone()
    .add(1, 'days');
};

const getToday = (): moment.Moment => {
  return moment('00:01', 'HH:mm').clone();
};

const normalizeToday = (date: moment.Moment) => {
  return date.isBefore()
    ? moment()
        .clone()
        .add('10', 'minute')
    : date;
};

export const DateHelper = {
  isToday,
  formatDate,
  isYesterday,
  getTime,
  getDateWithYear,
  getNextDay,
  getNextYear,
  isTodayOrBefore,
  isYesterdayOrBefore,
  getTomorrow,
  getToday,
  normalizeToday,
  mergeDateWithTime
};
