import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'antd/es/grid';
import { Button } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Table from 'antd/es/table';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { EPrivateRoutes } from 'common/const';
import { PaginationHelper, TableFormatHelper } from 'common/helpers';
import {
  ESubscriptionPlansStatus,
  SUBSCRIPTION_PLANS_PER_PAGE,
  subscriptionPlansColumns
} from 'entities/SubscriptionPlans/SubscriptionPlans.consts';
import {
  communicationSubscriptionPlans,
  ISubscriptionPlansConnectedProps
} from 'entities/SubscriptionPlans/SubscriptionPlans.communication';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';
import { SubscriptionPlansListFilters, SubscriptionPlansListFooter } from 'entities/SubscriptionPlans/components';
import { ISubscriptionPlansCollectionParams } from 'entities/SubscriptionPlans/SubscriptionPlans.models';

type AllProps = ISubscriptionPlansConnectedProps & ICompaniesConnectedProps;

const SubscriptionPlansListComponent = (props: AllProps) => {
  const {
    subscriptionPlansCollection,
    getSubscriptionPlansCollection,
    clearSubscriptionPlansCollection,
    companiesCurrent
  } = props;

  const companyId = companiesCurrent?.data?.data?.id;

  const { loading, data: subscriptionPlans } = subscriptionPlansCollection;
  const tableData = subscriptionPlans?.data;
  const hasData = !!subscriptionPlans?.data.length;

  const [currentPage, setCurrentPage] = useState<number>(1);

  const searchTitle = HooksHelper.useStateBuilder<string | undefined>(undefined);
  const amountFrom = HooksHelper.useStateBuilder<number | undefined>(undefined);
  const amountTo = HooksHelper.useStateBuilder<number | undefined>(undefined);
  const searchPeriod = HooksHelper.useStateBuilder<string | undefined>(undefined);
  const status = HooksHelper.useStateBuilder<ESubscriptionPlansStatus | undefined>(undefined);
  const hasFilter = searchTitle.value || amountFrom.value || amountTo.value || searchPeriod.value || status.value;

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    return () => clearSubscriptionPlansCollection();
  }, [clearSubscriptionPlansCollection]);

  const reloadCollection = useCallback(
    (newParams?: Partial<ISubscriptionPlansCollectionParams>) => {
      if (!companyId) {
        return;
      }

      const params: ISubscriptionPlansCollectionParams = { company: companyId, limit: SUBSCRIPTION_PLANS_PER_PAGE };

      if (searchPeriod.value) {
        params.period = Number(searchPeriod.value);
      }

      if (searchTitle.value) {
        params.title = searchTitle.value;
      }

      if (status.value) {
        params.isDeleted = status.value === ESubscriptionPlansStatus.Archived;
      }

      if (amountFrom.value || amountFrom.value === 0) {
        params.amountFrom = amountFrom.value;
      }

      if (amountTo.value || amountTo.value === 0) {
        params.amountTo = amountTo.value;
      }

      getSubscriptionPlansCollection({ ...params, ...newParams });
    },
    [
      getSubscriptionPlansCollection,
      companyId,
      searchTitle.value,
      searchPeriod.value,
      status.value,
      amountFrom.value,
      amountTo.value
    ]
  );

  useEffect(() => {
    reloadCollection();
  }, [reloadCollection]);

  useEffect(() => {
    setCurrentPage(1);
  }, [setCurrentPage, status.value, searchPeriod.value, amountTo.value, amountFrom.value, searchTitle.value]);

  const onChangePage = (limit: number, offset: number, page: number) => {
    setCurrentPage(page);
    reloadCollection({ limit, offset });
  };

  const listPagination = PaginationHelper.getListsPagination(
    onChangePage,
    SUBSCRIPTION_PLANS_PER_PAGE,
    subscriptionPlans?.meta.count,
    currentPage
  );

  const footer = hasFilter
    ? () => <div className="ta-c">{t('plansPage.table.noDataWithFilters')}</div>
    : SubscriptionPlansListFooter;

  return (
    <>
      <Row className="mt-30 mb-30" gutter={[24, 24]} type="flex">
        <Col xs={{ span: 24, order: 2 }} sm={{ span: 15, order: 1 }} lg={17} xl={18}>
          <SubscriptionPlansListFilters
            onSearchTitle={searchTitle.set}
            onChangeAmountFrom={amountFrom.set}
            onChangeAmountTo={amountTo.set}
            onSearchPeriod={searchPeriod.set}
            changeStatus={status.set}
          />
        </Col>

        <Col xs={{ span: 24, order: 1 }} sm={{ span: 9, order: 2 }} lg={7} xl={6} className="ta-r">
          <Button type="primary" onClick={() => history.push(EPrivateRoutes.CreatePlans)}>
            {t('plansPage.btnCreate')}
          </Button>
        </Col>
      </Row>

      <Table
        className="clw-table clw-table_align-left clw-table_without-no-data clw-table_first-col-bold mb-25 mt-25"
        size="middle"
        columns={TableFormatHelper.translateTitles(subscriptionPlansColumns, t)}
        dataSource={tableData}
        rowKey={record => record.id}
        loading={loading}
        scroll={{ x: true }}
        footer={hasData || loading ? undefined : footer}
        pagination={listPagination}
        bordered
      />
    </>
  );
};

export const SubscriptionPlansList = communicationCompanies.injector(
  communicationSubscriptionPlans.injector(SubscriptionPlansListComponent)
);
