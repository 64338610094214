import { Row, Col } from 'antd/es/grid';
import * as React from 'react';
import { Button } from '@axmit/clp-library';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ECommonRoutes } from 'common/const';
import { RequestsList } from 'entities/Requests/components';
import { Balance } from 'entities/Payment/components/Balance';

export const AccountRequests = () => {
  const history = useHistory();
  const { i18n } = useTranslation();

  return (
    <>
      <Row className="mt-30 mb-30">
        <Col xs={12} className="mb-30">
          <Balance />
        </Col>

        <Col xs={12} className="ta-r">
          <Button
            size="small"
            type="primary"
            onClick={() => {
              history.push(ECommonRoutes.CreateRequest);
            }}
          >
            {i18n.t('requestMake.btnToCreatePage')}
          </Button>
        </Col>
      </Row>

      <RequestsList />
    </>
  );
};
