import i18n from 'app/locales/i18n';

export const CreatePasswordValidationRules = [
  { min: 8, message: () => i18n.t('errorPasswordMin') },
  {
    required: true,
    message: () => i18n.t('errorRequiredDefault')
  },
  {
    pattern: new RegExp(/[A-Z]/g),
    message: () => i18n.t('errorPasswordUppercase')
  },
  {
    pattern: new RegExp(/[a-z]/g),
    message: () => i18n.t('errorPasswordLowercase')
  },
  {
    pattern: new RegExp(/[0-9]/g),
    message: () => i18n.t('errorPasswordNumbers')
  }
];

export const RequiredRules = [
  {
    required: true,
    message: () => i18n.t('errorRequiredDefault')
  }
];

export const EmailBaseRules = [
  {
    type: 'email',
    message: () => i18n.t('errorEmailNotEmail')
  },
  {
    required: true,
    message: () => i18n.t('errorRequiredDefault')
  }
];

export const CardNumberValidationRules = [
  {
    required: true,
    message: () => i18n.t('errorRequiredDefault')
  },
  {
    min: 13,
    max: 19,
    message: () => i18n.t('cardCreate.number.notValid')
  }
];

export const CardCvcValidationRules = [
  {
    required: true,
    message: () => i18n.t('errorRequiredDefault')
  },
  {
    min: 3,
    max: 4,
    message: () => i18n.t('cardCreate.cvc.notValid')
  }
];
