import * as React from 'react';
import { PropsWithChildren } from 'react';

interface IComponentProps {
  label: string;
  field: string;
  subLabel?: string;
}

export const FormLabel = (props: PropsWithChildren<IComponentProps>) => {
  const { label, field, subLabel } = props;

  return (
    <div className="clp-form-item">
      <div className="ant-col ant-form-item-label ant-form-item-label-left">
        <label htmlFor={field} className="ant-form-item-required ant-form-item-no-colon">
          {label}
        </label>
      </div>

      {subLabel && <div className="form-sub-label">{subLabel}</div>}
    </div>
  );
};
