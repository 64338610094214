import { Paragraph } from '@axmit/clp-library';
import Table from 'antd/es/table';
import React, { useCallback, useEffect } from 'react';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/es/table/interface';
import { PaginationConfig } from 'antd/es/pagination';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import moment from 'moment';
import { DateHelper, FormatHelper, PaginationHelper, TableFormatHelper } from 'common/helpers';
import { EOrderDirection } from 'common/models/Request.models';
import { EEmployeeHistoryColumns, EMPLOYEES_PER_PAGE } from 'entities/Employees/Employees.consts';
import { communicationEmployees, IEmployeesConnectedProps } from 'entities/Employees/Employees.communication';
import { IEmployeeRequestModel, IEmployeeRequestsParams } from 'entities/Employees/Employees.models';
import { EmployeeRequestHistoryFilters } from 'entities/Employees/components/EmployeeRequestHistoryFilters';

interface IComponentProps {
  employeeId: string;
}

type AllProps = IComponentProps & IEmployeesConnectedProps;

const EmployeeRequestHistoryComponent = ({
  employeeId,
  getEmployeesRequests,
  clearEmployeesRequests,
  employeesRequests
}: AllProps) => {
  const { t } = useTranslation();
  const dateFrom = HooksHelper.useStateBuilder<moment.Moment | null | undefined>(undefined);
  const dateTo = HooksHelper.useStateBuilder<moment.Moment | null | undefined>(undefined);
  const ordering = HooksHelper.useStateBuilder<string | undefined>(undefined);
  const orderDirection = HooksHelper.useStateBuilder<EOrderDirection | undefined>(undefined);

  useEffect(() => {
    return () => clearEmployeesRequests();
  }, [clearEmployeesRequests]);

  const reloadCollection = useCallback(
    (newParams?: Partial<IEmployeeRequestsParams>) => {
      getEmployeesRequests({
        id: employeeId,
        limit: EMPLOYEES_PER_PAGE,
        dateFrom: dateFrom.value?.toISOString(),
        dateTo: dateTo.value?.toISOString(),
        // @ts-ignore: string to string literal
        ordering: ordering.value,
        orderDirection: orderDirection.value,
        ...newParams
      });
    },
    [getEmployeesRequests, employeeId, dateFrom.value, dateTo.value, orderDirection.value, ordering.value]
  );

  useEffect(() => {
    reloadCollection();
  }, [reloadCollection]);

  const onChangePage = (limit: number, offset: number) => {
    reloadCollection({ limit, offset });
  };

  const { data, loading } = employeesRequests;
  const listPagination = PaginationHelper.getListsPagination(onChangePage, EMPLOYEES_PER_PAGE, data?.meta.count);
  const hasData = !!data?.data.length;
  const headerSuffix: string | undefined = hasData ? `(${data?.data.length})` : undefined;

  const employeeHistoryColumns: ColumnProps<IEmployeeRequestModel>[] = [
    {
      dataIndex: EEmployeeHistoryColumns.createdAt,
      title: `employeePage.history.table.columns.date`,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: DateHelper.getDateWithYear
    },
    {
      dataIndex: EEmployeeHistoryColumns.title,
      title: `employeePage.history.table.columns.request`,
      sorter: true,
      sortDirections: ['descend', 'ascend']
    },
    {
      dataIndex: EEmployeeHistoryColumns.amount,
      title: `employeePage.history.table.columns.amount`,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: FormatHelper.getCurrency
    }
  ];

  const handleTableChange = (
    pagination: PaginationConfig,
    filters: Partial<Record<keyof IEmployeeRequestModel, string[]>>,
    sorter: SorterResult<IEmployeeRequestModel>
  ) => {
    const direction = sorter.order === 'ascend' ? EOrderDirection.ASC : EOrderDirection.DESC;

    orderDirection.set(sorter.order ? direction : undefined);
    ordering.set(sorter.field);
  };

  return (
    <>
      <Paragraph className="color_primary t_bold" size="sm">
        {t('employeePage.history.header')} {headerSuffix}
      </Paragraph>

      <EmployeeRequestHistoryFilters
        dateFrom={dateFrom.value}
        onChangeDateFrom={dateFrom.set}
        dateTo={dateTo.value}
        onChangeDateTo={dateTo.set}
      />

      <Table
        className="clw-table clw-table_align-left clw-table_without-no-data"
        size="middle"
        loading={loading}
        columns={TableFormatHelper.translateTitles(employeeHistoryColumns, t)}
        dataSource={data?.data}
        pagination={listPagination}
        rowKey={record => record.createdAt}
        footer={
          hasData ? undefined : () => <div className="clw-table-footer">{t('employeePage.history.table.noDataPlaceholder')}</div>
        }
        onChange={handleTableChange}
        bordered
      />
    </>
  );
};

const containers = compose(communicationEmployees.injector);

export const EmployeeRequestHistory = containers(EmployeeRequestHistoryComponent);
