import { Paragraph } from '@axmit/clp-library';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FormatHelper } from 'common/helpers';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';

interface IComponentProps {
  total?: number;
  loading: boolean;
}

type AllProps = ICompaniesConnectedProps & IComponentProps;

const CompanyAccountPaymentTotalComponent = (props: AllProps) => {
  const { total, loading } = props;
  const { t } = useTranslation();

  if (!loading && !total) {
    return null;
  }

  return (
    <Paragraph className="mb-20" size="xs">
      <Trans
        t={t}
        i18nKey="accountPage.paymentForm.amountFinal"
        values={{ amount: loading ? '...' : FormatHelper.getCurrency(total) }}
        components={[<b key="amount" />]}
      />
    </Paragraph>
  );
};

export const CompanyAccountPaymentTotal = communicationCompanies.injector(CompanyAccountPaymentTotalComponent);
