import { getCreds } from '@axmit/axios-patch-jwt';
import React from 'react';
import { Route, Switch } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { EPrivateRoutes, EPublicRoutes } from 'common/const/Routes.const';
import { DashboardPersonal, DashboardCompany } from 'app/pages';
import { UserEmployeeCheckoutForm } from 'entities/Payment/components/UserEmployeeCheckoutForm';
import { EmployeeMobile } from 'entities/User/components/EmployeeMobile';
import { CompanyDeposit } from 'entities/CompanyPayment/components';
import { ChangePasswordMobilePage } from 'entities/Auth/components';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';
import { Deposit } from 'entities/Payment/components/Deposit';
import { PaymentMobile } from 'entities/Payment/components/PaymentMobile';
import { PersonalMobile } from 'entities/User/components';
import { EmployeesMobile } from 'entities/User/components/EmployeesMobile';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { CompanyUpdateMobile } from 'entities/Companies/components/CompanyUpdateMobile';
import { AutoPaymentMobile } from 'entities/Payment/components';
import { CompanyAccountMobile } from 'entities/Companies/components';

type AllProps = RouteComponentProps & IUsersConnectedProps & ICompaniesConnectedProps;

class LayoutPrivateComponent extends React.PureComponent<AllProps> {
  async componentDidMount() {
    const { history } = this.props;

    const creds = await getCreds();

    if (!creds.access) {
      history.push(EPublicRoutes.LogIn);
    }
  }

  public render() {
    const { usersCurrent, companiesCurrent } = this.props;

    if (!usersCurrent.data || !companiesCurrent.data) {
      return null;
    }

    if (companiesCurrent.data.isCompanyAccount) {
      return (
        <Switch>
          <Route path={EPrivateRoutes.DepositCompany} component={CompanyDeposit} />
          <Route path={EPrivateRoutes.Deposit} component={Deposit} />
          <Route path={EPrivateRoutes.PersonalInfoMobile} component={PersonalMobile} />
          <Route path={EPrivateRoutes.CompanyInfoMobile} component={CompanyUpdateMobile} />
          <Route path={EPrivateRoutes.ChangePasswordMobile} component={ChangePasswordMobilePage} />
          <Route path={EPrivateRoutes.AutoPaymentMobile} component={AutoPaymentMobile} />
          <Route path={EPrivateRoutes.AccountMobile} component={CompanyAccountMobile} />
          <Route path={EPrivateRoutes.EmployeesMobile} component={EmployeesMobile} />
          <Route path={`${EPrivateRoutes.EmployeeMobile}/:id`} component={EmployeeMobile} />
          <Route path={EPrivateRoutes.TransactionGiftRequest} component={UserEmployeeCheckoutForm} />
          <Route path="*" component={DashboardCompany} />
        </Switch>
      );
    }

    return (
      <Switch>
        <Route path={EPrivateRoutes.Deposit} component={Deposit} />
        <Route path={EPrivateRoutes.PaymentInfoMobile} component={PaymentMobile} />
        <Route path={EPrivateRoutes.PersonalInfoMobile} component={PersonalMobile} />
        <Route path={EPrivateRoutes.ChangePasswordMobile} component={ChangePasswordMobilePage} />
        <Route path={EPrivateRoutes.TransactionGiftRequest} component={UserEmployeeCheckoutForm} />
        <Route path="*" component={DashboardPersonal} />
      </Switch>
    );
  }
}

const containers = compose(withRouter, communicationUsers.injector, communicationCompanies.injector);

export const LayoutPrivate = containers(LayoutPrivateComponent);
