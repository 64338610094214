import { Button, Container, Tabs } from '@axmit/clp-library';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { Col } from 'antd/es/grid';
import Spin from 'antd/es/spin';
import message from 'antd/es/message';
import AntTabs from 'antd/es/tabs';
import * as React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { BtnBack } from 'common/components/BtnBack';
import { UsersHooks } from 'common/hooks/Users.hooks';
import { ECommonRoutes, EPrivateRoutes } from 'common/const';
import { ICheckoutForm } from 'entities/Payment/Payment.models';
import { communicationPayment, IPaymentConnectedProps } from 'entities/Payment/Payment.communication';
import { CheckoutForm } from 'entities/Payment/components/CheckoutForm';
import { CurrencyForm } from 'entities/Payment/components/CurrencyForm';

type AllProps = IPaymentConnectedProps;

export const DepositComponent = (props: AllProps) => {
  const { paymentIntent, addPaymentIntent } = props;
  const { i18n } = useTranslation();
  const history = useHistory();
  const { currentUserId } = UsersHooks.useCurrent();

  const amount = HooksHelper.useStateBuilder<number | undefined>(1);
  const amountIsValid = HooksHelper.useStateBuilder(true);
  const stripeLoading = HooksHelper.useStateBuilder(false);

  const submit = (checkoutParams: ICheckoutForm) => {
    currentUserId && addPaymentIntent({ userId: currentUserId, amount: amount.value || 0, ...checkoutParams });
  };

  return (
    <Container>
      <BtnBack />

      <Tabs
        tabBarExtraContent={
          <Button type="link" className="pr-0 " onClick={() => history.push(ECommonRoutes.Logout)}>
            <span className="t_decoration_none">{i18n.t('signOut')}</span>
          </Button>
        }
        activeKey="form"
      >
        <AntTabs.TabPane tab={i18n.t('deposit')} key="form">
          <Col xl={8} lg={12} className="pt-30">
            <Spin spinning={paymentIntent.loading || stripeLoading.value}>
              <CurrencyForm amount={amount.value} setAmount={amount.set} setValid={amountIsValid.set} />

              <CheckoutForm
                submit={submit}
                currencyIsValid={amountIsValid.value}
                successCallback={() => {
                  message.success(i18n.t('successUpdateDefault'));
                  history.push(EPrivateRoutes.Main);
                }}
                setStripeLoading={stripeLoading.set}
              />
            </Spin>
          </Col>
        </AntTabs.TabPane>
      </Tabs>
    </Container>
  );
};

export const Deposit = communicationPayment.injector(DepositComponent);
