import { Container } from '@axmit/clp-library';
import React from 'react';
import { Desktop, Mobile } from 'common/components';
import { DashboardPersonalDesktop, DashboardPersonalMobile } from 'app/pages';

const DashboardPersonalComponent = () => {
  return (
    <Container>
      <Desktop>
        <DashboardPersonalDesktop />
      </Desktop>

      <Mobile>
        <DashboardPersonalMobile />
      </Mobile>
    </Container>
  );
};

export const DashboardPersonal = DashboardPersonalComponent;
