import i18n from 'app/locales/i18n';

const getCurrency = (valueInit?: number | null): string => {
  const value = valueInit || 0;

  return Intl.NumberFormat([i18n.language], {
    style: 'currency',
    currency: 'eur'
  }).format(value);
};

const normalizeCurrency = (value: string) => {
  if (!value) {
    return value;
  }

  return FormatHelper.formatAmount(value);
};

const formatAmount = (value?: string): number => (value ? +(+value).toFixed(2) : 0);

const normalizeNumber = (value?: string) => Number(value) || '';

export const FormatHelper = {
  normalizeNumber,
  normalizeCurrency,
  formatAmount,
  getCurrency
};
