import Typography from 'antd/es/typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyData } from 'common/components';
import { DateHelper } from 'common/helpers';
import { IRequestModel } from 'entities/Requests/Requests.models';

interface IComponentProps {
  request?: IRequestModel | null;
}

export const RequestViewMain = ({ request }: IComponentProps) => {
  const { i18n } = useTranslation();

  if (!request) {
    return <EmptyData />;
  }

  const {
    meta: { description },
    createdAt,
    deliveryDate
  } = request;

  return (
    <>
      <Typography.Title className="clp-typography-title clp-typography-title_size_xs mb-30" level={3}>
        {i18n.t('details')}
      </Typography.Title>

      <div className="mb-15">
        <span className="color_light">{i18n.t('dueDate')}: </span>
        <span className="color_primary">{DateHelper.getDateWithYear(deliveryDate)}</span>
      </div>

      <div className="mb-30">
        <span className="color_light">{i18n.t('created')}: </span>
        <span className="color_primary"> {DateHelper.getDateWithYear(createdAt)}</span>
        <span className="color_default"> {DateHelper.getTime(createdAt)}</span>
      </div>

      {description && (
        <>
          <Typography.Title className="clp-typography-title clp-typography-title_size_xs mb-30" level={3}>
            {i18n.t('description')}
          </Typography.Title>

          <Typography.Text className="clp-typography-text clp-typography-text_size_md mb-30 break-word">
            {description}
          </Typography.Text>
        </>
      )}
    </>
  );
};
