import axios from 'axios';
import { BaseTransport } from 'common/dto/base.transport';
import { ICompanyInvitationCollection, ICompanyInvitationModel } from 'entities/CompanyInvitations/Invintations.models';

export class CompanyInvitationsTransport extends BaseTransport<ICompanyInvitationModel, ICompanyInvitationCollection> {
  public activate = (id: string) => {
    return axios.post(`${this.path}/${id}/activate`);
  };

  public reInvite = (id: string) => {
    return axios.post(`${this.path}/${id}/re-invite`);
  };
}
