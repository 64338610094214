import i18n from 'app/locales/i18n';
import { ERequestStatus } from 'entities/Requests/Requests.models';

const getStatusIcon = (status: ERequestStatus): 'success' | 'processing' | 'default' | 'error' | 'warning' => {
  switch (status) {
    case ERequestStatus.Activated:
    case ERequestStatus.Created:
      return 'warning';

    case ERequestStatus.Completed:
    case ERequestStatus.Delivered:
      return 'success';

    case ERequestStatus.InProgress:
      return 'processing';

    default:
      return 'default';
  }
};

const getStatusDescription = (status: ERequestStatus): string => {
  switch (status) {
    case ERequestStatus.Activated:
    case ERequestStatus.Created:
      return i18n.t('awaiting');

    case ERequestStatus.Completed:
    case ERequestStatus.Delivered:
      return i18n.t('finished');

    case ERequestStatus.InProgress:
      return i18n.t('inProgress');

    default:
      return i18n.t('unknown');
  }
};

export const RequestHelper = {
  getStatusIcon,
  getStatusDescription
};
