import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EPrivateRoutes } from 'common/const';

export const SubscriptionPlansListFooter = () => {
  return (
    <div className="clw-table-footer">
      <Trans i18nKey="plansPage.table.noDataPlaceholder">
        In order to offer periodic credit to your colleagues, you can{' '}
        <Link to={EPrivateRoutes.CreatePlans} className="clw-table-footer-link">
          create plans
        </Link>{' '}
        here that are to your liking, and then invite your colleagues. Alternatively, you can add colleagues without creating
        plans and offer one-time credits straight away by getting started under{' '}
        <Link to={EPrivateRoutes.Employees} className="clw-table-footer-link">
          Colleagues
        </Link>
      </Trans>
    </div>
  );
};
