import { Col, Row } from 'antd/es/grid';
import React, { useEffect } from 'react';
import { CompanyAccountHistory, CompanyAccountInfo, CompanyAccountPayment } from 'entities/Companies/components';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';

type AllProps = ICompaniesConnectedProps;

const CompanyAccountComponent = (props: AllProps) => {
  const { companiesCurrent, getCompaniesSummary, clearCompaniesSummary, companiesSummary } = props;

  const companyId = companiesCurrent?.data?.data?.id;

  useEffect(() => {
    companyId && getCompaniesSummary(companyId);
  }, [companyId, getCompaniesSummary]);

  useEffect(() => {
    return () => {
      clearCompaniesSummary();
    };
  }, [clearCompaniesSummary]);

  if (!companyId || !companiesSummary.data) {
    return null;
  }

  const { nextTransferAmount } = companiesSummary.data;

  return (
    <Row gutter={[24, 60]}>
      <Col xl={12}>
        <CompanyAccountInfo />

        <CompanyAccountPayment initAmount={nextTransferAmount} />
      </Col>

      <Col xs={24}>
        <CompanyAccountHistory companyId={companyId} />
      </Col>
    </Row>
  );
};

export const CompanyAccount = communicationCompanies.injector(CompanyAccountComponent);
