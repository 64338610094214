import { APIProvider, BaseStrategy, Branch, buildCommunication, EActionsTypes, StoreBranch } from '@axmit/redux-communications';
import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';
import message from 'antd/es/message';
import { RequestLoadingHelper } from 'common/helpers';
import { ECommonRoutes } from 'common/const';
import { IMappedValidateData } from 'common/models/Validate.models';
import i18n from 'app/locales/i18n';
import {
  IRequestCollection,
  IRequestCollectionParams,
  IRequestCreateParams,
  IRequestModel,
  IRequestsNonAuthUserMsgParams,
  IRequestTransaction,
  IRequestTransactionParams
} from 'entities/Requests/Requests.models';
import { RequestTransport } from 'entities/Requests/Request.transport';

const namespace = 'requests';

export interface IRequestsConnectedProps {
  requestsModel: StoreBranch<IRequestModel>;
  addRequestsModel(params: IRequestCreateParams): IRequestModel;
  getRequestsModel(id: string): IRequestModel;
  clearRequestsModel(): void;

  requestsCollection: StoreBranch<IRequestCollection, IRequestCollectionParams>;
  getRequestsCollection(params: IRequestCollectionParams): IRequestCollection;
  clearRequestsCollection(): void;

  requestsTransactions: StoreBranch<IRequestTransaction>;
  getRequestsTransactions(params: IRequestTransactionParams): void;
  clearRequestsTransactions(): void;

  requestsNonAuthUserMsg: StoreBranch<{}>;
  addRequestsNonAuthUserMsg(params: IRequestsNonAuthUserMsgParams): void;
}

const transport = new RequestTransport('gift-requests');

const formatRequestCreateErrors = (error: IMappedValidateData) => {
  if (error?.errors?.contactDate) {
    message.error(i18n.t('timeRequestError'));
  }

  if (error?.errors?.deliveryDate) {
    message.error(i18n.t('timeCompletedError'));
  }
};

const modelApiProvider = [
  new APIProvider<IRequestModel, IRequestCreateParams>(EActionsTypes.add, transport.add, {
    clearParams: true,
    onSuccess: function*() {
      yield put(push(ECommonRoutes.FinalizeRequest));
    },
    onFail: formatRequestCreateErrors
  }),
  new APIProvider<IRequestModel, string>(EActionsTypes.get, transport.get)
];

const collectionApiProvider = [
  new APIProvider<IRequestCollection, IRequestCollectionParams>(EActionsTypes.get, transport.getCollection, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

const transactionsApiProvider = [
  new APIProvider(EActionsTypes.get, transport.getTransactions, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

const nonAuthUserMsgApiProvider = [
  new APIProvider(EActionsTypes.add, transport.sendNonAuthUserMsg, {
    *onSuccess() {
      yield put(push(ECommonRoutes.FinalizeRequest));
    },
    onFail: formatRequestCreateErrors
  })
];

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('collection', collectionApiProvider),
  new Branch('transactions', transactionsApiProvider),
  new Branch('nonAuthUserMsg', nonAuthUserMsgApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationRequests = buildCommunication<IRequestsConnectedProps>(strategy);
